export const recoverPreferencesFromUserData = (profileData) => {
    const profilePreferences = profileData?.traitBenefitPreferences;
    const userPreferences = [];
    if(profilePreferences) {
        profilePreferences.forEach(benefitPreference => {
            let value = benefitPreference?.traitValue;
            if(value) {
                // set to upper case to be sure we match existing user benefit preferences in prod
                userPreferences.push(value);
            }
        })
    }
    return userPreferences;
};

export const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
}