import React from 'react';
import PropTypes from 'prop-types';
import MarkdownText from '../MarkdownText/MarkdownText';
import StarRating from '../StarRating/StarRating';
import Button from '../Button/Button';
import {Constants} from '../../adapters/helpers/Constants';

export default function ThreeColumnsUserQuoteBlock ({ document }) {
    const selectedUserQuotes = document?.fields?.selectedUserQuotes || [];
    const seeMore = document?.fields?.seeMore;
    const starSize = 1.8;

    return (
        <div className={'ob-three-columns-quotes'}>
            <ul className={`ob-three-columns-quotes__list ${selectedUserQuotes.length === 2 ? 'ob-three-columns-quotes__list--two-columns' : ''}`}>
                {selectedUserQuotes.map((userQuote, index) => (
                    <li className={'ob-three-columns-quotes__list-item'} key={index}>
                        {userQuote?.fields?.isFeatured ? (
                            <>
                                {userQuote?.fields?.title &&
                                    <h3 className={'ob-three-columns-quotes__quote-title'}>{userQuote?.fields?.title}</h3>
                                }
                                <StarRating
                                    className={'ob-three-columns-quotes__star-rating'}
                                    ratingNumber={userQuote?.fields?.starRating}
                                />
                                <MarkdownText className={`ob-three-columns-quotes__quote-text ${userQuote?.fields?.isFeatured ? 'ob-three-columns-quotes__quote-text--is-featured' : ''}`} value={userQuote?.fields?.quote}/>
                                <p className={'ob-three-columns-quotes__date-posted'}>{userQuote?.fields?.datePosted?.fields?.text}</p>
                                <p className={'ob-three-columns-quotes__author-name'}>{userQuote?.fields?.authorName}</p>
                            </>
                        ) : (
                            <>
                                <p className={'ob-three-columns-quotes__date-posted'}>{userQuote?.fields?.datePosted?.fields?.text}</p>
                                <h3 className={'ob-three-columns-quotes__quote-title'}>{userQuote?.fields?.title}</h3>
                                <MarkdownText className={'ob-three-columns-quotes__quote-text'} value={userQuote?.fields?.quote}/>
                                <StarRating
                                    className={'ob-three-columns-quotes__star-rating'}
                                    smallSize={starSize}
                                    mediumSize={starSize}
                                    largeSize={starSize}
                                    ratingNumber={userQuote?.fields?.starRating}
                                />
                                <p className={'ob-three-columns-quotes__author-name'}>{userQuote?.fields?.authorName}</p>
                            </>
                        )}
                    </li>
                ))}
            </ul>
            {seeMore &&
                <Button tag={'a'} className={'ob-three-columns-quotes__see-more'}
                    size={'large-slim'} borderTheme={true} href={seeMore?.fields?.url}
                    ariaLabel={`${seeMore?.fields?.title} ${Constants.aboutReviews}`}
                    title={`${seeMore?.fields?.title} ${Constants.aboutReviews}`}>
                    {seeMore?.fields?.title}
                </Button>
            }
        </div>
    )
}

ThreeColumnsUserQuoteBlock.propTypes = {
    document: PropTypes.object,
};
