import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'


function ObLink({children, tag: Tag, href, id, className, styles, role, onClick, dataActionDetail, disabled, isExternalLink, isRelNofollow}) {

    if (!children) {
        return null
    }

    return (
        <React.Fragment>
            { href && Tag == 'a' ? (
                <a href={href} 
                target={isExternalLink ? '_blank' : null} 
                rel={isRelNofollow ? 'nofollow' : null} 
                className={`ob-link ${className ? className : ''}`} 
                id={id} 
                style={styles} 
                role={role} 
                onClick={onClick} 
                data-action-detail={dataActionDetail} 
                aria-disabled={disabled}>
                        {children}
                </a>
            ) : (
                <Tag className={`ob-link ${className ? className : ''}`} id={id} style={styles} role={role} onClick={onClick} data-action-detail={dataActionDetail}>
                    {children}
                </Tag>
            )}

        </React.Fragment>
    )
}

ObLink.propTypes = {
    /**
    * The tag or component to be used e.g. button, a, Link
    */
    tag: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func  
    ]),

    /** Children is the text button */
    children: PropTypes.node,

    /** Link */
    href: PropTypes.string,

    /** Custom class. */
    className: PropTypes.string,

    /** Analytics data action event */
    dataActionDetail: PropTypes.string,

    /** Custom ID. */
    id: PropTypes.string,

    /** Custom styles to be applied to the link */
    styles: PropTypes.object,

    icon: PropTypes.string,

    role:  PropTypes.string,
    
    disabled:  PropTypes.string,

    onClick: PropTypes.any,

    isExternalLink: PropTypes.bool,

    isRelNofollow: PropTypes.bool,
}

ObLink.defaultProps = {
    tag: 'a',
    styles: {}
}


export default ObLink

