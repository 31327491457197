
// much simpler (and smaller) uniqBy implementation compared to lodash's
export function uniqBy(ary, keyFn) {
    const keys = [];
    const values = [];

    for (let i = 0; i < ary.length; ++i) {
        const item = ary[i];
        if(item) {
            const key = keyFn(item);

            if (keys.indexOf(key) === -1) {
                keys.push(key);
                values.push(item);
            }
        }
    }

    return values;
}
