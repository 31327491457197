import {FAQBlockConstants, SearchBlockConstants} from '../../adapters/helpers/Constants';
import Icon from '../Icon/Icon';
import {Markdown} from '../MarkdownText/MarkdownText';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {focusOnElement} from '../../adapters/helpers/Utils';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import { FAQConstants } from '../../adapters/helpers/Constants'

export default function FAQQA({qa, documentKey, activeItem, onClickCallback, faqStyle}) {
    
    const [isOpen, setIsOpen] = useState(false);
    const [answerHeight, setAnswerHeight] = useState(0);
    const answerRef = useRef(null);
    
    const onClickHandler = useCallback(() => {
        onClickCallback(documentKey === activeItem ? false : documentKey);
        if (answerRef.current) {
            focusOnElement(answerRef.current);
        }
    }, [])
    
    const calculateHeight = useCallback(
        () => {
            if (answerRef.current) {
                setAnswerHeight(answerRef.current.scrollHeight);
            }
        },
        [],
    );
    
    useEffect(() => {
        setIsOpen(documentKey === activeItem)
    }, [activeItem]);
    
    return (
        <CSSTransition in={isOpen} timeout={800} onEntering={calculateHeight} onExit={calculateHeight}>
            <div className={'ob-faq-block__qa'}>
                <div onClick={onClickHandler}
                    tabIndex='0'
                    role={FAQConstants.link}
                    onKeyDown={onClickHandler}
                        className='ob-faq-block__question event_button_click'
                        data-action-detail={AnalyticsConstants.expandFaq}>
                    <div className='ob-faq-block__icon-wrapper'>
                        {faqStyle === SearchBlockConstants.faqStyleDropdown &&
                        <div className='ob-faq-block__icon'>
                            {isOpen ? <Icon name={FAQBlockConstants.minus6} viewBox={6}/>
                                : <Icon name={FAQBlockConstants.plus} viewBox={10}/>}
                        </div>
                        }
                        {faqStyle === SearchBlockConstants.faqStylePageLinks &&
                        <div className='ob-faq-block__icon ob-faq-block__icon-arrow'>
                            <Icon name={FAQBlockConstants.arrowRight} size={2}/>
                        </div>
                        }
                        
                    </div>
                    
                    <h2 className='ob-faq-block__question-text'>
                        {qa.name}
                    </h2>
                </div>
                <div ref={answerRef} className='ob-faq-block__answer' style={{height: answerHeight}}>
                    {!!qa.description && <div className='ob-faq-block__answer-content'>
                        <Markdown source={decodeURI(qa.description)}/>
                    </div>}
                </div>
            </div>
        </CSSTransition>
    );
}

FAQQA.propTypes = {
    qa: PropTypes.any,
    documentKey: PropTypes.any,
    activeItem: PropTypes.any,
    onClickCallback: PropTypes.any,
    faqStyle: PropTypes.string,
};

FAQQA.defaultProps = {
    qa: {}
};