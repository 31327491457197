export const inputs = {
    formName: 'changePasswordFormNoAuth',
    type: {
        password: 'password',
    },
    field: {
        currentPassword: 'currentPassword',
        newPassword: 'newPassword',
        newPasswordConfirm: 'newPasswordConfirm',
    }
}
