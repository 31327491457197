import C from 'classnames';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { SeeMoreButtonConstants } from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';


export default function SeeMoreButton({isOpen, seeMoreText = '', seeLessText = '', onClick, whiteText}) {

    return (
        <div className={
            C('ob-see-more-button"',
            {'ob-see-more-button--white': whiteText})
        }>
            <Icon className="ob-see-more-button__icon" roundedIcon={SeeMoreButtonConstants.blue} name={isOpen ? SeeMoreButtonConstants.chevronLeft : SeeMoreButtonConstants.chevronRight}/>
            <button aria-label={isOpen ? seeLessText : seeMoreText} onClick={onClick}
                    type={SeeMoreButtonConstants.button}
                    className={'event_button_click ob-see-more-button__text'}
                    data-action-detail={isOpen ? AnalyticsConstants.collapseText : AnalyticsConstants.expandText}
            >
                {isOpen ? seeLessText : seeMoreText}
            </button>
        </div>
    )
}

SeeMoreButton.propTypes = {
    isOpen: PropTypes.bool,
    seeMoreText: PropTypes.string,
    seeLessText: PropTypes.string,
    onClick: PropTypes.func,
    whiteText: PropTypes.bool,
};