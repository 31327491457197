import {isObject} from './functions'; 


// If this function works fine, should replace the one in BlockDao
export function recursivelyRemoveSysFromEntry(entry) {
    if(Array.isArray(entry)) {
        return entry.map(x => recursivelyRemoveSysFromEntry(x));
    }

    if(!isObject(entry)) {
        return  entry;
    }
    const sysType = entry?.sys?.type
    const contentType = entry?.sys?.contentType?.sys?.id || 
        ((sysType === 'Asset') ? sysType : undefined);
    const fields = entry?.fields;

    if(contentType && fields) {
        const _sysRemovedFields = recursivelyRemoveSysFromEntry(fields);

        const revision = contentType == 'staticImage' ? entry?.sys?.revision : undefined;

        return {fields: {..._sysRemovedFields, contentType, revision}};
    } else {
        const keys = Object.keys(entry);
        const _entry = {};
        
        for (const key of keys) {
            _entry[key] = recursivelyRemoveSysFromEntry(entry[key]);
        }
        return _entry;
    }
}