import React, { useState, useEffect, useLayoutEffect } from 'react';

// https://github.com/streamich/react-use/blob/master/docs/useIsomorphicLayoutEffect.md
export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useMatchMedia(mediaQueryString, initialValue = null) {
    const [match, setMatch] = useState(initialValue);

    useEffect(
        () => {
            if(!window.matchMedia) {
                return;
            }

            function handleMatch(event) {
                setMatch(event.matches);
            }

            const matchMedia = window.matchMedia(mediaQueryString);

            setMatch(matchMedia.matches);
            matchMedia.addListener(handleMatch);

            return () => {
                matchMedia.removeListener(handleMatch);
            };
        },
        [mediaQueryString]
    );

    return match;
}

export const mediaQueryHOC = (Component) => {
    function MediaQueryHOC(props) {
        const isXSmall = useMatchMedia('(max-width: 375px)');
        const isSmall = useMatchMedia('(max-width: 479px)');
        const isMedium = useMatchMedia('(max-width: 767px)');
        const isLarge = useMatchMedia('(max-width: 991px)');
        const isXLarge = useMatchMedia('(max-width: 1023px)');
        return (
            <Component
                isXSmall={isXSmall}
                isSmall={isSmall}
                isMedium={isMedium}
                isLarge={isLarge}
                isXLarge={isXLarge}
                {...props}
            />
        );
    }
    return MediaQueryHOC;
};

let listeners = [];
let state = { currentProductIndex: 0};


const setState = (newState) => {
    state = { ...state, ...newState };
    listeners.forEach((listener) => {
        listener(state);
    });
};

export const updateCurrentProductIndex = () => {
    const newListener = useState()[1];
    useEffect(() => {
        listeners.push(newListener);
    }, []);
    return [state, setState];
};
