import React, {useState, useEffect} from 'react';
import Block from '../../adapters/helpers/Block';
import ObLink from '../ObLink/ObLink';
import Icon from '../Icon/Icon';
import { ProductHighlightsConstants } from '../../adapters/helpers/Constants';
import DOMPurify from 'isomorphic-dompurify';

function HTMLBlock(props) {
    const block = new Block(props);
    const id = block.getAnchorId();
    const className = 'ob-html-block ' + block.getFieldValue('classNames');
    const text = block.getFieldValue('text');
    const learnMoreCta = block.getFieldValue('learnMoreCta');
    const learnMoreDataActionDetail = block.getFieldValue('learnMoreDataActionDetail');
    const relatedBlocks = block.getFieldValue('relatedBlockContents');
    const [learnMore, setLearnMore] = useState(true);

    useEffect(() => {
        const hash = window?.location?.hash || null;
        if(hash === ProductHighlightsConstants.whyOralBHash) {
            let ele = document.getElementById('wieso');
            if(ele) {
                let bodyRect = document.body.getBoundingClientRect(),
                elemRect = ele.getBoundingClientRect(),
                offset   = elemRect.top - bodyRect.top;

                if(window.innerWidth > ProductHighlightsConstants.innerWidth_4600) {
                    offset = ProductHighlightsConstants.offset_10000;
                } else if(window.innerWidth > ProductHighlightsConstants.innerWidth_3000){
                    offset = ProductHighlightsConstants.offset_7100;
                } else if(window.innerWidth > ProductHighlightsConstants.innerWidth_2300) {
                    offset = ProductHighlightsConstants.offset_5600;
                } else if(window.innerWidth > ProductHighlightsConstants.innerWidth_2000) {
                    offset = ProductHighlightsConstants.offset_5100;
                } else if(window.innerWidth > ProductHighlightsConstants.innerWidth_1900) {
                    offset = ProductHighlightsConstants.offset_4800;
                } else if(window.innerWidth > ProductHighlightsConstants.innerWidth_1700) {
                    offset = ProductHighlightsConstants.offset_4500;
                } else if(window.innerWidth > ProductHighlightsConstants.innerWidth_1500) {
                    offset = ProductHighlightsConstants.offset_4300;
                } else if(window.innerWidth > ProductHighlightsConstants.innerWidth_1200) {
                    offset = ProductHighlightsConstants.offset_4200;
                } else if(window.innerWidth > ProductHighlightsConstants.innerWidth_380) {
                    offset = ProductHighlightsConstants.offset_5000;
                }

                window.scrollTo({
                    top: offset,
                    left: 200,
                    behavior: "smooth",
                });
                setLearnMore(!learnMore);
            }
        }
    }, []);

    const learnMoreButton = () => {
        let button;
        if(learnMoreCta){
            if (learnMore) {
                button = (
                    <ObLink ariaExpanded={true} className="primaryGrey ob-best-clean-learn-more event_view_more_details" dataActionDetail={learnMoreDataActionDetail} tag={ProductHighlightsConstants.button} ariaLabel={ProductHighlightsConstants.learnMore} onClick={handleLearnMore} >
                          <Icon name="chevronDown" />
                          {learnMoreCta}
                          <span className="visuallyhidden">{learnMoreCta}</span>
                    </ObLink>
                )
            } else {
                button = (
                    <ObLink ariaExpanded={false} className="primaryGrey ob-best-clean-learn-more event_view_more_details" dataActionDetail={learnMoreDataActionDetail} tag={ProductHighlightsConstants.button} onClick={handleLearnMore} ariaLabel={ProductHighlightsConstants.learnMore}>
                        <Icon name="chevronRight" />
                        {learnMoreCta}
                        <span className="visuallyhidden">{learnMoreCta}</span>
                    </ObLink>
                );
            }
      
        }
        
        return button;
    };
    
  
    const handleLearnMore = () => {
        setLearnMore(!learnMore);
    };

    return (
        <>
        <div
            id={id}
            className={className}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text, { ADD_TAGS: ["iframe"] }, { ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}            
        >
        </div>
        { learnMoreCta && learnMore ? learnMoreButton() : <div> {new Block(relatedBlocks).renderSpecifiedBlock(relatedBlocks)} </div> }
        </>
    )
}

HTMLBlock.propTypes = {

};

export default HTMLBlock;

