import { useRouter } from 'next/router';
import { observable, configure } from 'mobx'
import { SearchBlockConstants } from './Constants';
import * as React from 'react';
import { customJsonstringify } from './Utils';
// useRouter query is undefined on the first render
export function useRouterQueryParam(key) {
  const router = useRouter()

  const value = React.useMemo(() => {
    const res = router.asPath.match(new RegExp(`[&?]${key}=(.*)(&|$)`)) || [];
    if(res && res.length > 0){
      return res[1];
    }
  }, [router.asPath, key]);

  return value;
}
configure({
  enforceActions: 'never',
})

export let popoverState = observable({
  enabled: true,
})

export const toggle = (value) => {
  popoverState.enabled = value
}

export const formatDate = (date) => {
    let month = date.getMonth() + 1,
      day = date.getDate(),
      year = date.getFullYear()
  
    if (month.toString().length < 2) month = '0' + month
    if (day.toString().length < 2) day = '0' + day
  
    return [year, month, day].join('-')
  }
 
  export const searchConfig= {
    algoliaIndexName: process.env.ALGOLIA_INDEX,
    algoliaAppID:process.env.ALGOLIA_APP_ID,
    algoliaSearchOnlyAPIToken: process.env.ALGOLIA_APP_SEARCH_KEY
   }
  export const getLSValue = (key) => {
    if (typeof window !== 'undefined') {
      const item = window.localStorage.getItem(key)
      return JSON.parse(item)
    }
  }
  
  export const setLSValue = (key, value) => {
    try {
      window.localStorage.setItem(key, customJsonstringify(value))
    } catch (error) {
      console.error(error)    }
   
  }
  const getHashValue=()=> {
    if(typeof window!='undefined'){
    return window?.location?.hash;
    }
  }
  
  export const getHashVariable=(variable)=> {
    let result = '';
    let searchQueryStrings = getHashValue();

    if (searchQueryStrings && searchQueryStrings.length > 1) {
        let query = searchQueryStrings.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split(SearchBlockConstants.queryHashEqual);
            if (pair && pair.length !== 0 && decodeURIComponent(pair[0]) === variable) {
                result = decodeURIComponent(pair[1]);
            }
        }
    }
    return result;
}

