import { useState, useEffect } from 'react';

let listeners = [];
let state = { formErrorResponse: undefined, isInvalidForm: false, givenName: undefined};

const setState = (newState) => {
    state = { ...state, ...newState };
    listeners.forEach((listener) => {
        listener(state);
    });
};

const useJanrainGlobalState = () => {
    const newListener = useState()[1];
    useEffect(() => {
        listeners.push(newListener);
    
        return function cleanup() {
            if (listeners.indexOf(newListener) >= 0) {
                listeners.splice(listeners.indexOf(newListener), 1);
            }
        };
    }, []);
    return [state, setState];
};

export default useJanrainGlobalState;
