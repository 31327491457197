import React, { useContext } from 'react';
import Image from '../Image/Image';
import Button from '../Button/Button';
import Block from '../../adapters/helpers/Block';
import Heading from '../Heading/Heading';
import { Transition } from 'react-transition-group';
import OnePageScrollContext from '../OnePageScroll/OnePageScrollContext';
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import {SpotlightPreorderConstants} from '../../adapters/helpers/Constants';

function SpotlightPreorder(props) {
    const {document, isMedium, index} = props;
    const block = new Block(document);

    const context = useContext(OnePageScrollContext);
    const currentIndex = context?.currentIndex || 0;
    const isActiveBlock = (index === currentIndex);

    const body = block.getFieldValue(SpotlightPreorderConstants.body);
    const title = block.getFieldValue(SpotlightPreorderConstants.title);
    const anchorId = block.getFieldValue(SpotlightPreorderConstants.anchorId);
    const classNames = block.getFieldValue(SpotlightPreorderConstants.classNames);
    const backgroundAsset = block.getFieldValue(SpotlightPreorderConstants.backgroundAsset);
    const preOrderButtonLabel = block.getFieldValue(SpotlightPreorderConstants.preOrderButtonLabel);
    const preOrderCallToAction = block.getFieldValue(SpotlightPreorderConstants.preOrderCallToAction);
    const mobileBackgroundAsset = block.getFieldValue(SpotlightPreorderConstants.mobileBackgroundAsset);

    return (
        <Transition in={index === undefined} timeout={600}>
            {() => (
                <div className={`ob-spotlightContentBlock ob-spotlight-preorder ${classNames}`} id={anchorId} data-isactiveblock={isActiveBlock} >
                    {isMedium && mobileBackgroundAsset &&
                        <Image image={mobileBackgroundAsset} />
                    }
                    <Image image={backgroundAsset && !isMedium ? backgroundAsset : undefined} customStyles={{backgroundSize: 'cover', backgroundPositionY: '-15px', backgroundRepeat: 'no-repeat'}}>
                        <div className={'ob-spotlight-preorder__wrapper'}>
                            <Heading className={'ob-spotlight-preorder__title'}>{title}</Heading>
                            <p className={'ob-spotlight-preorder__body'}>{body}</p>
                            <Button tag="a" href={preOrderCallToAction}>{preOrderButtonLabel.fields.text}</Button>
                        </div>
                    </Image>
                </div>
            )}
        </Transition>
    );
}

export default mediaQueryHOC(SpotlightPreorder);

SpotlightPreorder.propTypes = {
    document: PropTypes.any,
    isMedium: PropTypes.bool,
    index: PropTypes.any,
}