class Document {

    constructor(props) {
        this.props = props;
    }

    getFieldValue(fieldName) {
        const value = this.getFields()[fieldName];
        return value;
    }

    setFieldValue(propName, propValue) {
        this.props[propName] = propValue;
    }

    getFields() {
        let fields = this.getDocument().fields;
        if (!fields) {
            fields = {};
        }
        return fields;
    }

    getDocument() {
        let doc = this.props?.document;

        if (doc == null) {
            doc = this.props;
        }

        if (!doc) {
            doc = {};
        }

        return doc;
    }
}

export default Document