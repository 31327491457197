import styled, { keyframes } from 'styled-components'

export const buttonColors = {
    light: {
        border: '#000000',
        text: '#000000',
        background: 'transparent'
    },
    dark: {
        border: '#000000',
        text: '#FFFFFF',
        background: '#000000'
    }
}

export const oppositeColor = (variant) => variant === 'light' ? 'dark' : 'light'

export const ButtonWrapper = styled.a`
  display: block;
  background: ${({ variant }) => buttonColors[variant].background};
  padding: 10px 20px;
  outline: none;
  border: 2px solid ${({ variant }) => buttonColors[variant].border};
  cursor: pointer;
  font-size: 0.9rem;
  text-align: center;
  text-decoration: none;
  
  &:hover{
    background-color: ${({ variant, disabled }) => buttonColors[disabled ? variant : oppositeColor(variant)].background};
    border-color: ${({ variant, disabled }) => buttonColors[disabled ? variant :oppositeColor(variant)].border};
  }
  
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
`
export const linkStyle = `  
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
`

export const ButtonLink = styled.span`
  color: ${({ variant }) => buttonColors[variant].text};
  
  ${ButtonWrapper}:hover & {
    color: ${({ variant, disabled }) => buttonColors[disabled ? variant : oppositeColor(variant)].text};
  }
  
  ${linkStyle}
`

const loaderColors = {
    main: '#e2e2e2',
    second: '#545454',
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Loader = styled.span`
  border: 4px solid ${loaderColors.main};
  border-radius: 50%;
  border-top: 4px solid ${loaderColors.second};
  width: 9px;
  height: 9px;
  animation: ${spin} 2s linear infinite;
  display: inline-block;
  margin-left: 3%;
  vertical-align: middle;
`
