import React from 'react';
import JanrainForm from '../Janrain/Janrain/HOC/JanrainForm';
import RegisterProductForm from '../Janrain/Janrain/janrainForms/RegisterProductForm/RegisterProductForm';


export default function RegisterProductBlock(props) {

    const title = props?.document?.fields?.title;
    const step1Heading = props?.document?.fields?.step1Heading?.fields?.text;
    const step2Heading = props?.document?.fields?.step2Heading?.fields?.text;
    const productGroupDescription = props?.document?.fields?.productGroupDescription;
    const selectProductTypeLabel = props?.document?.fields?.selectProductType?.fields?.text;
    const selectProductLabel = props?.document?.fields?.selectProduct?.fields?.text;
    const dateOfPurchaseLabel = props?.document?.fields?.dateOfPurchaseLabel?.fields?.text;
    const dateOfPurchasePlaceholder = props?.document?.fields?.dateOfPurchasePlaceholder?.fields?.text;
    const dateOfPurchaseDisclaimer = props?.document?.fields?.dateOfPurchaseDisclaimer;
    const dateOfPurchaseValidation = props?.document?.fields?.dateValidation;
    const noProductSelectedLabel = props?.document?.fields?.noProductSelectedLabel?.fields?.text;
    const registerNewProductButtonLabel = props?.document?.fields?.registerNewProductButtonLabel?.fields?.text;
    const receiptPicture = props?.document?.fields?.receiptPicture;
    const oralCareGoals = props?.document?.fields?.oralCareGoals?.fields?.text;
    const selectGoals = props?.document?.fields?.selectGoals?.fields?.text;
    const reminderToReplaceLabel = props?.document?.fields?.reminderToReplace?.fields?.text;
    const registerProductButtonText = props?.document?.fields?.registerProductButtonText?.fields?.text;
    const selectProductButtonLabel = props?.document?.fields?.selectProductButtonLabel?.fields?.text;
    const selectedProductButtonLabel = props?.document?.fields?.selectedProductButtonLabel?.fields?.text;
    const productCategoryList = props?.document?.fields?.registerProductCategoryList;
    const warrantyErrorLabel = props?.document?.fields?.warrantyErrorLabel?.fields?.text;
    const successLink = props?.document?.fields?.successLink;
    const validPurchaseTimeWindow = props?.document?.fields?.validPurchaseTimeWindow;
    const warrantyExtensionTime = props?.document?.fields?.warrantyExtensionTime;
    const noProductWarrantyLabel = props?.document?.fields?.noProductWarrantyLabel?.fields?.text;
    const gcsDownErrorMessage = props?.document?.fields?.gcsDownErrorMessage?.fields?.text;
    const previousLabel = props?.document?.fields?.previousLabel?.fields?.text;
    const nextLabel = props?.document?.fields?.nextLabel?.fields?.text;
    const activateGWEWarrantyEmail = props?.document?.fields?.activateGWEWarrantyEmail || false;
    const brushListTitle = props?.document?.fields?.brushListTitle;
    const productImageDisclaimerText = props?.document?.fields?.productImageDisclaimerText;
    const comesBackDescription = props?.document?.fields?.comesBackDescription;
    const productTypes = [];

    const registerProductFormLabels = {
        step1Heading: step1Heading,
        step2Heading: step2Heading,
        brushListTitle: brushListTitle,
        productGroupDescription: productGroupDescription,
        selectProductTypeLabel: selectProductTypeLabel,
        selectProductLabel: selectProductLabel,
        selectedProductButtonLabel: selectedProductButtonLabel,
        dateOfPurchaseLabel: dateOfPurchaseLabel,
        dateOfPurchasePlaceholder: dateOfPurchasePlaceholder,
        dateOfPurchaseDisclaimer: dateOfPurchaseDisclaimer,
        noProductSelectedLabel: noProductSelectedLabel,
        registerNewProductButtonLabel: registerNewProductButtonLabel,
        oralCareGoals: oralCareGoals,
        selectGoals: selectGoals,
        reminderToReplaceLabel: reminderToReplaceLabel,
        registerProductButtonText: registerProductButtonText,
        registerProductProductSelect: selectProductButtonLabel,
        warrantyErrorLabel: warrantyErrorLabel,
        noProductWarrantyLabel: noProductWarrantyLabel,
        gcsDownErrorMessage: gcsDownErrorMessage,
        previousLabel: previousLabel,
        nextLabel: nextLabel,
        productImageDisclaimerText: productImageDisclaimerText,
        comesBackDescription: comesBackDescription
    };

    productCategoryList.forEach(element => {
        productTypes.push({ value: element.fields.name, id: element.fields.name });
    });

    return (
        <JanrainForm needActiveSession={true}>
            <RegisterProductForm
                formHeading={title}
                labels={registerProductFormLabels}
                receiptPicture={receiptPicture}
                dateOfPurchaseValidation={dateOfPurchaseValidation}
                successRedirectPath={successLink?.fields?.pagePath?.fields?.slug}
                validPurchaseTimeWindow={validPurchaseTimeWindow}
                warrantyExtensionTime={warrantyExtensionTime}
                activateGWEWarrantyEmail={activateGWEWarrantyEmail}
                registerDateOfBirthFieldLabel={''}
            />
        </JanrainForm>
    )
}
