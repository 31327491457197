export const inputs = {
    formName: 'editProfileForm',
    type: {
        text: 'text',
        email: 'email',
        radio: 'radio',
        date: 'date',
        hidden: 'hidden',
        phone: 'tel'
    },
    field: {
        firstName: 'firstName',
        lastName: 'lastName',
        emailAddress: 'emailAddress',
        phone: 'phone',
        addressPostalCode: 'addressPostalCode',
        dateOfBirth: 'dateOfBirth',
        addressCountry: 'addressCountry',
        birthdate: {
            dateselect_month: 'birthdate[dateselect_month]',
            dateselect_year: 'birthdate[dateselect_year]',
            dateselect_day: 'birthdate[dateselect_day]'
        },
        traitProductType: {
            traitValue: 'traitProductType_traitValue',
            imageUrl: 'traitProductType_imageUrl',
            registrationDate: 'traitProductType_registrationDate'
        },
        traitBenefitPreferences: 'traitBenefitPreferences',
        traitBrushHeadReminderOptInFlag: 'traitBrushHeadReminderOptInFlag'
    }
}
