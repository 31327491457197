
import React from 'react';
import { objectWithLabel } from '../../adapters/helpers/Utils'
import { ChangePasswordForm } from '../Janrain/Janrain/janrainForms';
import JanrainForm from '../Janrain/Janrain/HOC/JanrainForm';

export default function ChangePasswordBlock(props) {

    const formHeading = props?.document?.fields?.title;
    const formSubHeadingSuccess = props?.document?.fields?.subtitleSuccess?.fields?.text;
    const formSuccessLink = props?.document?.fields?.successLink;
    const ariaFormIsSubmitting = props?.document?.fields?.ariaFormIsSubmitting?.fields?.text;
    const janrainErrorList = props?.document?.fields?.janrainErrors?.fields

    const changePasswordFormLabels = {
        currentPasswordFieldLabel: props?.document?.fields?.currentPasswordFieldLabel?.fields?.text,
        changePasswordNewPasswordFieldLabel: props?.document?.fields?.newPasswordFieldLabel?.fields?.text,
        changePasswordConfirmNewPasswordFieldLabel: props?.document?.fields?.reEnterPasswordFieldLabel?.fields?.text,
        passwordRequirements: props?.document?.fields?.minimumCharacterLabel,
        submitButtonLabel: props?.document?.fields?.submitButtonLabel?.fields?.text,
    };

    return (
        <JanrainForm needActiveSession={true}>
            <ChangePasswordForm
                formHeading={formHeading}
                formSubHeadingSuccess={formSubHeadingSuccess}
                formSuccessLink={formSuccessLink}
                janrainFormErrors={objectWithLabel(janrainErrorList)}
                ariaFormIsSubmitting={ariaFormIsSubmitting}
                janrainLabels={changePasswordFormLabels}
            />
        </JanrainForm>
    )
}
