import React  from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { profileValidation } from '../form.validators'
import {
    EditProfile,
    ProductManagement,
    UpdateBenefitPreferences
} from '../../../components'
import ProfileStore from './profile.store'

const profileStore = new ProfileStore(profileValidation, 7);

const ProfileForm = ({
                         userData,
                         oralbBenefitPreferenceList,
                         oralbProductList,
                         currentTab,
                         profileProductManagementTab,
                         commonLabels,
                         janrainErrors,
                         profileEditTab,
                         profileBenefitPreferencesTab,
                         setOralbProductList,
                         isGCSAPIDown}) => {
    const { form, errors, formValidated } = profileStore;
    const closeModalTimeoutMS = 250;

    const customStyles = {
        overlay: {
            boxShadow           : 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background          : 'rgba(0, 0, 0, 0.5)',
            display             : 'flex',
            justifyContent      : 'center',
            alignItems          : 'center'
        },
        content: {
            position            : 'static',
            display             : 'flex'
        }
    };

    return (
        <div className={'ob-profile-form'}>
            <div className={'ob-form__layout ob-profile__wrapper'}>

                <div className={`ob-profile__tab-container ${currentTab === 0 ? 'is-active' : ''}`}>
                    <ProductManagement
                        userData={userData}
                        oralbProductList={oralbProductList}
                        profileProductManagementTab={profileProductManagementTab}
                        commonLabels={commonLabels}
                        janrainErrors={janrainErrors}
                        customStyles={customStyles}
                        closeModalTimeoutMS={closeModalTimeoutMS}
                        setOralbProductList={setOralbProductList}
                        isGCSAPIDown={isGCSAPIDown}
                    />
                </div>

                <div className={`ob-profile__tab-container ${currentTab === 1 ? 'is-active' : ''}`}>
                    <EditProfile
                        janrainErrors={janrainErrors}
                        errors={errors}
                        profileStore={profileStore}
                        form={form}
                        userData={userData}
                        profileEditTab={profileEditTab}
                        formValidated={formValidated}
                        commonLabels={commonLabels}
                        customStyles={customStyles}
                        closeModalTimeoutMS={closeModalTimeoutMS}
                    />
                </div>

                <div className={`ob-profile__tab-container ${currentTab === 2 ? 'is-active' : ''}`}>
                    <UpdateBenefitPreferences
                        oralbBenefitPreferenceList={oralbBenefitPreferenceList}
                        userData={userData}
                        profileBenefitPreferencesTab={profileBenefitPreferencesTab}
                        commonLabels={commonLabels}
                        customStyles={customStyles}
                        closeModalTimeoutMS={closeModalTimeoutMS}
                    />
                </div>
            </div>
        </div>
    )
};

ProfileForm.propTypes = {
    userData: PropTypes.object.isRequired,
    oralbBenefitPreferenceList: PropTypes.array,
    oralbProductList: PropTypes.array,
    currentTab: PropTypes.number,
    profileProductManagementTab: PropTypes.object,
    commonLabels: PropTypes.object,
    janrainErrors: PropTypes.object,
    profileEditTab: PropTypes.object,
    profileBenefitPreferencesTab: PropTypes.object,
    setOralbProductList: PropTypes.func
};

export default observer(ProfileForm)
// This export is for unit testing (do not remove) :
export const ProfileFormTest = ProfileForm;