import styled from 'styled-components'

const colors = {
    darkGray: '#333333',
}

export const RadioWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction:row;
  margin-bottom: 2%;
  
  @media(min-width: 768px){
    margin-bottom: 2%;
  }
`

export const InputLabel = styled.label`
  text-transform: uppercase;
  font-size: 0.9rem;
  margin: 0.3% 0;
`

export const Input = styled.input`
  background: none;
  color: ${colors.darkGray};
  font-size: 0.9rem;
  padding: 2%;
  border: 0;
  cursor: pointer;
  
  @media(min-width: 768px){
    margin-left: 0;
  }
`
