import React, {cloneElement, Children, Component} from 'react';
import PropTypes from 'prop-types';
import { RotationConstants } from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';

export default class Rotation extends Component {
    static propTypes = {
        className: PropTypes.string,
        cycle: PropTypes.bool,
        vertical: PropTypes.bool,
        reverse: PropTypes.bool,
        isDisabled: PropTypes.bool,
        autoPlay: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number
        ]),
        onChange: PropTypes.func,
        children: PropTypes.arrayOf(PropTypes.element).isRequired,
        tabIndex: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        pauseOnHover: PropTypes.bool,
        playOnce: PropTypes.bool
    };
    
    static defaultProps = {
        cycle: false,
        vertical: false,
        tabIndex: 0,
        isDisabled: false,
        autoPlay: false,
        pauseOnHover: false,
        playOnce: false,
        onChange: () => {}
    };
    
    hovered = false;
    
    divRef = null;
    
    state = {
        current: 0
    };
    
    componentDidMount () {
        document.addEventListener(RotationConstants.mouseup, this.touchEnd, false);
        if (this.props.autoPlay) {
            this.nextFrame();
        }
    }
    
    componentDidUpdate ({autoPlay}) {
        if (autoPlay !== this.props.autoPlay) {
            if (autoPlay) {
                this.nextFrame();
            } else {
                this.stop();
            }
        }
    }
    
    componentWillUnmount () {
        document.removeEventListener(RotationConstants.mouseup, this.touchEnd, false);
        this.stop();
    }
    
    setCurrentFrame (frame) {
        const {cycle, children, onChange} = this.props;
        const length = children.length;
        let current = frame;
        
        if (current < 0) {
            current = cycle ? current + length : 0;
        }
        
        if (current > length - 1) {
            current = cycle ? current - length : length - 1;
        }
        
        if(!this.props.isDisabled) {
            if (current !== this.state.current) {
                this.setState({current});
                onChange(current);
            } else if (this.props.autoPlay) {
                this.stop();
            }
        }
    }
    
    nextFrame () {
        const {current} = this.state;
        const {reverse, autoPlay, pauseOnHover, children, playOnce} = this.props;
        const playTimeout = autoPlay === true ? 75 : autoPlay;
        
        if (!this.hovered || !pauseOnHover) {
            this.setCurrentFrame(reverse ? current - 1 : current + 1)
        }
        
        this.nextTimeout = setTimeout(() => {
            this.nextFrame();
        }, playTimeout);
        
        if (current === children.length - 1 && playOnce) {
            this.stop();
        }
    }
    
    stop () {
        clearTimeout(this.nextTimeout);
    }
    
    hover = () => {
        this.hovered = true;
    };
    
    unhover = () => {
        this.hovered = false;
    };
    
    touchStart = event => {
        if(!this.props.isDisabled) {
            event.preventDefault();
            this.divRef.style.cursor = RotationConstants.grabbing;
            this.pointerPosition = this.calculatePointerPosition(event);
            this.startFrame = this.state.current;
            this.stop()
        }
    };
    
    touchMove = event => {
        if(!this.props.isDisabled) {
            const notTouched = typeof this.pointerPosition !== RotationConstants.number;
            event.preventDefault();

            if (notTouched) {
                return;
            }

            const {vertical, children, reverse} = this.props;
            const {offsetWidth, offsetHeight} = event.currentTarget;
            const pointer = this.calculatePointerPosition(event);
            const max = vertical ? offsetHeight : offsetWidth;
            const offset = pointer - this.pointerPosition;
            const delta = Math.floor(offset / max * children.length);
            this.setCurrentFrame(reverse ? this.startFrame - delta : this.startFrame + delta);
        }
    };
    
    touchEnd = event => {
        if(!this.props.isDisabled) {
            event.preventDefault();
            this.divRef.style.cursor = RotationConstants.grab;
            this.pointerPosition = null;
            this.startFrame = null;
        }
    };
    
    pressKey = event => {
        if(!this.props.isDisabled) {
            const eventOnField = event.target.tagName.match(RotationConstants.match);

            if (eventOnField) {
                return;
            }

            const {current} = this.state;
            const {vertical, reverse} = this.props;
            const prevKey = vertical ? 38 : 37;
            const nextKey = vertical ? 40 : 39;
            this.stop();

            if (event.keyCode === prevKey) {
                this.setCurrentFrame(reverse ? current + 1 : current - 1);
            } else if (event.keyCode === nextKey) {
                this.setCurrentFrame(reverse ? current - 1 : current + 1);
            }
        }
    };
    
    calculatePointerPosition (event) {
        const {clientX, clientY} = event.type.indexOf(RotationConstants.touch) === 0 ? event.changedTouches[0] : event;
        const {offsetTop, offsetLeft} = event.currentTarget;
        return this.props.vertical ? clientY - offsetTop : clientX - offsetLeft;
    }
    
    render () {
        const {current} = this.state;
        
        const {
            children,
            className,
            tabIndex,
            pauseOnHover
        } = this.props;
        
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                ref={inst => {this.divRef = inst}}
                tabIndex={this.props.isDisabled ? '-1' : tabIndex}
                onTouchStart={this.touchStart}
                onTouchMove={this.touchMove}
                onTouchEnd={this.touchEnd}
                onMouseDown={this.touchStart}
                onMouseMove={this.touchMove}
                onMouseEnter={pauseOnHover ? this.hover : null}
                onMouseLeave={pauseOnHover ? this.unhover : null}
                onKeyDown={tabIndex >= 0 ? this.pressKey : null}
                data-action-detail={AnalyticsConstants.rotationView}
                className={'event_button_click ' + className}
                style={{position: 'relative', cursor: this.props.isDisabled ? 'default' : 'grab'}}>
                {Children.map(children, (child, i) => cloneElement(
                    child,
                    {
                        style: {
                            width: '100%',
                            display: current === i ? 'block' : 'none'
                        }
                    }
                ))}
            </div>
        )
    }
}
