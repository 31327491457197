import React from 'react';
import RegisterTypeCodeForm from '../Janrain/Janrain/janrainForms/RegisterTypeCodeForm/RegisterTypeCodeForm';
import JanrainForm from '../Janrain/Janrain/HOC/JanrainForm';

export default function RegisterTypeCodeBlock(props) {

    const title = props?.document?.fields?.registerTitle;
    const description = props?.document?.fields?.description;
    const subTitle = props?.document?.fields?.registerSubTitle;
    const helpText = props?.document?.fields?.helpText;
    const comboProductPackOptionText = props?.document?.fields?.comboProductPackOptionText;
    const comboPackOptionHelpText = props?.document?.fields?.comboPackOptionHelpText;
    const proTipText = props?.document?.fields?.proTipText;
    const selectFromListText = props?.document?.fields?.selectFromListText;
    const invalidTypeCodeErrorText = props?.document?.fields?.invalidTypeCodeErrorText;
    const invalidTypeCodeHelpText = props?.document?.fields?.invalidTypecodeHelpText;
    const registerProductSeriesList = props?.document?.fields?.registerProductSeriesList;
    const registerProductSubgroupList = props?.document?.fields?.registerProductSubgroupList;
    const tipImage = props?.document?.fields?.tipImage;
    const validTypeCodeImage = props?.document?.fields?.validTypeCodeImage;
    const successRedirectPath = props?.document?.fields?.successRedirectPath;
    const registerTypeCodeButtonText = props?.document?.fields?.registerTypeCodeButtonText;
    const typecodeListPopupSelectLabel = props?.document?.fields?.typecodeListPopupSelectLabel;
    const typecodeListPopupCancelLabel = props?.document?.fields?.typecodeListPopupCancelLabel;
    const noTypeCodesError = props?.document?.fields?.noTypeCodesError;
    const selectTypecodeTitle = props?.document?.fields?.selectTypecodeTitle;
    const brushListTitle = props?.document?.fields?.brushListTitle;
    const comesBackDescription = props?.document?.fields?.comesBackDescription;
    const lstInternSubGroup = [];
    const lstSeries = [];
    const registerTypeCodeFormLabels = {
        title: title,
        description: description,
        subTitle: subTitle,
        helpText: helpText,
        comboProductPackOptionText: comboProductPackOptionText,
        comboPackOptionHelpText: comboPackOptionHelpText,
        proTipText: proTipText,
        selectFromListText: selectFromListText,
        invalidTypeCodeErrorText: invalidTypeCodeErrorText,
        invalidTypeCodeHelpText: invalidTypeCodeHelpText,
        registerTypeCodeButtonText: registerTypeCodeButtonText,
        typecodeListPopupSelectLabel: typecodeListPopupSelectLabel,
        typecodeListPopupCancelLabel: typecodeListPopupCancelLabel,
        noTypeCodesError: noTypeCodesError,
        selectTypecodeTitle: selectTypecodeTitle,
        brushListTitle: brushListTitle,
        comesBackDescription: comesBackDescription
    };
    
    registerProductSubgroupList.forEach(element => {
        lstInternSubGroup.push(element?.fields?.text);
    });
    registerProductSeriesList.forEach(element => {
        lstSeries.push(element?.fields?.text);
    });
    return (
        <JanrainForm needActiveSession={true}>
            <RegisterTypeCodeForm
                formHeading={title}
                labels={registerTypeCodeFormLabels}
                tipImage={tipImage}
                validTypeCodeImage={validTypeCodeImage}
                lstInternSubGroup={lstInternSubGroup}
                lstSeries={lstSeries}
                successRedirectPath={successRedirectPath?.fields?.slug}
            />
        </JanrainForm>
    )
}
