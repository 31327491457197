import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ProductOverview from '../ProductOverview';
import { Waypoint } from 'react-waypoint';

function StackedProduct(props) {
    const [isShown, setIsShown] = useState(false);

    const handleWaypoint = useCallback(() => {
        setIsShown(true);
    }, []);

    return (
        <Waypoint onEnter={handleWaypoint} topOffset={'500px'} bottomOffset={'300px'}>
            <div className='ob-stacked-products-overview-wrapper'>
                <ProductOverview {...{...props, isShown}}/>
            </div>
        </Waypoint>
    )
}

export default function StackedProductsOverview({products, whiteText, onClickCallback, productOverview, isMedium, removeLazyLoad}) {
    return (
        <div className='ob-stacked-products-overview'>
            {products.map((product, i) => (
                <StackedProduct key={i}
                    product={product}
                    onClickCallback={onClickCallback}
                    index={i}
                    isAnimated
                    isAutoHeightMode
                    whiteText={whiteText}
                    isMedium={isMedium}
                    removeLazyLoad={removeLazyLoad}
                    productOverview={productOverview}/>))}
        </div>);
}

StackedProductsOverview.defaultProps = {
    products: [],
}

StackedProductsOverview.propTypes = {
    products: PropTypes.array,
    whiteText: PropTypes.bool,
    onClickCallback: PropTypes.func,
    productOverview: PropTypes.any,
    isMedium: PropTypes.bool,
    removeLazyLoad: PropTypes.bool
}
