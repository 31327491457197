export const inputs = {
    formName: 'signInForm',
    type: {
        email: 'email',
        password: 'password'
    },
    field: {
        signInEmailAddress: 'signInEmailAddress',
        currentPassword: 'currentPassword'
    }
};
