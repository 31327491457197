import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export class ListItem extends React.Component {

	handleMouseOver = (e) => {
		e.stopPropagation()
		this.props.onMouseOver(this.props.index)
	}

	handleChange = (ev) => {
		this.props.onChange({event: ev, index: this.props.index})
	}

	render() {
		let props = this.props
		let classes = cx('react-list-select--item', {
			'is-disabled': props.disabled,
			'is-selected': props.selected,
			'is-focused': props.focused,
		})

		return (
			<li
				className={classes}
				onMouseOver={this.handleMouseOver}
				onFocus={ () => void 0 }
				onClick={this.handleChange}
				id={`item-${props.index}`}
				tabIndex='-1'
				onKeyPress={()=>{}}
				role='menuitem'
			>
				{props.children}
			</li>
		)
	}
}

ListItem.propTypes = {
	onMouseOver: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired
}

ListItem.defaultProps = {
	disabled: false,
	selected: false,
	focused: false,
}
