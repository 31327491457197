import React from 'react';

import Block from '../../adapters/helpers/Block';
import PropTypes from 'prop-types'
import Backdrop from '../Backdrop/Backdrop';
import Heading from '../Heading/Heading';
import ObLink from '../ObLink/ObLink';
import {Label} from '../Label/Label';

import {ArticleHeroBlockConstants} from '../../adapters/helpers/Constants'

import Disclaimer from '../Disclaimer/Disclaimer';

export default function ArticleHeroBlock(props) {
    const block = new Block(props);
    const {extraAttributes, document} = props;
    const className = block.getFieldValue(ArticleHeroBlockConstants.classNames);
    const anchorId = block.getAnchorId();

    const title = extraAttributes?.entity?.articleOverview?.fields?.title;
    const eyebrow = extraAttributes?.entity?.category?.fields?.title;
    const slug = extraAttributes?.entity?.category?.fields?.slug?.fields?.slug;
    const readingTimeLabel = Label({label: block.getFieldValue(ArticleHeroBlockConstants.readTimeLabel), tokens: {readTime: extraAttributes.entity.articleOverview.fields.readTime}});
    const body = extraAttributes?.entity?.articleOverview?.fields?.description;
    const backgroundAsset = extraAttributes?.entity?.backgroundAsset;
    const mobileBackgroundAsset = document?.fields?.mobileBackgroundAsset;
    const disclaimer = document?.fields?.disclaimer;

    return (
        <div className={`ob-article-hero ${className || ''}`} id={anchorId}>
            <Backdrop>
                <Backdrop.Image image={backgroundAsset} mobileImage={mobileBackgroundAsset} />

                <Backdrop.Content className="ob-article-hero-content">
                    <div className="ob-article-hero-inner">
                        <ObLink href={`${slug}`}>
                            {eyebrow}
                        </ObLink>

                        <Heading className="ob-article-hero-heading" tag="h1">
                            {title}
                        </Heading>

                        <p className="ob-article-hero-text">
                            {body}
                        </p>
                        { readingTimeLabel &&
                            <p className="ob-article-hero-time">
                                {readingTimeLabel}
                            </p>
                        }

                        {disclaimer &&
                            <Disclaimer document={disclaimer} />
                        }
                    </div>
                </Backdrop.Content>
            </Backdrop>
        </div>
    );
}

ArticleHeroBlock.propTypes = {
    document: PropTypes.object,
    extraAttributes: PropTypes.object
}
