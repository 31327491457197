import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {focusOnElement} from '../../../../../adapters/helpers/Utils'
import { Constants } from '../../../../../adapters/helpers/Constants'

const FormErrors = ({ statusMessage, form, errors, predefinedErrors }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        const errorWrapper = document.querySelector('.ob-error__wrapper');

        if (errorWrapper) {
            focusOnElement(errorWrapper);
        }
    }, [statusMessage]);

    const isDuplicateEmail = (message = "") => message.includes(Constants.alreadyInUse);

    const getValidationError = () => {
        if(form === 'registrationForm' && errors && errors.length) {
            return errors.map((error, index) => {
                if (error?.field === 'emailAddress') {
                    return isDuplicateEmail(error?.message) ? predefinedErrors.emailAlreadyExists : predefinedErrors.emailNotAllowedError;
                } else {
                    return predefinedErrors.inputsNotValid;
                }
            });
        } else {
            return predefinedErrors.inputsNotValid;
        }
    };

    const errorText = () => {
        switch (statusMessage) {
            case 'invalidCredentials':
                if (form === 'signInForm') {
                    return predefinedErrors.errorInLoginForm;
                } else {
                    return predefinedErrors.invalidCredentials;
                }
            case 'validationErrors':
                return getValidationError();
            case 'accountDeactivated':
                return predefinedErrors.accountDeactivated;
            case 'invalidAccountIdentifier' :
                return predefinedErrors.invalidCredentials;
            case 'rateLimitExceeded':
                return predefinedErrors.rateLimitExceeded;
            default:
                return getValidationError();

        }
    }

    return (
        <div className="ob-error__wrapper">
            <p className="ob-error__heading">{errorText()}</p>
        </div>
    )
}

FormErrors.propTypes = {
    statusMessage: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    errors: PropTypes.shape({
        emailNotValid: PropTypes.string.isRequired,
        noDigitsAllowed: PropTypes.string.isRequired,
        passwordIsTooShort: PropTypes.string.isRequired,
        passwordMustContainDigits: PropTypes.string.isRequired,
        passwordMustContainLowercaseLetters: PropTypes.string.isRequired,
        passwordMustContainUppercaseLetters: PropTypes.string.isRequired,
        accountDeactivated: PropTypes.string.isRequired,
        inputsNotValid: PropTypes.string.isRequired,
        invalidCredentials: PropTypes.string.isRequired,
        errorInLoginForm: PropTypes.string.isRequired,
        errorInRegisterForm: PropTypes.string.isRequired
    })
}

export default FormErrors
