import { getHashVariable } from '../helpers/AlgoliaHelper';
import { SearchBlockConstants,algoliaReducerConstants } from '../helpers/Constants';
export const initialState = {
  SearchClicked: false,
  queryParm: getHashVariable(SearchBlockConstants.queryHashName),
  hitsResponse: [],
  isSpinning: false,
  searchStaticContent: {},
  showAutocomplete: false,
  searchValue: '',
  filerClicked: '',
  pageNoClicked: null,
  totalPageNumber: null,
  filterItems: [],
  nbHits: null,
  nbPages: null,
};
export const searchReducer = (state, action) => {
  switch (action.type) {
    case algoliaReducerConstants.SearchClicked:
      return { ...state, SearchClicked: action.payload };
    case algoliaReducerConstants.queryParm:
      return { ...state, queryParm: action.payload };
    case algoliaReducerConstants.searchStaticContent:
      return { ...state, searchStaticContent: action.payload };
    case algoliaReducerConstants.hitsResponse:
      return { ...state, hitsResponse: action.payload };
    case algoliaReducerConstants.filerClicked:
      return { ...state, filerClicked: action.payload };
    case algoliaReducerConstants.pageNoClicked:
      return { ...state, pageNoClicked: action.payload };
    case algoliaReducerConstants.showAutocomplete:
      return { ...state, showAutocomplete: action.payload };
    case algoliaReducerConstants.searchValue:
      return { ...state, searchValue: action.payload };
    case algoliaReducerConstants.isSpinning:
      return { ...state, isSpinning: action.payload };
    case algoliaReducerConstants.totalPageNumber:
      return { ...state, totalPageNumber: action.payload };
    case algoliaReducerConstants.filterItems:
      return { ...state, filterItems: action.payload };
    case algoliaReducerConstants.nbHits:
      return { ...state, nbHits: action.payload };
    case algoliaReducerConstants.nbPages:
      return { ...state, nbPages: action.payload };
    default:
      return state;
  }
};
