import PropTypes from 'prop-types';
import React from 'react';
import { LabelHTML } from '../Label/Label';
import SeriesComparisonItem from './SeriesComparisonItem';
import Disclaimer from '../Disclaimer/Disclaimer';

export default function SeriesComparison(props) {
    const {document: doc, products, selectedSeries, onClickCallback} = props;

    return (
        <div className="ob-series-comparison">
            <div className="ob-series-comparison__sizer">
                <h2 className="ob-series-comparison__title">
                    <LabelHTML label={doc.fields.compareLabel} tokens={{seriesName: selectedSeries.fields.shortTitle}} />
                </h2>

                <ul className="ob-series-comparison__list">
                    {products.map((product, i) => (
                        <li key={i} className="ob-series-comparison__item">
                            <SeriesComparisonItem document={doc} product={product} onClickCallback={onClickCallback} />
                        </li>
                    ))}
                </ul>
                {selectedSeries?.fields?.disclaimer &&
                    <Disclaimer document={selectedSeries?.fields?.disclaimer} />
                }
            </div>
        </div>
    );
}

SeriesComparison.propTypes = {
    document: PropTypes.any,
    products: PropTypes.any,
    selectedSeries: PropTypes.object,
    onClickCallback: PropTypes.func,
};
