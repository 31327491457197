import React, { useEffect, useRef } from 'react';
import Block from '../../adapters/helpers/Block';
import Eyebrow from '../Eyebrow/Eyebrow';
import PropTypes from 'prop-types';
import UnderlineTabsNavigation from '../UnderlineTabsNavigation/UnderlineTabsNavigation';
import {getSelectedIndex, mapLinks} from './helpers';
import { scrollIntoView } from '../../adapters/helpers/Scroll';
import {CollectionTabsBlockConstants} from '../../adapters/helpers/Constants';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import ButtonTabsNavigation from '../ButtonTabsNavigation/ButtonTabsNavigation';

function CollectionTabsBlock(props) {
    const {extraAttributes, isMedium} = props;

    const block = new Block(props);

    const extraClassNames = props.document?.fields?.classNames;
    const surtitle = props.document?.fields?.surtitle;
    const links = props.document?.fields?.links;
    const jumpToLabel = props.document?.fields?.jumpToLabel;
    const anchorId = block.getAnchorId();
    const currentURL = extraAttributes?.slug;
    const tabsRef = useRef();

    const classNames = ['ob-collection-tabs'];
    if (extraClassNames) {
        classNames.push(extraClassNames);
    }

    const scrollToContent = (event, link) => {
        if (link.charAt(0) !== CollectionTabsBlockConstants.hash) {
            return;
        }
        event.preventDefault();
        const element  = document.querySelector(link);
        if (element) {
            scrollIntoView(element, {behavior: CollectionTabsBlockConstants.smooth});
        }
    };

    useEffect(() => {
        if (window?.location?.hash === `#${anchorId}` && tabsRef?.current) {
            let timeout = null;
            window.setTimeout(() => {
                timeout = scrollIntoView(tabsRef.current, {behavior: CollectionTabsBlockConstants.smooth});
            }, 250);

            return () => {
                clearTimeout(timeout);
            }
        }
    }, []);

    const tabs = mapLinks(links);
    const selectedIndex = getSelectedIndex(links, currentURL.replace(/\/$/, ''));

    return (
        <div className={classNames.join(' ')} id={anchorId} ref={tabsRef}>
            <Eyebrow className='ob-collection-tabs__eyebrow'>{surtitle}</Eyebrow>
            <div className='ob-collection-tabs-block__tabs-wrapper'>
                {isMedium ?
                    <ButtonTabsNavigation tabs={tabs} selectedIndex={selectedIndex} jumpToLabel={jumpToLabel} scrollToContent={scrollToContent} />
                    :
                    <UnderlineTabsNavigation tabs={tabs} selectedIndex={selectedIndex} scrollToContent={scrollToContent} />
                }
            </div>
        </div>
    )
}

CollectionTabsBlock.propTypes = {
    extraAttributes: PropTypes.any,
    document: PropTypes.any,
    isMedium: PropTypes.bool
};

export default mediaQueryHOC(CollectionTabsBlock);
// This export is for unit testing (do not remove) :
export const CollectionTabsBlockTest = CollectionTabsBlock;
