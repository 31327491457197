import React, { useContext } from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import Head from 'next/head'
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import { ICloudinaryImage } from '../../types';
import { ImageConstants, ImageContenfullConstants, ComponentFactoryConstants} from '../../adapters/helpers/Constants';

const Visibility = React.createContext(false);

export default function Backdrop(props) {
    const content = (
        <div {...props} className={`${props.className || ''} ob-backdrop`} />
    );

    return (
        <LazyLoad offset={500} placeholder={
            <Visibility.Provider value={false}>
                {content}
            </Visibility.Provider>
        }>
            <Visibility.Provider value={true}>
                {content}
            </Visibility.Provider>
        </LazyLoad>
    );
}

Backdrop.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Backdrop.Image = BackdropImage;
function BackdropImage({
    preserveAspectRatio = true,
    className = '',
    image, xlg = image, lg = xlg, md = lg, sm = md,
    mobileImage = sm, xs = mobileImage, tiny = xs, xtiny = tiny,
}) {
    const visible = useContext(Visibility);
    const ispreload = mobileImage?.fields?.isPreload
    const isBannerImage = mobileImage?.fields?.isBannerImage
    const fallback = image || xlg || lg || md || sm || xs || tiny || xtiny;
    const contentImageId = mobileImage?.fields?.asset?.fields?.file?.url;
    return (
        <div className={`${className} ob-backdrop__image`}>
            {ispreload && isBannerImage && <Head>
                <link rel='preload' href='/static/fonts/HurmeGeometricSans1-Bold.woff' as='font' crossOrigin='anonymous' />
                 <link rel='preload' href='/static/fonts/HurmeGeometricSans1-Light.woff' as='font' crossOrigin='anonymous' />
                 { contentImageId && <link rel="preload" href={ImageFactory.buildContentfullImageUrl(contentImageId, ImageContenfullConstants.dpr_365 )} as="image" /> }
            </Head> }
            {!!preserveAspectRatio && (<>
                {!!xlg      && renderAspectRatio(xlg, 'xlg')}
                {!!lg       && renderAspectRatio(lg, 'lg')}
                {!!md       && renderAspectRatio(md, 'md')}
                {!!sm       && renderAspectRatio(sm, 'sm')}
                {!!xs       && renderAspectRatio(xs, 'xs')}
                {!!tiny     && renderAspectRatio(tiny, 'tiny')}
                {!!xtiny    && renderAspectRatio(xtiny, 'xtiny')}
            </>)}

            <picture className="ob-backdrop__pic">
                {/* {!!xlg   && <source srcSet={ImageFactory.dpr(xlg,  'f_auto,q_auto,w_1440')} media="(min-width: 1440px)" />}
                {!!lg    && <source srcSet={ImageFactory.dpr(lg,   'f_auto,q_auto,w_1200')} media="(min-width: 1200px)" />}
                {!!md    && <source srcSet={ImageFactory.dpr(md,    'f_auto,q_auto,w_992')} media="(min-width: 992px)"  />}
                {!!sm    && <source srcSet={ImageFactory.dpr(sm,    'f_auto,q_auto,w_768')} media="(min-width: 768px)"  />}
                {!!xs    && <source srcSet={ImageFactory.dpr(xs,    'f_auto,q_auto,w_426')} media="(min-width: 426px)"  />}
                {!!tiny  && <source srcSet={ImageFactory.dpr(tiny,  'f_auto,q_auto,w_376')} media="(min-width: 376px)"  />}
                {!!xtiny && <source srcSet={ImageFactory.dpr(xtiny, 'f_auto,q_auto,w_365')}                             />} */}

                {!!xlg   && <source srcSet={ImageFactory.imageContentfullDpr(xlg?.fields?.asset?.fields?.file?.url, ImageContenfullConstants.dpr_1440)} media={ComponentFactoryConstants.mediaUpper} />}
                {!!lg    && <source srcSet={ImageFactory.imageContentfullDpr(lg?.fields?.asset?.fields?.file?.url,  ImageContenfullConstants.dpr_1200)} media={ImageContenfullConstants.media_1200} />}
                {!!md    && <source srcSet={ImageFactory.imageContentfullDpr(md?.fields?.asset?.fields?.file?.url,  ImageContenfullConstants.dpr_992)} media={ImageContenfullConstants.media_992}  />}
                {!!sm    && <source srcSet={ImageFactory.imageContentfullDpr(sm?.fields?.asset?.fields?.file?.url,  ImageContenfullConstants.dpr_768)} media={ImageContenfullConstants.media_768}  />}
                {!!xs    && <source srcSet={ImageFactory.imageContentfullDpr(xs?.fields?.asset?.fields?.file?.url,  ImageContenfullConstants.dpr_426)} media={ComponentFactoryConstants.mediaUpper}  />}
                {!!tiny  && <source srcSet={ImageFactory.imageContentfullDpr(tiny?.fields?.asset?.fields?.file?.url,  ImageContenfullConstants.dpr_376)} media={ImageContenfullConstants.media_376}  />}
                {!!xtiny && <source srcSet={ImageFactory.imageContentfullDpr(xtiny?.fields?.asset?.fields?.file?.url, ImageContenfullConstants.dpr_365)}                             />}                                           

                {(visible && fallback) ? (
                    <img
                        className="ob-backdrop__img"
                        alt={fallback.fields.alternateText || ''}
                        src={ImageFactory.imageContentfullSrc(fallback?.fields?.asset?.fields?.file?.url, ImageContenfullConstants.dpr_1440)}
                        srcSet={ImageFactory.imageContentfullDpr(fallback?.fields?.asset?.fields?.file?.url, ImageContenfullConstants.dpr_1440)}
                    />
                ) : ispreload && isBannerImage && <img
                className="ob-backdrop__img"
                alt={fallback.fields.alternateText || ''}
                src={ImageFactory.imageContentfullSrc(fallback?.fields?.asset?.fields?.file?.url, ImageContenfullConstants.dpr_1440)}
                srcSet={ImageFactory.imageContentfullDpr(fallback?.fields?.asset?.fields?.file?.url, ImageContenfullConstants.dpr_1440)}
            />}
            </picture>
        </div>
    );
}

BackdropImage.propTypes = {
    className: PropTypes.string,
    alt: PropTypes.string,
    image: ICloudinaryImage,
    mobileImage: ICloudinaryImage,
    xtiny: ICloudinaryImage,
    tiny: ICloudinaryImage,
    xs: ICloudinaryImage,
    sm: ICloudinaryImage,
    md: ICloudinaryImage,
    lg: ICloudinaryImage,
    xlg: ICloudinaryImage,
    preserveAspectRatio: PropTypes.bool,
};

Backdrop.Content = BackdropContent;
function BackdropContent({ className, children = null}) {
    return (
        <div className={`${className || ''} ob-backdrop__content`}>
            {children}
        </div>
    );
}

BackdropContent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

function renderAspectRatio(image, breakpointKey) {
    const aspectRatio = image?.fields?.imageRendition?.fields?.aspectRatio;

    if (!aspectRatio) {
        return null;
    }

    return (
        <div
            className={`ob-backdrop__ratio ob-backdrop__ratio--${breakpointKey}`}
            style={{ paddingTop: 100 / aspectRatio + '%' }}
        />
    );
}
