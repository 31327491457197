export const inputs = {
    formName: 'postPurchaseRegistrationForm',
    type: {
        text: 'text',
        email: 'email',
    },
    field: {
        emailAddress: 'emailAddress',
        globalOpt_optStatus: 'globalOpt_optStatus',
        globalOpt_optId: 'globalOpt_optId',
    }
}
