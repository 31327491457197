import React, { useEffect } from 'react';
import {  connectRefinementList } from 'react-instantsearch-dom';
import {algoliaReducerConstants} from '../../../../adapters/helpers/Constants';

function order(a, b) {
  return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
}

const RefinementList = ({ items, currentRefinement, refine,state,dispatch }) => {

  useEffect(() => {
    if(!state.showAutocomplete && state.searchValue != '' && (state.searchValue == state.queryParm)){
    
      dispatch({ type: algoliaReducerConstants.isSpinning, payload: false, ...state });
      dispatch({ type: algoliaReducerConstants.filterItems, payload: items, ...state });
    }
  }, [items])

  const addFilterClass = currentRefinement.length == 0 ? 'filterSelected' : 'filterNotSelected';
  const filterClass = item => {
    return item.isRefined ? 'filterSelected' : 'filterNotSelected'
  }

  const getFilterLabel = (label) => {
    let returnVal = ''
    let labelFromCF = state.searchStaticContent
    let objName = label+'CategoryLabel';
    if(labelFromCF[objName]){
      returnVal = labelFromCF[objName].fields.text
    }
    else{
      returnVal = label == 'faq' ? 'FAQ' : label.charAt(0).toUpperCase() + label.slice(1)
    }
    return returnVal
  }

  return (   
    <div className="ob-search-block__popular-topics">
      <div className="ob-search-block__results-title">{state.searchStaticContent.resultsBySectionTitle}</div>
      <div className="ob-search-block__popular-topics-container">
        <div className="ob-search-block__popular-topics__toggle-wrapper">
          <button
            className={'ob-outline-button event_button_click '+addFilterClass}
            data-action-detail="all"
            type="button"
            onClick={(event) => {
              event.preventDefault();
              
              dispatch({ type: algoliaReducerConstants.filerClicked, payload: 'all',...state });  
              refine('');
            }}
          >
            {state?.searchStaticContent?.allResultsCategoryLabel ? state.searchStaticContent.allResultsCategoryLabel.fields.text : 'All'}
          </button>
        </div>
        {state.filterItems && state.filterItems.sort(order).map((item, i) => (
          // eslint-disable-next-line react/jsx-key
          <div
            key={i}
            className="ob-search-block__popular-topics__toggle-wrapper"
          >
            <button
              className={'ob-outline-button event_button_click '+filterClass(item)}
              data-action-detail={item.label}
              type="button"
              onClick={(event) => {
                event.preventDefault();
                
                  dispatch({ type: algoliaReducerConstants.filerClicked, payload: item.label,...state });      
                  refine(item.label); 
              }}
            >
              {getFilterLabel(item.label)}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

 const  CustomRefinementList = connectRefinementList(RefinementList);
 export default CustomRefinementList;