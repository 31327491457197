// Go to Profile or Login Screen
export const janrainHandleGoToProfile = (userData) => {
    const utmSourceValue = getSearchQueryParameter('utm_source')
    const utmSource = utmSourceValue ? `/?utm_source=${utmSourceValue}` : ''
    return window.location = userData ? '/profile' : '/login' + utmSource
};

export const getSearchQueryParameter = (name) => {
    const searchParamsObject= new URLSearchParams(window.location.search)
    return searchParamsObject.has(name) ?
        searchParamsObject.get(name) : undefined
};

//Go to Home Page
export const janrainHandleGoToHomePage = () => window.location = '/'

// Logout
export const janrainHandleLogout = () => {
    window.janrain.capture.ui.endCaptureSession()
    window.location = '/'
};

// Create Months Array
const monthsArr = [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie'
];
export const monthsName = monthsArr.map((el, i) => {
    return {
        id: i+1,
        value : el
    }
});

// Create Years Array
export const years = (scope = 100) => {
    const date = new Date()
    const currentYear = date.getFullYear()
    return Array.from({ length: scope }, (el, i) => {
        return {
            id: currentYear-i,
            value: currentYear-i
        }
    })
};

export const janrainInit = (callback) => {
    const janrain = window.janrain;
    if (!janrain.capture) {
        Object.defineProperty(janrain, 'capture', {
            get: function () {
                return this._capture
            },
            set: function (val) {
                this._capture = val
                callback()
            }
        })
    } else {
        callback()
    }
};

export const handlePluralPreviousObject = (charSeq = '') => {
    let chunks = [];
    if(charSeq) {
        chunks = charSeq.split(',');
    }
    return (chunks.length > 0 && chunks[0].length > 0) ? charSeq + ',' : '';
};