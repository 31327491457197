import React, { useEffect, Fragment, useState, useRef } from 'react'
import dynamic from 'next/dynamic';
import C from "classnames";
import Slider from 'react-slick';
import Modal from 'react-modal';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import {CarouselBlockConstants, Constants} from '../../adapters/helpers/Constants'
import Block from '../../adapters/helpers/Block';
import { getColorStyle, stripHtml } from "../../adapters/helpers/Utils";
import PropTypes from 'prop-types';
const Image = dynamic(() => import('../Image/Image'));
const Icon = dynamic(() => import('../Icon/Icon'));
import VideoPlayer from '../VideoPlayer/VideoPlayer';
const YoutubeVideo = dynamic(() => import('../YoutubeVideo/YoutubeVideo'));
const ResponsiveImage = dynamic(() => import('../ResponsiveImage/ResponsiveImage'));
import { scrollIntoView } from '../../adapters/helpers/Scroll';

const CarouselBlock = (props) => {
    const {document : doc , isMedium, isSmall, isXSmall} = props;
    const sliderRef = useRef(null)
    const carouselRef = useRef();
    const videoSettings = {
        ...doc?.fields?.carouselSettings,
        nextArrow: <img tabIndex={0} src={doc?.fields?.rightArrowIcon?.fields?.file?.url} data-action-detail='carousel_panel' alt={CarouselBlockConstants.rightArrow} />,
        prevArrow: <img tabIndex={0} src={doc?.fields?.leftArrowIcon?.fields?.file?.url} data-action-detail='carousel_panel' alt={CarouselBlockConstants.leftArrow}/>
    }
    const anchorId = doc?.fields?.anchorId;
    const handleGAClassName = (ele) => {
        if(ele?.[0]) {
            ele[0].classList.add('event_button_click');
        }
    }
    const handleClickTab = (index) =>{
        sliderRef.current.slickGoTo(index);
    }
    const handleClickPageTab = ( index ) => {
        sliderRef.current.slickGoTo( index );
        const sliderElement = document.querySelector('.slick-slider');
        const sliderPosition = sliderElement.getBoundingClientRect().top + window.scrollY;
        const offset = 200;
        const mobileOffset = 70;
        ( isSmall || isXSmall ) ?
            window.scrollTo( { top: sliderPosition - mobileOffset, behavior: 'smooth' } )
            :
            window.scrollTo( { top: sliderPosition - offset, behavior: 'smooth' } )
        setTimeout(() => {
            const tabElement = document.querySelector('.carousel-tab-page.active');
            const parent = tabElement.parentElement;
            parent.scrollLeft = tabElement.offsetLeft - ( parent.offsetWidth / 2 ) + ( tabElement.offsetWidth / 2 );
        }, 1000);
    }
    useEffect(() => {
        handleGAClassName(document.getElementsByClassName('slick-prev'));
        handleGAClassName(document.getElementsByClassName('slick-next'));
        setTimeout( () => {
            const videoButtonId = document?.getElementById( 'button-video' );
            videoButtonId?.addEventListener( 'click', () => {
                return (
                    setIsModalOpen(true)
                )
            })
        }, 3000)
        
        if (window?.location?.hash === `#${anchorId}` && carouselRef?.current) {
            let timeout = null;
            window.setTimeout(() => {
                timeout = scrollIntoView(carouselRef.current, {behavior: 'smooth', block: 'nearest'});
            }, 250);

            return () => {
                clearTimeout(timeout);
            }
        }
    }, []);
    
    const [selectedVariants, setSelectedVariants] = useState(
        Array( doc?.fields?.carouselItems?.length ).fill( 0 )
    );
    const [activeTabIndex, setActiveTabIndex] = useState( 0 );
    const [currentSlideIndex, setCurrentSlideIndex] = useState( 0 );
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const tabItems = doc?.fields?.carouselItems?.map( ( item, index ) => {
        return (
            <div key={index} className={`carousel-tab ${index === activeTabIndex? 'active':''}`} onClick={()=> handleClickTab(index)}>{item?.fields?.tabTitle}</div>
        )
    })
    
    const tabPageItems = doc?.fields?.carouselItems?.map( ( item, index ) => {
        return (
            <div key={index} className={`carousel-tab-page ${index === activeTabIndex ? 'active' : ''}`} onClick={() => handleClickPageTab( index )}>
                <div className={item?.fields?.tabHtmlSection?.fields?.classNames} dangerouslySetInnerHTML={{ __html: item?.fields?.tabHtmlSection?.fields?.text }}></div>
            </div>
        )
    })
    useEffect( () => {
        setActiveTabIndex(currentSlideIndex);
    },[currentSlideIndex]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = event => {
        event.preventDefault();
        setIsModalOpen(true);
    };
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content : {
            border              : 'none',
            top                 : '50%',
            left                : '50%',
            right               : 'auto',
            bottom              : 'auto',
            padding             : '0',
            marginRight         : '-50%',
            transform           : 'translate(-50%, -50%)',
            maxWidth            : '100rem',
            width               : '90%'
        }
    };

    const renderVideo = (video) => {
        if(video) {
            if(video?.videoId) {
                return <YoutubeVideo document={video} />
            } else {
                    return <VideoPlayer video={video} />
                }
            }
    };
  return (
    <Fragment>
        {doc?.fields?.corouselTitle && <h2 className={`carousel-heading ${doc?.fields?.carouselHeadingClassname ? doc?.fields?.carouselHeadingClassname : ''}` } dangerouslySetInnerHTML={{__html: doc?.fields?.corouselTitle}}/>}
        {doc?.fields?.carouselDescription && <div className={`carousel-description ${doc?.fields?.carouselDescriptionClassName}`} dangerouslySetInnerHTML={{__html: doc?.fields?.carouselDescription}}/>}
        {doc?.fields?.carouselItems?.map((item,index)=>{ 
            if(item?.fields?.tabItemShopNowCta && index === activeTabIndex) {
            return(
            <div dangerouslySetInnerHTML={{__html: item?.fields?.tabItemShopNowCta}} className='ob-shop-now-wrapper'></div>
            )}
         })}
        {doc?.fields?.isCarouselTab && !(isXSmall||isSmall) && <div className={`carousel-tabs ${doc?.fields?.carouselTabItemsClassName ? doc?.fields?.carouselTabItemsClassName : '' }` }>{tabItems}</div>}
        {doc?.fields?.isCarouselPageTab && <div className={`carousel-page-tabs ${doc?.fields?.carouselTabItemsClassName ? doc?.fields?.carouselTabItemsClassName : '' }` }>{tabPageItems}</div>}
        <section id={anchorId} ref={carouselRef}>
            <Slider {...videoSettings} ref={sliderRef} className={doc?.fields?.className} afterChange={(index) => setCurrentSlideIndex(index)}>
        {doc?.fields?.carouselItems?.map((item,index)=>{

            switch (item?.fields?.contentType){
                case CarouselBlockConstants.productCard:
                    return(
                        <a key={index} href={item?.fields?.destinationUrl?.fields?.url} className={`card-link ${item?.fields?.destinationUrl?.fields?.linkClassName}`} target={item?.fields?.destinationUrl?.fields?.isExternalLink ? "_blank" : null} data-action-detail={item?.fields?.destinationUrl?.fields?.dataActionDetail}>
                            <div className='carousel-card'>
                                <Image image={item?.fields?.productImage}/>  
                                {item?.fields?.productName && <h2 className='carousel-title'>{item?.fields?.productName} <Icon name={CarouselBlockConstants.chevronRight} /></h2>}
                                {item?.fields?.title && <h2 className='carousel-heading'>{item?.fields?.title}</h2>}
                                {item?.fields?.cta && <h2 className='carousel-cta'>{item?.fields?.cta}</h2>}
                            </div> 
                        </a>
                    );
                case CarouselBlockConstants.cloudinaryVideo:
                    return(
                        <div className='carousel-video'>
                            <VideoPlayer video={item?.fields} />
                        </div>
                    );
                case CarouselBlockConstants.carouselTabItems: 
                    return(
                        <div className={`ob-carousel-tab-content-conatiner ${item?.fields?.carouselTabItemClassName}`} key={index}>
                            {item?.fields?.carouselHtmlSection && <div className={item?.fields?.carouselHtmlSection?.fields?.classNames} dangerouslySetInnerHTML={{ __html: item?.fields?.carouselHtmlSection?.fields?.text }}></div>}
                            {(isMedium||isXSmall||isSmall) && item?.fields?.tabTitle && <div className={'carousel-tabs-mobile'}>{item?.fields?.tabTitle} </div>}
                            {item?.fields?.tabItemDetailVideo ? <div>{new Block(item?.fields?.tabItemDetailVideo).renderBlockFromDocument(item?.fields?.tabItemDetailVideo)} </div>  : item?.fields?.tabImage && <div className='ob-carousel-tab-content-conatiner-image'><ResponsiveImage document={item?.fields?.tabImage}></ResponsiveImage></div>}
                            <div className='ob-carousel-tab-content-conatiner-desc-wrapper'>
                                { item?.fields?.tabDescription && <span dangerouslySetInnerHTML={{__html: item?.fields?.tabDescription}}></span>}
                                { item?.fields?.tabVideoTitle && <button className={`carousel-tab-video-cta carousel-tab-video-cta-${index}`} onClick={e => openModal(e)}>{item?.fields?.tabVideoTitle}</button> }
                                { item?.fields?.tabLinkNextDescription && <span dangerouslySetInnerHTML={{__html: item?.fields?.tabLinkNextDescription}}></span> }
                            </div>
                                { item?.fields?.tabItemLearnMoreCta &&   
                                <div dangerouslySetInnerHTML={{__html: item?.fields?.tabItemLearnMoreCta}} className='ob-learn-More-cta-wrapper'></div>
                                }
                            {isModalOpen && item?.fields?.tabVideo && index === activeTabIndex && <Modal
                                isOpen={isModalOpen}
                                onRequestClose={closeModal}
                                style={customStyles}
                                htmlOpenClassName={'ob-carousel-tab-item-video'}
                            >
                                <div className={'ob-carousel-tab-item-video-container'}>
                                { renderVideo(item?.fields?.tabVideo?.fields) }
                                </div>
                            </Modal>}
                        </div>
                    );
                case CarouselBlockConstants.articleProductCard:
                    const productVariants = item?.fields?.product?.[0]?.fields?.productVariants;
                    const productVariantImage = productVariants[selectedVariants[index]]?.fields?.productGridAsset;
                        return (
                            <div className="carousel-article-card">
                                <div className="ob-bg-image-container">
                                    <Image image={item?.fields?.backgroundImage}>
                                        <div className="article-items-wrapper">
                                            <div className="ob-image-container">
                                                <Image image={productVariantImage} />
                                            </div>
                                            <div className="text-variant-selector-container">
                                                <h3>
                                                    <a
                                                        key={index}
                                                        href={item?.fields?.destinationUrl?.fields?.url}
                                                        className="card-link"
                                                    >
                                                        {item?.fields?.title}
                                                    </a>
                                                </h3>
                                                <p>{item?.fields?.description}</p>
                                                <div className="ob-color-variant-button-container">
                                                    {productVariants.map( ( variant, i ) => {
                                                        const color = variant?.fields?.color;
                                                        if ( !color ) {
                                                            return null;
                                                        } else {
                                                            return !color?.fields?.colorImage ? (
                                                                <button
                                                                    key={i}
                                                                    aria-label={color?.fields?.name}
                                                                    type={Constants.button}
                                                                    className={C(
                                                                        "ob-series__color ob-series__color--large-mobile event_buy_now_choose_product",
                                                                        {
                                                                            ["ob-series__color--active"]:
                                                                                i === selectedVariants[index],
                                                                        }
                                                                    )}
                                                                    style={getColorStyle( color )}
                                                                    data-action-detail={`${color?.fields?.name
                                                                        }_${stripHtml( item?.fields?.title )}`}
                                                                    onClick={() => {
                                                                        const newSelectedVariants = [
                                                                            ...selectedVariants,
                                                                        ];
                                                                        newSelectedVariants[index] = i;
                                                                        setSelectedVariants( newSelectedVariants );
                                                                    }}
                                                                    aria-pressed={i === selectedVariants[index]}
                                                                >
                                                                    {color?.fields?.name}
                                                                </button>
                                                            ) : (
                                                                <img
                                                                    key={i}
                                                                    className={`event_buy_now_choose_product ob-series-product-color-img ${i === selectedVariants[index]
                                                                            ? "ob-series__color--active"
                                                                            : ""
                                                                        }`}
                                                                    data-action-detail={`${color?.fields.name
                                                                        }_${stripHtml( title )}`}
                                                                    alt={color?.fields?.colorImage?.fields?.title}
                                                                    src={
                                                                        color?.fields?.colorImage?.fields?.file?.url
                                                                    }
                                                                    onClick={() => {
                                                                        const newSelectedVariants = [
                                                                            ...selectedVariants,
                                                                        ];
                                                                        newSelectedVariants[index] = i;
                                                                        setSelectedVariants( newSelectedVariants );
                                                                    }}
                                                                ></img>
                                                            );
                                                        }
                                                    } )}
                                                </div>
                                            </div>
                                        </div>
                                    </Image>
                                </div>
                            </div>
                        ); 
                case CarouselBlockConstants.htmlBlock:
                    return(
                        <>
                            <div className={item?.fields?.classNames} dangerouslySetInnerHTML={{ __html: item?.fields?.text }}></div>
                            {isModalOpen && item?.fields?.buttonVideoBlock && <Modal
                                isOpen={isModalOpen}
                                onRequestClose={closeModal}
                                style={customStyles}
                                htmlOpenClassName={'ob-html-video-button-wrapper'}
                            >
                                <div className={'ob-html-video-container'}>
                                    { renderVideo(item?.fields?.buttonVideoBlock?.fields?.video?.fields) }
                                </div>
                            </Modal>}
                        </>);
                case CarouselBlockConstants.contentBlock:
                    return(
                        <div>{new Block(doc?.fields?.carouselItems).renderSpecifiedBlock(doc?.fields?.carouselItems)} </div>
                    );
                case CarouselBlockConstants.miniContentBlock:
                    return(
                        <div>{new Block(item).renderBlockFromDocument(item)} </div>
                    ); 
                default:
                    return(
                        <Image image={item} />
                    );
            }   
        } )}   
        </Slider>
    </section> 
        {doc?.fields?.imageBelowText && <p className='carousel-image-below-text' dangerouslySetInnerHTML={{__html: doc?.fields?.imageBelowText}}/>}        
    </Fragment>
    
  )
}

export default mediaQueryHOC(CarouselBlock);

CarouselBlock.propTypes = {
    document: PropTypes.any,
    isMedium: PropTypes.bool,
    isLarge: PropTypes.bool,
    isXXLarge: PropTypes.bool,
    isSmall: PropTypes.bool,
    isXSmall: PropTypes.bool,
  };