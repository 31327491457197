import PropTypes from 'prop-types';
import {mediaQueryHOC} from '../../adapters/helpers/Hooks';
import React, { useState, useCallback, useRef } from 'react';
import Block from '../../adapters/helpers/Block';
import Heading from '../Heading/Heading';
import Image from '../Image/Image';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Icon from '../Icon/Icon';
import { CSSTransition } from 'react-transition-group';
import { GalleryBlockConstants } from '../../adapters/helpers/Constants';

function Gallery(props) {
    const { extraAttributes, isMedium } = props;

    return (extraAttributes && extraAttributes.entity && extraAttributes.entity.gallery) ?
        renderGalleryBlock(extraAttributes.entity, isMedium, props) :
        '';
}

function getRoundedWidth(width) {
    let widthValue;

    if(width == GalleryBlockConstants.full) {
        widthValue = 100;
    }

    else if(width == GalleryBlockConstants.large) {
        widthValue = 58.333333;
    }

    else if(width == GalleryBlockConstants.small) {
        widthValue = 41.666666;
    }

    return widthValue;
}

function defineGallery(galleryItems, showMoreItems) {
    if (galleryItems && galleryItems.length > 1) {
        return (
            <div className={'ob-gallery__masonry ' + (showMoreItems ? '' : 'gallery-hidden')}>
                {
                    galleryItems.slice(1).map((product, productIndex) => (
                        <div key={'product-' + productIndex} className={'grid-item'} style={{ width: getRoundedWidth(product.fields.width) + '%' }}>
                            {product.fields.mainAsset.fields.contentType === GalleryBlockConstants.cloudinaryImage ? (
                                <Image noLazyLoad={true}
                                       image={product.fields.mainAsset}
                                       ariaHidden={!showMoreItems} />
                            ) : (
                                    <VideoPlayer video={product.fields.mainAsset.fields} />
                                )}
                        </div>
                    ))
                }
            </div>
        )
    }

    else {
        return;
    }
}

function renderGalleryBlock(entity, isMobile, props) {
    const galleryBlock = new Block(props);

    let [showMoreItems, setShowMoreItems] = useState(false);
    const collapsibleRef = useRef(null);
    const [collapsibleHeight, setCollapsibleHeight] = useState(0);

    const customClassNames = galleryBlock.getFieldValue(GalleryBlockConstants.classNames);
    const className = 'gallery ' + (customClassNames ? customClassNames : '');
    const titleValue = galleryBlock.getFieldValue(GalleryBlockConstants.title);
    const isWhiteText = className.includes(GalleryBlockConstants.whiteText);

    const anchorId = galleryBlock.getFieldValue(GalleryBlockConstants.anchorId);
    const seeMoreButtonLabel = galleryBlock.getFieldValue(GalleryBlockConstants.seeMoreButtonLabel)
    const seeLessButtonLabel = galleryBlock.getFieldValue(GalleryBlockConstants.seeLessButtonLabel)
    const [buttonLabel, setButtonLabel] = useState(seeMoreButtonLabel);

    let galleryItemsJson = [];
    if (entity.gallery) {
        galleryItemsJson = entity.gallery;
    }

    let galleryItems = defineGallery(galleryItemsJson, showMoreItems);
    let mainGalleryItem = null;
    if (galleryItemsJson && galleryItemsJson.length > 0) {
        mainGalleryItem = galleryItemsJson[0].fields.mainAsset;
        
        if (isMobile && galleryItemsJson[0].fields.mobileAsset) {
            mainGalleryItem = galleryItemsJson[0].fields.mobileAsset;
        }
    }

    const _handleClickShowItems = useCallback(() => {
        setShowMoreItems(!showMoreItems);
        if (showMoreItems == false) {
            setButtonLabel(seeLessButtonLabel);
        } else {
            setButtonLabel(seeMoreButtonLabel);
        }
    }, [showMoreItems]);

    const _calculateHeight = useCallback(
        () => {
            if (collapsibleRef.current) {
                setCollapsibleHeight(collapsibleRef.current.scrollHeight);
            }
        },
        [],
    );

    return (
        <div className={`ob-gallery ${className}`} id={anchorId} >

            {mainGalleryItem.fields.contentType === 'cloudinaryImage' ? (
                <Image image={mainGalleryItem}>
                    <div className='ob-gallery__wrapper'>
                        <Heading whiteText={isWhiteText} tag='h1' >{titleValue}</Heading>
                    </div>

                    {/* See more button */}
                    <button onClick={_handleClickShowItems}
                            className='ob-link ob-gallery__button primaryGrey see-more-button event_button_click'
                            aria-expanded={showMoreItems}
                            data-action-detail={buttonLabel}>
                        { showMoreItems ? (
                            <Icon name={GalleryBlockConstants.minus6} roundedIcon={GalleryBlockConstants.blue} viewBox={6} />
                        ) : (
                            <Icon name={GalleryBlockConstants.plus} roundedIcon={GalleryBlockConstants.blue} viewBox={10} />
                        )}
                        {buttonLabel}
                    </button>
                </Image>
            ) : (
                    <div className={'ob-gallery__wrapper--video'}>
                        <Heading whiteText={isWhiteText} tag='h1' >{titleValue}</Heading>
                        <VideoPlayer video={mainGalleryItem.fields} />
                    </div>
                )
            }
            
            <CSSTransition in={showMoreItems} timeout={800} onEntering={_calculateHeight} onExit={_calculateHeight}>
                <div className='ob-gallery__container' aria-hidden={!showMoreItems}>
                    <div className='ob-gallery__container-collapsible-wrapper' style={{ height: collapsibleHeight }}>
                        <div ref={collapsibleRef} className='ob-gallery__container-collapsible'>
                            {galleryItems}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default mediaQueryHOC(Gallery);
// This export is for unit testing (do not remove) :
export const GalleryBlockTest = Gallery;

Gallery.propTypes = {
    document: PropTypes.object,
    isMedium: PropTypes.bool
};