import React from 'react';
import Icon from '../Icon/Icon';
import ObLink from '../ObLink/ObLink';
import PropTypes from 'prop-types';

export default function BrandGuarantee(props) {
    const {extraAttributes} = props;

    const brandGuarantee = extraAttributes?.entity?.brandGuarantee;
    const claims = brandGuarantee?.fields?.claims;
    const ctaLabel = brandGuarantee?.fields?.ctaLabel;
    const ctaLink = brandGuarantee?.fields?.ctaLink;
    const anchorId = brandGuarantee?.fields?.anchorId;
    const classNames = brandGuarantee?.fields?.classNames || '';

    return (
        <>
        { claims &&
            <div className={'ob-brand-guarantee ' + classNames} id={anchorId} >
                {claims.map((claim, i) => {
                    const iconId = claim?.fields?.iconId;
                    const statistic = claim?.fields?.statistic;
                    const title = claim?.fields?.title;

                    return (
                        <div className='ob-brand-guarantee__claim' key={i} title={title}>
                            {iconId && <div aria-hidden='true' className='ob-brand-guarantee__icon'><span className={'icon-' + iconId}/></div>}
                            {statistic && <div className='ob-brand-guarantee__statistic'>{statistic}</div>}
                        </div>
                    );
                })}
                {ctaLink && ctaLabel &&
                    <div className='ob-brand-guarantee__cta-link'>
                        <ObLink href={ctaLink}
                                className='white event_internal_link'
                                data-action-detail={ctaLabel}>
                            {ctaLabel}
                            <Icon size={0.7} color='black' name='chevronRight' />
                        </ObLink>
                    </div>}
            </div>
        }
        </>
    );
}

BrandGuarantee.propTypes = {
    extraAttributes: PropTypes.object,
};