import PropTypes from 'prop-types';
import React from 'react';

export default function SearchToggleButton({text, isActive, onClick}) {
    return (
        <button aria-label={text}
            className="ob-outline-button event_button_click"
            data-action-detail={text}
            type="button"
            role="switch"
            aria-checked={isActive ? 'true' : 'false'}
            onClick={onClick}
        >
            {text}
        </button>
    );
}

SearchToggleButton.propTypes = {
    text: PropTypes.any,
    isActive: PropTypes.any,
    onClick: PropTypes.any,
};
