import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OnePageScrollContext from '../OnePageScroll/OnePageScrollContext';
import {SpotlightVideoPlayerConstants} from '../../adapters/helpers/Constants';

const CLOUD_NAME = 'pgone';

class SpotlightVideoPlayer extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.video = props.video;
        this.playVideo = props.playVideo;
        this.currentIndex = props.currentIndex;
        this.nextFrameOnVideoEnd = props.nextFrameOnVideoEnd;
    }

    componentDidMount() {
        require('cloudinary-video-player');

        if (this.videoIsDefined()) {
            this.loadCloudinaryCoreLib().then(cloudinary =>
            this.setState({
                video :  cloudinary.Cloudinary
                .new({ cloud_name: CLOUD_NAME, secure: true })
                .videoPlayer(this.videoRef.current,
                    {
                        loop: this.video.loop,
                        fluid: true,
                        muted: true,
                        controls: false,
                        fontFace: SpotlightVideoPlayerConstants.inherit,
                        autoplayMode: SpotlightVideoPlayerConstants.never
                    })
                .source(this.video.assetId, { sourceTypes: SpotlightVideoPlayerConstants.sourceTypes})
            }))
                .catch(() => {
                
                });

            this.checkVideoPlay();
        }
        this._ismounted = true;
    }
    
    async loadCloudinaryCoreLib() {
        return await import('cloudinary-core');
    }

    componentDidUpdate() {
        if (!this._ismounted) {
            return;
        }

        this.checkVideoPlay();

        if(!this.props.onePageScrollContext.resetVideo) {
            this.props.onePageScrollContext.setVideoResetState();
        }
    }

    resetVideo = () => {
        if(this.state && this.state.video) {
            this.state.video.currentTime(0);
        }
    };

    playDomVideo = () => {
        if(this.state && this.state.video) {
            this.state.video.play();
        }
    };

    pauseVideo = () => {
        if(this.state && this.state.video) {
            this.state.video.pause();
        }
    };

    checkVideoPlay = () => {
        if (this.props.playVideo) {
            if (!this.video.loop && this.props.onePageScrollContext.resetVideo) {
                this.resetVideo();
            }
            setTimeout(() => {
                this.playDomVideo();
                this.props.onePageScrollContext.setPlayButton(true);
            });

        } else {
            this.pauseVideo();
        }
    };

    componentWillUnmount() {
        this._ismounted = false;
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.video !== this.props.video) {
            this.state.video.source(nextProps.video.assetId, { sourceTypes: SpotlightVideoPlayerConstants.sourceTypes });

            this.checkVideoPlay();
        }

        if (this.props.playVideo !== nextProps.playVideo) {
            return true;
        }
        return false;
    }

    videoIsDefined() {
        return (this.video && this.video.assetId);
    }

    render() {
        return (
            <OnePageScrollContext.Consumer>
                {context => (
                    <div className="ob-video-player">
                        <video
                            tabIndex={-1}
                            muted
                            playsInline
                            onEnded={() => context.videoEnded(this.nextFrameOnVideoEnd)}
                            id={`video-player-${this.props.anchorId}`}
                            ref={this.videoRef} />
                    </div>
                )}
            </OnePageScrollContext.Consumer>
        )
    }
}

SpotlightVideoPlayer.propTypes = {
    video: PropTypes.object,
    playVideo: PropTypes.any,
    currentIndex: PropTypes.any,
    onePageScrollContext: PropTypes.any,
    nextFrameOnVideoEnd: PropTypes.any,
    anchorId: PropTypes.any,
};

export default SpotlightVideoPlayer