import 'url-search-params-polyfill'
import React, { useState, useEffect } from 'react';
import Block from '../../adapters/helpers/Block';
import {LoginForm} from '../Janrain/Janrain/janrainForms';
import {redirectTo} from '../Janrain/helpers/redirect.helpers';
import {getSearchQueryParameter} from '../Janrain/Janrain/janrainForms/Janrain.helpers';
import JanrainForm from '../Janrain/Janrain/HOC/JanrainForm';
import { objectWithLabel } from '../../adapters/helpers/Utils'

export default function Login(props) {
    const [isCallbackInURL, setCallbackInURL] = useState(false);
    const [utmSource, setUtmSource] = useState(undefined);

    const propsBlock = new Block(props);
    const successRedirectPath = propsBlock.getFieldValue('successRedirectPath')?.fields?.slug;
    const emailValidationRegex = propsBlock.getFieldValue('emailValidationRegex')?.fields?.pattern;
    const janrainErrorList = props?.document?.fields?.janrainErrors?.fields
    const ariaFormIsSubmitting = props?.document?.fields?.ariaFormIsSubmitting?.fields?.text;
    const activateDoubleOptInEmailVerification = props?.document?.fields?.activateDoubleOptInEmailVerification || false;

    function handleLoginSuccess() {
        if (isCallbackInURL) {
            const searchParamsObject = new URLSearchParams(window.location.search);
            setCallbackInURL(searchParamsObject.get('callback'));
            redirectTo(isCallbackInURL);
        } else {
            // Can put waitConsumerID method here if consumerID is mandatory
            redirectTo(successRedirectPath);
        }
    }

    useEffect(() => {
        const searchParamsObject = new URLSearchParams(window.location.search);
        const utmSource = getSearchQueryParameter('utm_source');
        const isCallbackInURL = searchParamsObject.has('callback');
        setCallbackInURL(isCallbackInURL);
        setUtmSource(utmSource);
    }, []);

    const title = propsBlock.getFieldValue('title');
    const email = propsBlock.getFieldValue('email');
    const surtitle = propsBlock.getFieldValue('surtitle');
    const password = propsBlock.getFieldValue('password');
    const createAccount = propsBlock.getFieldValue('createAccount');
    const keepMeSignedIn = propsBlock.getFieldValue('keepMeSignedIn');
    const loginButtonText = propsBlock.getFieldValue('loginButtonText');
    const forgotPasswordText = propsBlock.getFieldValue('forgotPasswordText');
    const forgotPasswordQuestion = propsBlock.getFieldValue('forgotPasswordQuestion');
    const forgotPasswordPath = propsBlock.getFieldValue('forgotPasswordPath')?.fields?.slug;
    const forgotProfilePath = propsBlock.getFieldValue('createProfilePath')?.fields?.slug;

    const loginFormLabels = {
        loginEmailFieldLabel : email?.fields?.text,
        loginPasswordFieldLabel : password?.fields.text,
        loginPasswordForgotPasswordQuestion : forgotPasswordQuestion?.fields?.text,
        loginPasswordForgotPasswordText : forgotPasswordText?.fields?.text,
        loginButtonText : loginButtonText?.fields?.text,
        loginCreateAccount: createAccount?.fields?.text,
        loginKeepMeSignedIn: keepMeSignedIn?.fields?.text
    };

    return <JanrainForm>
            <LoginForm
                formHeading={title}
                formSubHeading={surtitle}
                janrainLabels={loginFormLabels}
                forgotPasswordPath={forgotPasswordPath}
                forgotProfilePath={forgotProfilePath}
                emailValidationRegex={emailValidationRegex}
                janrainFormErrors={objectWithLabel(janrainErrorList)}
                handleLoginSuccess={handleLoginSuccess}
                ariaFormIsSubmitting={ariaFormIsSubmitting}
                utmSource={utmSource}
                activateDoubleOptInEmailVerification={activateDoubleOptInEmailVerification}
            />
        </JanrainForm>

}
