import React from 'react'
import PropTypes from 'prop-types'
import { RadioWrapper, InputLabel, Input } from './RadioInput.styles'

const RadioInput = ({
    name,
    label,
    value,
    checked,
    onChange
}) => (
    <RadioWrapper>
        <Input
            type='radio'
            checked={checked}
            value={value}
            name={name}
            onChange={onChange}
        />
        <InputLabel>{label}</InputLabel>
    </RadioWrapper>
)

RadioInput.defaultProps = {
    checked: false,
}

RadioInput.propTypes = {
    label: PropTypes.string.isRequired,
    name:  PropTypes.string.isRequired,
    value:  PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default RadioInput
