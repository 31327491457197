import React, { useState } from 'react'
import { Waypoint } from 'react-waypoint';
import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import Eyebrow from '../Eyebrow/Eyebrow';
import Heading from '../Heading/Heading';
import BodyText from '../BodyText/BodyText';
import { useMatchMedia } from '../../adapters/helpers/Hooks'
import { CharacterBlockConstants } from '../../adapters/helpers/Constants'

export default function CharacterBlock(props) {

    const block = new Block(props);
    const title = block.getFieldValue(CharacterBlockConstants.title);
    const titleLevel = block.getFieldValue(CharacterBlockConstants.titleLevel);
    const eyebrow = block.getFieldValue(CharacterBlockConstants.eyebrow);
    const description = block.getFieldValue(CharacterBlockConstants.description);
    const backgroundImage = block.getFieldValue(CharacterBlockConstants.backgroundImage);
    const mobileBackgroundImage = block.getFieldValue(CharacterBlockConstants.mobileBackgroundImage);
    const characterLogo = block.getFieldValue(CharacterBlockConstants.characterLogo);
    const mobileCharacterLogo = block.getFieldValue(CharacterBlockConstants.mobileCharacterLogo);
    const isCustomMobile = useMatchMedia(CharacterBlockConstants.maxWidth, null);
    const anchorId = block.getFieldValue(CharacterBlockConstants.anchorId);
    const customClassname = block.getFieldValue(CharacterBlockConstants.classNames) || '';
    const classname = customClassname ? `ob-CharacterBlock ${customClassname}` : 'ob-CharacterBlock';
    const logoIsUnderContent = customClassname.includes('logo-bottom');

    let [show, setShow] = useState('');

    const _handleWaypointEnter = () => {
        setShow('show');
    };

    let image = isCustomMobile ? mobileBackgroundImage : backgroundImage;
    return (
        <div id={`${anchorId}`} className={`${classname} ${show}`}>
            <Waypoint onEnter={_handleWaypointEnter} />
            {image &&
                <Image preloadImage={mobileBackgroundImage} image={isCustomMobile ? mobileBackgroundImage : backgroundImage}>
                    <div className="ob-CharacterBlock__wrapper">
                        <div className="ob-CharacterBlock__content">
                            {!logoIsUnderContent && <Image image={isCustomMobile ? mobileCharacterLogo : characterLogo} className={`ob-CharacterBlock__content-logo ${mobileCharacterLogo ? 'show-mobile' : ''} ${!eyebrow ? 'no-eyebrow' : ''}`}/>}
                            <Eyebrow className='ob-CharacterBlock__content-eyebrow'>{eyebrow}</Eyebrow>
                            <Heading className='ob-display-1-xl ob-CharacterBlock__content-heading' tag={titleLevel ? `h${titleLevel}` : 'h2'}>
                                {title}
                            </Heading>
                            <BodyText>{description}</BodyText>
                            {logoIsUnderContent && <Image image={isCustomMobile ? mobileCharacterLogo : characterLogo} className={`ob-CharacterBlock__content-logo ${mobileCharacterLogo ? 'show-mobile' : ''} ${!eyebrow ? 'no-eyebrow' : ''}`}/>}
                        </div>
                    </div>
                </Image>
            }
            {!image &&
                <div className="ob-CharacterBlock__wrapper no-background">
                    <div className="ob-CharacterBlock__content no-background">
                        <Image image={isCustomMobile ? mobileCharacterLogo : characterLogo} className={`ob-CharacterBlock__content-logo ${mobileCharacterLogo ? 'show-mobile' : ''} ${!eyebrow ? 'no-eyebrow' : ''}`}/>
                        <Eyebrow className='ob-CharacterBlock__content-eyebrow'>{eyebrow}</Eyebrow>
                        <Heading className='ob-display-1-xl ob-CharacterBlock__content-heading' tag={titleLevel ? `h${titleLevel}` : 'h2'}>
                            {title}
                        </Heading>
                        <BodyText>{description}</BodyText>
                    </div>
                </div>
            }
        </div>
    )
}
