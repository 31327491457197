import React from 'react'
import PropTypes from 'prop-types'
import { FakeWrapper, Screen } from './FakeJanrainScreen.styles'

const FakeJanrainScreen = ({ id }) => (
    <FakeWrapper>
        <Screen id={id}/>
    </FakeWrapper>
)

FakeJanrainScreen.propTypes = {
    id: PropTypes.string
}

export default FakeJanrainScreen
