import React, { createContext,useContext,useReducer } from 'react'
import BaseContext from '../BaseContext';
import  SearchBlock  from './SearchBlock'
import { searchReducer,initialState } from '../../adapters/reducers/reducers';
 
const SearchComponent = (props) => {
  const document = props?.document?.fields;
 
const [state, dispatch] = useReducer(searchReducer, initialState) 
  return (
    <div>
      <BaseContext.Provider value={[state, dispatch]}>
        <BaseContext.Consumer>
        { context => context &&
        <>
        <SearchBlock document={document} context={context}/>
        </> }
      </BaseContext.Consumer>
      </BaseContext.Provider>
  </div>
  )
}


export default React.memo(SearchComponent)
