import React, { useState } from 'react';
import { redirectTo } from '../Janrain/helpers/redirect.helpers';
import JanrainForm from '../Janrain/Janrain/HOC/JanrainForm';
import { RegisterForm } from '../Janrain/Janrain/janrainForms';
import { objectWithLabel } from '../../adapters/helpers/Utils'
import {getSearchQueryParameter} from '../Janrain/Janrain/janrainForms/Janrain.helpers';

export default function CreateAccount(props) {
    const [isCallbackInURL, setCallbackInURL] = useState(false);
    const [utmSource, setUtmSource] = useState(undefined);

    const title = props?.document?.fields?.title;
    const subtitle = props?.document?.fields?.surtitle;
    const disclaimer = props?.document?.fields?.disclaimer;
    const emailDisclaimer = props?.document?.fields?.createAccountDisclaimerEmail;
    const disclaimerBottom = props?.document?.fields?.disclaimerBottom;
    const alreadyHaveAnAccount = props?.document?.fields?.alreadyHaveAnAccount;
    const passwordRequirements = props?.document?.fields?.passwordRequirements;
    const whyWeAskForDateOfBirth = props?.document?.fields?.whyWeAskForDateOfBirth;
    const whyWeAskForDateOfBirthLegal = props?.document?.fields?.whyWeAskForDateOfBirthLegal;
    const formStep = props?.document?.fields?.formStep;
    const countries = props?.document?.fields?.countries;
    const countryOptionList = props?.document?.fields?.countryOptionList;
    const optId = props?.document?.fields?.optId;
    const optText = props?.document?.fields?.optText;
    const formLanguage = props?.document?.fields?.formLanguage;
    const emailValidationRegex = props?.document?.fields?.emailValidationRegex?.fields?.pattern;
    const dateValidationRegex = props?.document?.fields?.dateValidationRegex?.fields?.pattern;
    const postalCodeValidationRegex = props?.document?.fields?.postalCodeValidationRegex?.fields?.pattern;
    const postalCodeSecondPattern = props?.document?.fields?.postalCodeValidationRegex?.fields?.secondPattern;
    const successRedirectPath = props?.document?.fields?.successRedirectPathGwe?.fields?.slug;
    let arrCountries = [];
    const janrainErrorList = props?.document?.fields?.janrainErrors?.fields
    const ariaFormIsSubmitting = props?.document?.fields?.ariaFormIsSubmitting?.fields?.text;
    const minimumAge = props?.document?.fields?.minimumAge || 18;
    const underAgeErrorLabel = props?.document?.fields?.underAgeErrorLabel;
    const activateDoubleOptInEmailVerification = props?.document?.fields?.activateDoubleOptInEmailVerification || false;
    const topOralCareGoals = props?.document?.fields?.profileBenefitPreferencesTab?.fields?.topOralCareGoals || [];
    const oralCareGoals = props?.document?.fields?.oralCareGoals?.fields?.text;
    const selectGoals = props?.document?.fields?.selectGoals?.fields?.text;
    const reminderToReplaceLabel = props?.document?.fields?.reminderToReplace?.fields?.text;
    function handleCreateAccountSuccess() {
        if (isCallbackInURL) {
            const searchParamsObject = new URLSearchParams(window.location.search);
            setUtmSource(getSearchQueryParameter('utm_source'));
            setCallbackInURL(searchParamsObject.get('callback'));
            redirectTo(isCallbackInURL);
        } else {
            // Can put waitConsumerID method here if consumerID is mandatory
            redirectTo(successRedirectPath);
        }
    }

    if (countries) {
        for (let i = 0; i < countries.length; i++) {
            const country = countries[i].fields.text;
            arrCountries.push({value: country, id: country});
        }
    }

    const createAccountLabels = {
        registerAllFieldsRequiredLabel: props?.document?.fields?.allFieldsRequiredLabel?.fields?.text,
        registerFirstNameFieldLabel: props?.document?.fields?.firstName?.fields?.text,
        registerLastNameFieldLabel: props?.document?.fields?.lastName?.fields?.text,
        registerEmailAddressFieldLabel: props?.document?.fields?.email?.fields?.text,
        registerReEnterEmailAddressFieldLabel: props?.document?.fields?.reEnterEmail?.fields?.text,
        registerPasswordFieldLabel: props?.document?.fields?.password?.fields?.text,
        registerReEnterPasswordFieldLabel: props?.document?.fields?.reEnterPassword?.fields?.text,
        registerDateOfBirthFieldLabel: props?.document?.fields?.dateOfBirthLabel,
        registerZipCodeFieldLabel: props?.document?.fields?.postalCode?.fields?.text,
        registerCountryFieldLabel: props?.document?.fields?.country?.fields?.text,
        registerPromoCodeFieldLabel: props?.document?.fields?.promoCode?.fields?.text,
        registerPromoEmailsFieldLabel: props?.document?.fields?.registerToPromoEmails?.fields?.text,
        registerDateOfBirthPlaceholder: props?.document?.fields?.dateOfBirthPlaceholder?.fields?.text,
        registerButtonText: props?.document?.fields?.submitButton?.fields?.text,
        registerMonthSelectLabel: props?.document?.fields?.monthSelectLabel?.fields?.text,
        registerYearSelectLabel: props?.document?.fields?.yearSelectLabel?.fields?.text,
        registerMonthSelectAriaLabel: props?.document?.fields?.registerMonthSelectAriaLabel?.fields?.text,
        registerYearSelectAriaLabel: props?.document?.fields?.registerYearSelectAriaLabel?.fields?.text,
        underAgeErrorLabel: props?.document?.fields?.underAgeErrorLabel,
        modalLabelFirst: props?.document?.fields?.modalLabelFirst,
        modalLabelSecond: props?.document?.fields?.modalLabelSecond,
        closeModalLabel: props?.document?.fields?.closeModalLabel,
        oralCareGoals: oralCareGoals,
        selectGoals: selectGoals,
        reminderToReplaceLabel: reminderToReplaceLabel,
    };

    return <JanrainForm>
            <RegisterForm
                formHeading={title}
                formSubHeading={subtitle}
                disclaimer={disclaimer}
                emailDisclaimer={emailDisclaimer}
                disclaimerBottom={disclaimerBottom}
                janrainLabels={createAccountLabels}
                janrainFormErrors={objectWithLabel(janrainErrorList)}
                isCallbackInURL={isCallbackInURL}
                utmSource={utmSource}
                alreadyHaveAnAccount={alreadyHaveAnAccount}
                passwordRequirements={passwordRequirements}
                whyWeAskForDateOfBirth={whyWeAskForDateOfBirth}
                whyWeAskForDateOfBirthLegal={whyWeAskForDateOfBirthLegal}
                formStep={formStep}
                countries={arrCountries}
                countryOptionList={countryOptionList}
                optId={optId}
                optText={optText}
                formLanguage={formLanguage}
                emailValidationRegex={emailValidationRegex}
                dateValidationRegex={dateValidationRegex}
                postalCodeValidationRegex={postalCodeValidationRegex}
                postalCodeSecondPattern={postalCodeSecondPattern}
                handleRegisterSuccess={handleCreateAccountSuccess}
                ariaFormIsSubmitting={ariaFormIsSubmitting}
                minimumAge={minimumAge}
                underAgeErrorLabel={underAgeErrorLabel}
                activateDoubleOptInEmailVerification={activateDoubleOptInEmailVerification}
                oralCareGoals={topOralCareGoals}
            />
    </JanrainForm>
}
