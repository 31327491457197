import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image/Image';
import Heading from '../Heading/Heading';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import RichText from '../RichText/RichText';

function ArticleTableBlock({document, isMedium}) {

    const title = document?.fields?.title;
    const labels = document?.fields?.columnLabels;
    const classNames = document?.fields?.classNames || '';
    const anchorId = document?.fields?.anchorId;
    const rows = document?.fields?.rows;

    return (
        <div className={`ob-article-table-block ${classNames}`} id={anchorId}>
            <Heading className={'ob-article-table-block__title'}>{title}</Heading>

            {isMedium ? (
                /*** START MOBILE LAYOUT ***/
                <div className={'ob-article-table-block__table'}>
                    <ul className={'ob-article-table-block__table-body'}>
                        {rows.map((row, index) => (
                            <li key={index} className={'ob-article-table-block__table-body-row'}>
                                <div className={`ob-article-table-block__table-body-col ob-article-table-block__table-body-cell ${row?.fields?.logo ? 'ob-article-table-block__table-body-cell--has-icon' : ''}`}>
                                    <Image image={row?.fields?.logo} ariaHidden={true} />
                                    <p className={'ob-article-table-block__table-head-title'}>
                                        {labels?.[0]?.fields?.text}
                                    </p>
                                    <RichText className={`ob-article-table-block__table-body-cell ${row?.fields?.logo ? 'ob-article-table-block__table-body-cell--has-icon__text' : ''}`} document={row?.fields?.texts?.[0]} />
                                </div>
                                <div className={'ob-article-table-block__table-body-col'}>
                                    <p className={'ob-article-table-block__table-head-title'}>
                                        {labels?.[1]?.fields?.text}
                                    </p>
                                    <RichText className={'ob-article-table-block__table-body-cell'} document={row?.fields?.texts?.[1]} />
                                    <p className={'ob-article-table-block__table-head-title'}>
                                        {labels?.[2]?.fields?.text}
                                    </p>
                                    <RichText className={'ob-article-table-block__table-body-cell'} document={row?.fields?.texts?.[2]} />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                /*** END MOBILE LAYOUT ***/
            ) : (
                /*** START DESKTOP LAYOUT ***/
                <table className={'ob-article-table-block__table'}>
                    <thead className={'ob-article-table-block__table-head'}>
                    <tr className={'ob-article-table-block__table-head-row'}>
                        {labels.map((label, index) => (
                            <th
                                key={index}
                                className={'ob-article-table-block__table-head-title'}
                                scope='col'
                            >
                                {label?.fields?.text}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody className={'ob-article-table-block__table-body'}>
                    {rows.map((row, index) => (
                        <tr key={index} className={'ob-article-table-block__table-body-row'}>
                            {row.fields.texts.map((text, index) => (
                                <td key={index} className={`ob-article-table-block__table-body-cell ${index === 0 && row?.fields?.logo ? 'ob-article-table-block__table-body-cell--has-icon' : ''}`}>
                                    {index === 0 && <Image image={row?.fields?.logo} ariaHidden={true} />}
                                    <RichText className={index === 0 && row?.fields?.logo ? 'ob-article-table-block__table-body-cell--has-icon__text' : ''} document={text} />
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
                /*** END DESKTOP LAYOUT ***/
            )}
        </div>
    );
}

export default mediaQueryHOC(ArticleTableBlock);
// This export is for unit testing (do not remove) :
export const ArticleTableBlockTest = ArticleTableBlock;

ArticleTableBlock.propTypes = {
    document: PropTypes.object,
    isMedium: PropTypes.bool
};
