import {DeviceType} from './DeviceTypes';

export function getConfigurationValue(availableConfigurations, devicesConfigurations, fieldName, isMobile, isTiny, isTablet, defaultValue = false,isHomepage) {
    let value = defaultValue;
    if (availableConfigurations.indexOf(DeviceType.All) >= 0) {
        /*
          if configuration for device type ALL exist, only care about that one
         */
        let configuration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.All);
        value = configuration.fields[fieldName] || defaultValue;
    } else {
        /*
         in the other case fetch mobile and desktop value and return the one matching the is mobile variable
         given requested value is for mobile and mobile value is empty then use the desktop value
        */
        let tinyConfiguration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.TINY);
        let mobileConfiguration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.MOBILE);
        let tabletConfiguration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.TABLET);
        let desktopConfiguration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.DESKTOP);

        let tinyValue = tinyConfiguration ? tinyConfiguration.fields[fieldName] || defaultValue : defaultValue;
        let mobileValue = mobileConfiguration ? mobileConfiguration.fields[fieldName] || defaultValue : defaultValue;
        let tabletValue = tabletConfiguration ? tabletConfiguration.fields[fieldName] || defaultValue : defaultValue;
        let desktopValue = desktopConfiguration ? desktopConfiguration.fields[fieldName] || defaultValue : defaultValue;

        if (isTiny) {
            value = tinyValue || mobileValue || desktopValue;
        } else if (isMobile) {
            value = mobileValue || tabletValue || desktopValue;
        } else if (isTablet) {
            value = tabletValue || desktopValue;
        } else {
            value = desktopValue
        }
        if(!isHomepage && isTiny == null && isMobile == null && isTablet == null){
            value = mobileValue
        }
    }
    return value;
}

export function getConfigurationBooleanValue(availableConfigurations, devicesConfigurations, fieldName, isMobile, isTiny, isTablet, defaultValue = false) {
    let value = defaultValue;
    
    if (availableConfigurations.indexOf(DeviceType.All) >= 0) {
        /*
          if configuration for device type ALL exist, only care about that one
         */
        let configuration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.All);
        value = configuration.fields[fieldName] ? configuration.fields[fieldName] : defaultValue;
    } else {
        /*
         in the other case fetch mobile and desktop value and return the one matching the is mobile variable
         given requested value is for mobile and mobile value is empty then use the desktop value
        */

        let tinyConfiguration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.TINY);
        let mobileConfiguration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.MOBILE);
        let tabletConfiguration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.TABLET);
        let desktopConfiguration = devicesConfigurations.find(conf => conf.fields.deviceType === DeviceType.DESKTOP);

        let tinyValue = tinyConfiguration && tinyConfiguration.fields[fieldName]
            ? tinyConfiguration.fields[fieldName] : defaultValue;
        let mobileValue = mobileConfiguration && mobileConfiguration.fields[fieldName]
            ? mobileConfiguration.fields[fieldName] : defaultValue;
        let tabletValue = tabletConfiguration && tabletConfiguration.fields[fieldName]
            ? tabletConfiguration.fields[fieldName] : defaultValue;
        let desktopValue = desktopConfiguration && desktopConfiguration.fields[fieldName]
            ? desktopConfiguration.fields[fieldName] : defaultValue;

        if (isTiny) {
            value = tinyValue || mobileValue || desktopValue;
        } else if (isMobile) {
            value = mobileValue || tabletValue || desktopValue;
        } else if (isTablet) {
            value = tabletValue || desktopValue;
        } else {
            value = desktopValue
        }
    }
    return value;
}