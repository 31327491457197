import React, { useState, useEffect, useRef, useMemo } from 'react';
import ProductsOverview from '../ProductsOverview/ProductsOverview';
import { getProducts, removeProduct } from '../../persistence/recentlyViewed';
import 'isomorphic-fetch';

import Block from '../../adapters/helpers/Block';
import Heading from '../Heading/Heading';
import { Waypoint } from 'react-waypoint';
import { RecentlyViewedProductsBlockConstants } from '../../adapters/helpers/Constants'

export default function RecentlyViewedProductsBlock(props) {

    const standardizePath = require('../../adapters/helpers/Path').standardizePath;

    const block = new Block(props);
    const {document, extraAttributes} = props;

    const productsToLoad = useRef(
            getProducts()
                .map(({id}) => id)
                .filter(id => id !== extraAttributes?.slug)
        );

    const [products, setProducts] = useState(
        productsToLoad.current.map(() => undefined)
        );

    const [isShown, setIsShown] = useState(false);

    const anchorId = block.getAnchorId();

    const title = document?.fields?.title;
    const seeLessLabel = document?.fields?.seeLessLabel?.fields?.text;
    const seeMoreLabel = document?.fields?.seeMoreLabel?.fields?.text;

    const _handleWaypointEnter = () => {
        // Trigger by waypoint enter
        setIsShown(true);
    }

    const propsClassnames = document?.fields?.classNames;

    const classNames = useMemo(() => {
        const _classNames = [
            'ob-recently-viewed-block',
        ];

        if(propsClassnames) {
            _classNames.push(propsClassnames);
        }

        if(isShown) {
            _classNames.push(RecentlyViewedProductsBlockConstants.show);
        }

        return _classNames;

    }, [propsClassnames, isShown])

    useEffect(() => {
        if(isShown) {
            for(let i = 0; i < productsToLoad.current.length; i++) {
                const productId = productsToLoad.current[i];
                (async () => {
                    try {
                        const loadedProduct = await (
                            await (fetch(RecentlyViewedProductsBlockConstants.fetch + btoa(standardizePath(productId)) + RecentlyViewedProductsBlockConstants.json).then((response) => {
                                const statusCategory = response.status.toString()[0];
                                if(statusCategory === RecentlyViewedProductsBlockConstants.four) {
                                    removeProduct(productId);
                                }
                                return response;
                            }))
                        ).json();

                        setProducts(prevState =>  {
                            const products = prevState.slice();
                            products[i] = loadedProduct;
                            return products;
                        });
                    } catch(e) {
                        //Log error or do something
                    }
                })();
            }
        }
    }, [isShown]);

    return (
        <>
            {products.length > 0 && <div className={classNames.join(' ')} id={anchorId}>
                <div className="ob-recently-viewed-block-wrapper">
                    {!!title && <Heading className="ob-recently-viewed-block__heading">{title}</Heading>}
                    <div className="ob-recently-viewed-block__product-grid-wrapper">
                        <Waypoint onEnter={_handleWaypointEnter} />
                        <ProductsOverview
                            seeMoreProductsText={seeMoreLabel}
                            seeLessProductsText={seeLessLabel}
                            products={products}
                            isRecentlyViewed={true}
                            isCarrousel
                            onClickCallback={props.onClickCallback}/>
                    </div>
                </div>
            </div>}
        </>
    );
}
