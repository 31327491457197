export const inputs = {
    formName: 'registrationForm',
    type: {
        text: 'text',
        phone: 'tel',
        email: 'email',
        password: 'password',
        checkbox: 'checkbox',
        radio: 'radio',
        hidden: 'hidden'
    },
    field: {
        firstName: 'firstName',
        lastName: 'lastName',
        emailAddress: 'emailAddress',
        emailAddressConfirm: 'emailAddressConfirm',
        newPassword: 'newPassword',
        newPasswordConfirm: 'newPasswordConfirm',
        dateOfBirth: 'dateOfBirth',
        addressPostalCode: 'addressPostalCode',
        locale: 'locale',
        language: 'language',
        addressCountry: 'addressCountry',
        dateOfPurchase: 'dateOfPurchase',
        genderOptions: {
            male: 'Male',
            female: 'Female'
        },
        birthdate: {
            dateselect_month: 'birthdate[dateselect_month]',
            dateselect_year: 'birthdate[dateselect_year]',
            dateselect_day: 'birthdate[dateselect_day]'
        },
        globalOpt_optStatus: 'globalOpt_optStatus',
        globalOpt_optId: 'globalOpt_optId',
        goals: 'goals',
        goalsReminder: 'goalsReminder'
    }
}
