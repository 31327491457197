import FormStore from '../generic-form.store'

class ProfileStore extends FormStore {
    rehydrateForProfileForm(userData){
        const birthDate = userData.birthday !== null ? new Date(userData.birthday+'T00:00:00') : undefined;
        const birthMonth = birthDate ? birthDate.getMonth() + 1 : undefined;
        const birthYear = birthDate ? birthDate.getFullYear(): undefined;

        this.form = {
            firstName: userData.givenName || '',
            lastName: userData.familyName || '',
            emailAddress: userData.email || '',
            ...(userData.phone && {phone: userData.phone}),
            ...(userData.primaryAddress.zip && {addressPostalCode: userData.primaryAddress.zip}),
            ...(userData.primaryAddress.country && {addressCountry: userData.primaryAddress.country}),
            ...(userData.gender && {gender: userData.gender}),
            ...((birthMonth || birthYear) && {'birthdate[dateselect_day]': '1'}),
            ...((birthMonth || birthYear) && {'birthdate[dateselect_month]': birthMonth}),
            ...((birthMonth || birthYear) && {'birthdate[dateselect_year]': birthYear}),
        }
    }
}

export default ProfileStore
