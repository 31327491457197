import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { isObjectEmpty, validatePattern } from '../../adapters/helpers/Utils';
import Block from '../../adapters/helpers/Block';

const SwitchTab = (props) => {
   
    const document = props?.document;
    const contentDetails = document?.fields?.contentDetails;
    const [selectedSwitchTypeIndex, setSelectedSwitchTypeIndex] = useState(0);
    const switchType = Array.isArray(contentDetails) && contentDetails.length > selectedSwitchTypeIndex && contentDetails[selectedSwitchTypeIndex];
    const contents = switchType?.fields?.contents;  
    const anchorId = document?.fields?.anchorId;
    const className = document?.fields?.classNames;  
   
    return (
        <section className={`ob-switch-tab-block ${className ? className: ''}`} id={anchorId}>
           <h3 className='ob-switch-tab-title'>{document?.fields?.title}</h3>
           {document?.fields?.doesHaveHorizontalLineBelowMainTitle && <hr></hr>}
            {
                Array.isArray(contentDetails) && <ul role='tablist' className={'ob-switch-tab__content-details'}>
                    {contentDetails && contentDetails.map((switchtab, index) => {
                        const swtichTypeCornerClass = index === 0 ? 'ob-switch-tab__content-details-left' : (index === contentDetails.length - 1 ? 'ob-switch-tab__content-details-right' : '');
                        return <li role='tab'>
                            <div
                            className={`ob-switch-tab__content-details-type ${index === selectedSwitchTypeIndex ? 'ob-switch-tab__active-switch' : ''} ${swtichTypeCornerClass}`}
                            onClick={() => setSelectedSwitchTypeIndex(index)} key={index} tabIndex={index} >{switchtab?.fields?.label}</div></li>
                    })}
                </ul>
            }
            {!isObjectEmpty(contents) &&
                new Block(contents).renderSpecifiedBlock(contents)
            }
        </section>
    )
};

SwitchTab.propTypes = {
    document: PropTypes.object
};

export default mediaQueryHOC(SwitchTab);
