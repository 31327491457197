import React, { useState } from 'react'
import Image from '../Image/Image';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import Icon from '../Icon/Icon';

function ArticleFeeback(props) {
    const [isFeedbackResponsed, setIsFeedbackResponsed] = useState(true);
    const handleButtonClick=()=>{ 
        setIsFeedbackResponsed(false);
    }
    return (
        <div className="article-feedback-body">
            <Image image={ !props.isLarge ? props?.articleFeedback?.fields?.desktopImage : props?.articleFeedback?.fields?.mobileImage}/>
            {isFeedbackResponsed ? <div className='article-feedback-title' dangerouslySetInnerHTML={{__html: props?.articleFeedback?.fields?.title}} /> 
            : <div className='article-thankyou-message'>{props?.articleFeedback?.fields?.feedbackMessage}</div>}
             {isFeedbackResponsed && <div className='article-feedback-response-body'>
                <button className={`article-feedback-response ${props?.articleFeedback?.fields?.gaEventClass} `} data-action-detail={props?.articleFeedback?.fields?.dataActionLabelYes}  onClick={handleButtonClick}>
                    <div className='article-feedback-block'>
                        <span>{props?.articleFeedback?.fields?.feedbackYesLabel}</span>
                        <Icon name={'thumbsUp'} />
                    </div>   
                </button>
                <button className={`article-feedback-response ${props?.articleFeedback?.fields?.gaEventClass}`} data-action-detail={props?.articleFeedback?.fields?.dataActionLabelNo} onClick={handleButtonClick}>
                   <div className='article-feedback-block'>
                        <span>{props?.articleFeedback?.fields?.feedbackNoLabel}</span>
                        <Icon name={'thumbsDown'} />
                   </div>
                </button>
            </div>}
        </div>
    )
}

export default mediaQueryHOC(ArticleFeeback)