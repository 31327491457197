import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import {
    FormInput,
    SubmitButton,
    CheckboxInput,
} from '../Janrain/components';
import { inputs } from './inputs';
import Modal from 'react-modal';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import DotNav from '../DotNav/DotNav';
import { customJsonstringify, isObjectEmpty, validatePattern } from '../../adapters/helpers/Utils';
import throttle from 'lodash/throttle';
import { PostPurchaseConstants, Constants } from '../../adapters/helpers/Constants';
import {
    formatPostPurchaseRegistrationBody,
    campaignUrlPostPurchase,
    campaignHeader,
    registrationUrl,
    registrationHeader,
    voucherCount,
    voucherCountHeader
} from '../../adapters/model/service/gcs/gcsService';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';

const PostPurchaseRegisterForm = (props) => {
    const title = props?.document?.fields?.title;
    const commonIcon = props?.document?.fields?.commonIcon;
    const shortDescription = props?.document?.fields?.shortDescription;
    const description = props?.document?.fields?.description;
    const emailAddressLabel = props?.document?.fields?.emailAddressLabel;
    const emailPlaceHolder = props?.document?.fields?.emailPlaceHolder;
    const emailError = props?.document?.fields?.emailError;
    const emailFormatError = props?.document?.fields?.emailFormatError;
    const promocodeNotAvailableError = props?.document?.fields?.promocodeNotAvailableError;
    const registerFailedError = props?.document?.fields?.registerFailedError;
    const termsAndConditionsTop = props?.document?.fields?.termsAndConditionsTop;
    const termsAndConditionsBottom = props?.document?.fields?.termsAndConditionsBottom;
    const submitCtaText = props?.document?.fields?.submitCtaText;
    const modalLabelFirst = props?.document?.fields?.modalLabelFirst;
    const modalLabelSecond = props?.document?.fields?.modalLabelSecond;
    const closeModalLabel = props?.document?.fields?.closeModalLabel;
    const promoCodeLabel = props?.document?.fields?.promoCodeLabel;
    const replacementBrushHeadLabel = props?.document?.fields?.replacementBrushHeadLabel;
    const oralbProductList = props?.document?.fields?.brushHeads;
    const ariaLabelNext = props?.document?.fields?.ariaLabelNext;
    const ariaLabelPrevious = props?.document?.fields?.ariaLabelPrevious;
    const defaultSelectedBrushIndex = props?.document?.fields?.defaultSelectedBrushIndex;
    const promocodeNote = props?.document?.fields?.promocodeNote;
    const checkmark = props?.document?.fields?.checkmark;
    const emailExceedLimitLabel = props?.document?.fields?.emailExceedLimitLabel;
    const emailValidatePattern = props?.document?.fields?.emailValidatePattern;
    const anchorId = props?.document?.fields?.anchorId;
    const navLeftIcon = props?.document?.fields?.navLeftIcon;
    const navRightIcon = props?.document?.fields?.navRightIcon;
    const isFirstScreenDisabled = props?.document?.fields?.isFirstScreenDisabled;

    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [emailMarketOptStatus, setEmailMarketOptStatus] = useState();
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [isFirstLegalModalOpen, setisFirstLegalModalOpen] = useState(false);
    const [isSecondLegalModalOpen, setIsSecondLegalModalOpen] = useState(false);
    const [serverSideErrorMessage, setServerSideErrorMessage] = useState(false);
    const [isDisplayResult, setDisplayResult] = useState(false);
    const [voucherCode, setVoucherCode] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [numCarouselPages, setNumCarouselPages] = useState(0);
    const [lastScroll, setLastScroll] = useState(0);
    const [selectedBrushTypeIndex, setSelectedBrushTypeIndex] = useState(defaultSelectedBrushIndex);
    const [tAndCOptInId, setTAndCOptInId] = useState(false);
    const [emailOptInId, setEmailOptInId] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const blockRef = useRef(null);
    const legalModalRef = useRef(null);
    const productCarouselRef = useRef(null);
    const dotNavRef = useRef(null);

    const customStylesLegal = {
        overlay: {
            backgroundColor: 'none'
        },
        content: {
            position: 'fixed',
            border: 'none',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            padding: '0',
            marginRight: '0',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    };


    const mobileWidthCarousel = 255;
    const desktopWidthCarousel = 1020;

    useEffect(() => {
        setLegalModals();
        const voucherId = sessionStorage.getItem(PostPurchaseConstants.voucherIdKey);
        if (voucherId) {
            setDisplayResult(true);
            setVoucherCode(voucherId);
        } else {
            setOptinId();
        }
    }, []);

    useEffect(() => {
        setNumCarouselPages(0);
        const nextY = 0;
        if (productCarouselRef?.current?.scrollTo) {
            productCarouselRef.current.scrollTo({ left: nextY, behavior: 'smooth' });
        } else if(productCarouselRef?.current?.scrollLeft) {
            productCarouselRef.current.scrollLeft = nextY;
        }
        setCurrentPage(0);
        setLastScroll(0);
        isDisplayResult && updateNumCarouselPages();
    }, [isDisplayResult, selectedBrushTypeIndex]);

    useEffect(() => {
        const handleResize = throttle(updateNumCarouselPages, 100);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [lastScroll]);
    
    const getCarouselWidth = () => ((window.innerWidth <= Constants.tabWidthStart) ? mobileWidthCarousel : desktopWidthCarousel);

    const updateNumCarouselPages = () => {
        if (window && productCarouselRef?.current) {
            const carousel = productCarouselRef.current;
            const children = carousel.children;
            let fullCarouselWidth = 0;

            for (let i = 0; i < children.length; i++) {
                const element = children[i];
                let elementWidth = element.offsetWidth < 225 ? 225 : element.offsetWidth;
                const style = getComputedStyle(element);
                elementWidth += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);

                fullCarouselWidth += elementWidth;
            }
            const brushType = Array.isArray(oralbProductList) && oralbProductList.length > selectedBrushTypeIndex && oralbProductList[selectedBrushTypeIndex];
            const brushes = brushType?.fields?.brushes || [];
            
            // This fixes an issue where the result of "fullCarouselWidth / carousel.offsetWidth" was a low decimal point
            // and created less carousel pages than expected
            const corraselWidth = getCarouselWidth();
            let numPages=0
            if (brushes?.length % 3 === 1) {
                numPages = Math.ceil(fullCarouselWidth / corraselWidth);
            } else {
                numPages = Math.round(fullCarouselWidth / corraselWidth);
            }
            setNumCarouselPages(numPages);
        }
    };

    const handleScroll = useCallback(
        () => {
            if (!document.body.classList.contains('noScroll')) {
                setLastScroll(window.pageYOffset);
            }
        }
    );

    const closeLegalModal = () => {
        setisFirstLegalModalOpen(false);
        setIsSecondLegalModalOpen(false);
    };

    const setLegalModals = () => {
        if (blockRef.current) {
            let firstModalId = blockRef.current.querySelector('#openFirstLegalModal');
            if (firstModalId) {
                firstModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setisFirstLegalModalOpen(true);
                })
            }

            let secondModalId = blockRef.current.querySelector('#openSecondLegalModal');
            if (secondModalId) {
                secondModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIsSecondLegalModalOpen(true);
                })
            }
        }
    };

    const onScroll = useCallback(
        event => {
            if (!productCarouselRef.current) {
                return;
            }
            const brushType = Array.isArray(oralbProductList) && oralbProductList.length > selectedBrushTypeIndex && oralbProductList[selectedBrushTypeIndex];
            const brushes = brushType?.fields?.brushes || [];

            const corraselWidth = getCarouselWidth();
            const currentRatio = event.target.scrollLeft / corraselWidth;
            const min = Math.floor(currentRatio);

            let currentPosition = currentRatio < (min + 0.3) ? min : min + 1;
            currentPosition = Math.max(0, currentPosition);
            currentPosition = Math.min(brushes.length, currentPosition);
            setCurrentPage(currentPosition);
        },
        [productCarouselRef.current],
    );

    const handleDotClick = useCallback(i => {
        if (productCarouselRef.current) {
            const corraselWidth = getCarouselWidth();
            const nextY = corraselWidth * i;
            if (productCarouselRef.current.scrollTo) {
                productCarouselRef.current.scrollTo({ left: nextY, behavior: 'smooth' });
            } else {
                productCarouselRef.current.scrollLeft = nextY;
            }
        }
    }, []);

    const handleClick = event => {
        if (dotNavRef?.current && productCarouselRef?.current) {
            let activePageIndex = parseInt(dotNavRef.current.querySelector('.ob-dot-nav__item--active .ob-dot-nav__button').dataset.index, 10);
            const clickedButton = event.currentTarget.dataset.controlButton;
            if (clickedButton === 'next' && activePageIndex + 1 < numCarouselPages) {
                activePageIndex = activePageIndex + 1;
            } else if (clickedButton === 'prev' && activePageIndex - 1 >= 0) {
                activePageIndex = activePageIndex - 1;
            }
            const nextY = productCarouselRef.current.clientWidth * activePageIndex;
            if (productCarouselRef.current.scrollTo) {
                productCarouselRef.current.scrollTo({ left: nextY, behavior: 'smooth' });
            } else {
                productCarouselRef.current.scrollLeft = nextY;
            }
        }
    };

    const setOptinId = function () {
        let optInId = '';
        fetch(campaignUrlPostPurchase, {
            method: 'GET',
            headers: campaignHeader,
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(json => {
                        json?.optIns?.length > 0 ? setTAndCOptInId(json.optIns[0]?.optInId) : '';
                        json?.optIns?.length > 1 ? setEmailOptInId(json.optIns[1]?.optInId) : '';
                    });
                }
            })
        return optInId;
    };

    const clearErrors = () => {
        setServerSideErrorMessage('');
        setErrorMessage('');
        setEmailErrorMessage('');
    };

    const validateInput = (emailAddress) => {
        let isValidEmail = emailAddress && validatePattern(emailValidatePattern, emailAddress);
        if (!isValidEmail) {
            setEmailErrorMessage(emailAddress?.length > 0 ? emailFormatError : emailError);
        }
        return isValidEmail;
    };

    const handleRegistration = async event => {
        event.preventDefault();
        clearErrors();
        const emailAddress = email.trim();
        try {
            setIsSubmitting(true);
            if (registrationUrl && isNoError) {
                const requestBody = formatPostPurchaseRegistrationBody(emailAddress,
                    process.env.GCS_GET_CAMPAIGN_LOCALE,
                    tAndCOptInId,
                    emailOptInId, 
                    emailMarketOptStatus);
                await fetch(registrationUrl, {
                    method: 'POST',
                    headers: registrationHeader,
                    body: customJsonstringify(requestBody)
                })
                    .then(async (response) => {
                        // 201 for created
                        if (response.status === 201 || response.status === 200) {
                            setIsSubmitting(false);
                            return { success: true };
                        } else {
                            return response.json();
                        }
                    })
                    .then(object => {
                        // print server side error message
                        if (object && object.error && object.error.message) {
                            if ((object.error.code === 'EXCEED_EMAIL_LIMIT') && emailExceedLimitLabel) {
                                setServerSideErrorMessage(emailExceedLimitLabel);
                            } else {
                                setServerSideErrorMessage(object?.error?.message);
                            }
                        } else if (!object?.success) {
                            setServerSideErrorMessage(registerFailedError);
                        } else if (object?.success) {
                            setDisplayResult(true);
                        }
                        setIsSubmitting(false);
                    })
                    .catch(() => {
                        setServerSideErrorMessage(registerFailedError);
                        setIsSubmitting(false);
                    });
            } else {
                console.error('missing register url or prerequest data error');
                setIsSubmitting(false);
            }
        } catch (error) {
            console.error(error);
            setServerSideErrorMessage(registerFailedError);
            setIsSubmitting(false);
        }
    };

    const renderInputScreen = () => {
        const error = (errorMessage || serverSideErrorMessage);
        return <>
            <p className='ob-form__subtitle' dangerouslySetInnerHTML={{ __html: shortDescription }}></p>
            <p className='ob-form__description'>{description}</p>
            {error && <span className='ob-form__error-message'>{error}</span>}
            <form className='ob-form__form'
                name={inputs.formName}
                onSubmit={handleRegistration} noValidate>
                <div className='ob-form__wrapper ob-register__row'>
                    <FormInput
                        label={emailAddressLabel}
                        name={inputs.field.emailAddress}
                        inputType={inputs.type.email}
                        value={email}
                        onChange={(e) => { clearErrors(); setEmail(e?.target?.value) }}
                        validationErrorText={emailErrorMessage}
                        placeholder={emailPlaceHolder}
                    />
                </div>
                {termsAndConditionsTop &&
                    <div className='ob-register__info-text disclaimer-text-why' >
                        <p dangerouslySetInnerHTML={{ __html: termsAndConditionsTop }}></p>
                    </div>
                }
                {termsAndConditionsBottom &&
                    <CheckboxInput
                        label={`<p>${termsAndConditionsBottom}</p>`}
                        name={inputs.field.globalOpt_optStatus}
                        value={emailMarketOptStatus}
                        onChange={() => setEmailMarketOptStatus(!emailMarketOptStatus)}
                    />
                }
                <div className="ob-register__cta-section">
                    <SubmitButton
                        buttonText={submitCtaText}
                        disabled={isSubmitting}
                        isSubmitting={isSubmitting}
                    />
                </div>
            </form>
        </>
    }

    const renderResult = () => {
        const brushType = Array.isArray(oralbProductList) && oralbProductList.length > selectedBrushTypeIndex && oralbProductList[selectedBrushTypeIndex];
        const brushes = brushType?.fields?.brushes || [];
        
        return <>
            {!isFirstScreenDisabled && <p className='ob-form__promo-code'>{`${promoCodeLabel} ${voucherCode}`}</p>}
            <p className='ob-form__replace-head-description'>{replacementBrushHeadLabel}</p>
            {
                Array.isArray(oralbProductList) && <ul role='tablist' className={'ob-post-purchase-register-result__brush-types'}>
                    {oralbProductList.map((product, index) => {
                        const brushTypeCornerClass = index === 0 ? 'ob-post-purchase-register-result__brush-type-left' : (index === oralbProductList.length - 1 ? 'ob-post-purchase-register-result__brush-type-right' : '');
                        return <li role='tab' onClick={() => setSelectedBrushTypeIndex(index)}
                            className={`ob-post-purchase-register-result__brush-type ${index === selectedBrushTypeIndex ? 'ob-post-purchase-register-result__active-brush-type' : ''} ${brushTypeCornerClass}`}
                            key={index} tabIndex={0} onKeyPress={() => { }}>{product?.fields?.brushTypeLabel}</li>
                    })}
                </ul>
            }
            {
                Array.isArray(oralbProductList) && <ul role='tablist' className={'ob-post-purchase-register-result-mobile__brush-types'}>
                    {oralbProductList.map((product, index) => {
                        const brushTypeIcon = index === selectedBrushTypeIndex ? product?.fields?.brushTypeIcon : product?.fields?.brushTypeIconDisabled;
                        return <li role='tab' onClick={() => setSelectedBrushTypeIndex(index)}
                            className={`ob-post-purchase-register-result-mobile__brush-type ${index === selectedBrushTypeIndex ? 'ob-post-purchase-register-result-mobile__active-brush-type' : ''}`}
                            key={index} tabIndex={0} onKeyPress={() => { }}>
                            {brushTypeIcon && <Image className="ob-post-purchase-register-result-mobile__icon" image={brushTypeIcon} />}
                            <span dangerouslySetInnerHTML={{__html: product?.fields?.brushTypeLabelMobile}}/>
                        </li>
                    })}
                </ul>
            }
            {!isObjectEmpty(brushes) && <div className={'ob-post-purchase-register-result__carousel'}>
                <ul className={`ob-post-purchase-register-result__product-list ${numCarouselPages === 1 || brushes.length === 1 ? 'is-centered' : ''}`} onScroll={onScroll} ref={productCarouselRef}>
                    {brushes.map((productItem, index) => {
                        const product = productItem?.fields;
                        const productIcon = productItem?.fields?.icon
                        const assetId = productIcon?.fields?.asset?.fields?.file?.url;
                        const alternateText = productIcon?.fields?.alternateText;
                        const dataActionDetail = product?.ctaUrl?.split('#')[0];
                        let imageSrc = assetId && ImageFactory.buildContentfullImageUrlByHeight(assetId, '300');
                        return <li className={'ob-post-purchase-register-result__product-list-item'} key={index}>
                            <a
                                className={'ob-post-purchase-register-result__product-link event_internal_link'}
                                href={product?.ctaUrl}
                                data-action-detail={dataActionDetail}
                            >
                                {product?.icon && <img className="ob-post-purchase-register-result__icon" src={imageSrc} alt={alternateText} />}
                                <div className={'ob-post-purchase-register-result__product-link-item'}>
                                    <span>{product?.title}</span>
                                    {checkmark && <Image classname='ob-post-purchase-register-result__checkmark-icon' image={checkmark} />}
                                </div>
                            </a>
                            <p className={'ob-post-purchase-register-result__product-description'} dangerouslySetInnerHTML={{ __html: product?.description }}></p>
                        </li>
                    }
                    )}
                </ul>
                {numCarouselPages > 1 &&
                    <div>
                        <button className={'ob-post-purchase-register-result__carousel--control-button'}
                            data-control-button={'prev'}
                            aria-label={ariaLabelPrevious}
                            onClick={handleClick}
                            disabled={currentPage === 0}
                            type={'button'}>
                            <span>
                                <Image image={navLeftIcon} />
                            </span>
                        </button>
                        <button className={'ob-post-purchase-register-result__carousel--control-button'}
                            data-control-button={'next'}
                            aria-label={ariaLabelNext}
                            onClick={handleClick}
                            disabled={currentPage === numCarouselPages - 1}
                            type={'button'}>
                            <span>
                                <Image image={navRightIcon} />
                            </span>
                        </button>
                    </div>
                }
                {Array.isArray(brushes) && brushes.length > 1 &&
                    <div ref={dotNavRef}>
                        <DotNav count={numCarouselPages}
                            onClick={handleDotClick}
                            current={currentPage}
                            color={'blue'} />
                    </div>
                }
            </div>
            }
            {!isFirstScreenDisabled && promocodeNote && <p className='ob-post-purchase-register-result__disclaimer-note'>{promocodeNote.replace(PostPurchaseConstants.promoCode, voucherCode)}</p>}
        </>;
    };

    return (
        <section className='post-purchase-register' id={anchorId}>
            <Waypoint onLeave={closeLegalModal} />
            <div className='ob-register js-modal-container' ref={blockRef}>
                <div className='ob-form__layout ob-register__wrapper'>
                    {commonIcon &&
                        <div className='ob-contentBlock-text-image'>
                            <Image image={commonIcon} />
                        </div>
                    }
                    {!isFirstScreenDisabled && <h2 className='ob-form__title' dangerouslySetInnerHTML={{ __html: title }}></h2>}
                    {isFirstScreenDisabled ? renderResult() : isDisplayResult ? renderResult() : renderInputScreen()}
                </div>
                {(modalLabelFirst || modalLabelSecond) &&
                    <Modal
                        isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                        style={customStylesLegal}
                        onRequestClose={closeLegalModal}
                        closeTimeoutMS={250}
                        overlayClassName={'event_button_click'}
                        portalClassName='post-purchase-register-legal-modal'
                        ref={legalModalRef}
                    >
                        <div className='ob-register__modal-container'>

                            {isFirstLegalModalOpen && modalLabelFirst &&
                                <p dangerouslySetInnerHTML={{ __html: modalLabelFirst }}></p>
                            }

                            {isSecondLegalModalOpen && modalLabelSecond &&
                                <p dangerouslySetInnerHTML={{ __html: modalLabelSecond }}></p>
                            }

                            <button className='event_close_window ob-modal-close'
                                onClick={closeLegalModal}
                                aria-label={closeModalLabel}
                                type='button'
                            >
                                <Icon name='close' size='2.4' />
                            </button>

                        </div>
                    </Modal>
                }
            </div>
            <Waypoint onLeave={closeLegalModal} />
        </section>
    )
};

PostPurchaseRegisterForm.propTypes = {
    document: PropTypes.object
};

export default mediaQueryHOC(PostPurchaseRegisterForm);
// This export is for unit testing (do not remove) :
export const PostPurchaseRegisterFormTest = PostPurchaseRegisterForm;
