import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Image from '../Image/Image';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import Icon from '../Icon/Icon';

function BiographyContent(props) {

    let [modalStatus, setModalStatus] = useState(false);
    let [seeMoreStatus, setSeeMoreStatus] = useState(false);
    
    const { title, classNames, description, image } = props.biographyContent;
    const { isXSmall, isSmall } = props;
    const isMobile = isXSmall || isSmall;
    const customModalStyles = {
        overlay: {
            backgroundColor: 'transparent',
            zIndex: 200
        },
        content: {
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            fontSize: '1.4rem',
            lineHeight: '2.1rem',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: isMobile ? '90%' : '60%',
            maxHeight: isMobile ? '85%' : '70%',
            backgroundColor: 'white'           
        }
    };

    let handleSeeStatus = () => {
        setSeeMoreStatus(!seeMoreStatus);
    }

    let handleModalStatus = () => {
        setModalStatus(!modalStatus);
        setSeeMoreStatus(false);
    }

    return <div className={`ob-article-dr-biography ${classNames}`}>
           <div className="ob-bio-content-vertical">
                <a onClick={() => setModalStatus(!modalStatus)}>
                    <Image image={image} />
                    <p dangerouslySetInnerHTML={{ __html: title }} />
                </a>
           </div>
           {description && <Modal
                                isOpen={modalStatus}
                                onRequestClose={() => handleModalStatus()}                                   
                                portalClassName="ob-article-biography-modal-portal"                                
                                style={customModalStyles}
                                shouldCloseOnOverlayClick={false}                    
                            >
                            <div className="ob-bio-modal-content">
                                <div className={"bio-close-button"}
                                    onClick={() => handleModalStatus()}>
                                    <Icon name={"close"} size='1.4' color={'#00f'} />
                                    </div>
                                    <Image image={image} />
                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                    {props && props.biographyModalArticleList && props.biographyModalArticleList.length > 0 && !seeMoreStatus && 
                                        <div onClick={handleSeeStatus} className="ob-bio-article-see-status">                                        
                                            <Icon className="ob-see-more-button__icon" roundedIcon={'blue'} name={'chevronRight'}/>
                                            <label>{"See More"}</label>
                                        </div>}
                                    {seeMoreStatus && <div>
                                        <ul>
                                            {props && props.biographyModalArticleList && props.biographyModalArticleList.map((articleList, index) => (
                                              <li><a href={articleList.fields.slug.fields.slug} target="_blank">{articleList.fields.title}</a></li>                                        
                                            ))}                                 
                                        </ul>
                                    </div>}
                                    {seeMoreStatus && <div className="ob-bio-article-see-status" onClick={handleSeeStatus}>
                                        <Icon className="ob-see-more-button__icon" roundedIcon={'blue'} name={'chevronLeft'}/>
                                        <label>{"See Less"}</label>
                                    </div>}                      
                            </div>
                            </Modal>}
    </div>
}

export default mediaQueryHOC(BiographyContent);


