export function chunk(items, size) {
    if(!Array.isArray(items)
    || items.length === 0
    || !Number.isInteger(size)
    || size === 0) {
        return [];
    }

    const chunks = [];
    let chunk = [];

    for(let i = 0; i < items.length; i++) {
        chunk.push(items[i]);
        if((i + 1) % size === 0) {
            chunks.push(chunk);
            chunk = [];
        }
    }

    if(chunk.length > 0) {
        chunks.push(chunk);
    }

    return chunks;
}

export function flatten(array) {
    if (!Array.isArray(array)) {
        return [];
    }

    return array.reduce((previous, current) => {
        if (Array.isArray(current)){
            return previous.concat(current);
        } else {
            previous.push(current);
            return previous;
        }
    }, []);
}

export function minimum(array) {
    if (!Array.isArray(array)) {
        return undefined;
    }

    let minimum = undefined;

    for(let i = 0; i < array.length; i++) {
        const value = array[i];
        if(typeof value === 'number') {
            if(minimum === undefined || value < minimum) {
                minimum = value;
            }
        }
    }

    return minimum;
}

// function to return N given of items in an Array
export function first(array, n) {
    // if passed array is not an array, return empty array
    if (!Array.isArray(array)
    || n <= 0) {
        return [];
    }

    //if N number is bigger or equal slice up the entire thing
    if(n >= array.length) {
        return array.slice();
    } else {
        // otherwise return only N numbers of array items
        return array.slice(0, n);
    }
}

export function isLater(value) {
    // Return true if today is after the value date
    let result = false

    if (value && value instanceof Date) {
        result = new Date() > value;
    } else if (value) {
        result = new Date() > new Date(value)
    }

    return result;
}

export function isObject(value) {
    return (!!value) && (value.constructor === Object);
}

export function findIndex(array, fn) {
    if (!Array.isArray(array)
    || !(typeof fn === 'function')) {
        return undefined;
    }
    for(let i = 0; i < array.length; i++) {
        const element = array[i];

        if(fn(element)) {
            return i;
        }
    }

    return undefined;
}

export function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}
