import React from 'react';
import PropTypes from 'prop-types';

import StackedProductsOverView from './mobile/StackedProductsOverview';
import CarrouselProductsOverView from './mobile/CarrouselProductsOverview';

import CollapsibleProductsOverview from './desktop/CollapsibleProductsOverview';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'

function ProductsOverview({
    products,
    seeMoreProductsText,
    seeLessProductsText,
    itemsPerLine,
    whiteText,
    isCarrousel,
    isMedium,
    onClickCallback,
    productOverview,
    removeLazyLoad,
    isRecentlyViewed}) {
    return (
        <div>
            {isMedium ?
                (isCarrousel ?
                    <CarrouselProductsOverView products={products}
                        whiteText={whiteText} onClickCallback={onClickCallback} productOverview={productOverview} isMedium={isMedium} removeLazyLoad={removeLazyLoad} /> :
                    <StackedProductsOverView products={products}
                        whiteText={whiteText} onClickCallback={onClickCallback} productOverview={productOverview} isMedium={isMedium} removeLazyLoad={removeLazyLoad} />) :
                <CollapsibleProductsOverview products={products}
                    seeMoreProductsText={seeMoreProductsText}
                    seeLessProductsText={seeLessProductsText}
                    whiteText={whiteText}
                    isRecentlyViewed={isRecentlyViewed}
                    itemsPerRow={itemsPerLine} onClickCallback={onClickCallback}
                    productOverview={productOverview} removeLazyLoad={removeLazyLoad} />
            }
        </div>
    );
}

export default mediaQueryHOC(ProductsOverview);

ProductsOverview.defaultProps = {
    itemsPerLine: 3,
    products: [],
    isCarrousel: false,
    isRecentlyViewed: false,
    removeLazyLoad: false,
}

ProductsOverview.propTypes = {
    itemsPerLine: PropTypes.number,
    children: PropTypes.any,
    seeMoreProductsText: PropTypes.string,
    seeLessProductsText: PropTypes.string,
    products: PropTypes.any,
    whiteText: PropTypes.any,
    isMedium: PropTypes.bool,
    isCarrousel: PropTypes.bool,
    isRecentlyViewed: PropTypes.bool,
    removeLazyLoad: PropTypes.bool,
    onClickCallback: PropTypes.func.isRequired,
    productOverview: PropTypes.any
}
