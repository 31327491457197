import React, {useCallback, useState, useRef, useEffect, useMemo} from 'react';
import '../ProductOverview';
import ProductOverview from '../ProductOverview';
import DotNav from '../../DotNav/DotNav';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';
import { minimum } from '../../../adapters/helpers/functions';

export default function CarrouselProductsOverview({
    products,
    whiteText,
    onClickCallback,
    productOverview,
    isMedium,
    removeLazyLoad
}) {
    const [currentPage, setCurrentPage] = useState(0);
    const productGridRef = useRef(null);
    const [isShown, setIsShown] = useState(false);

    const onScroll = useCallback(
        (event) => {
            if(!productGridRef.current) {
                return;
            }

            const currentRatio = event.target.scrollLeft / productGridRef.current.clientWidth;
            const min = Math.floor(currentRatio);

            let currentPosition = currentRatio < (min + 0.5) ? min : min + 1;
            currentPosition = currentPosition = Math.max(0, currentPosition);
            currentPosition = Math.min(products.length - 1, currentPosition);

            setCurrentPage(currentPosition);
        },
        [productGridRef.current],
    );

    const handleDotClick = useCallback((i) => {
        if(productGridRef.current) {
            const nextY = productGridRef.current.clientWidth * i;
            if(productGridRef.current.scrollTo) {
                productGridRef.current.scrollTo({ left: nextY, behavior: 'smooth'});
            } else {
                productGridRef.current.scrollLeft = nextY;
            }
        }
    }, []);

    useEffect(() => {
        if(productGridRef.current) {
            productGridRef.current.scrollLeft = 0;
            setCurrentPage(0);
        }
    }, [productGridRef.current]);

    const handleWaypoint = useCallback(() => {
        setIsShown(true);
    }, []);

    const minimumAspectRatio = useMemo(() => {
        const aspectRatio = minimum(
                products.map(product => product?.fields?.mainAsset?.fields?.imageRendition?.fields?.aspectRatio)
            );

        // Temporary while aspect Ratios are too extreme
        return aspectRatio && Math.max(aspectRatio, 0.4);
    }, [products]);

    return (
        <>
            <Waypoint onEnter={handleWaypoint} topOffset={'500px'} bottomOffset={'300px'}>
                <div onScroll={onScroll} ref={productGridRef} className="ob-carrousel-products-overview">
                    {products.map((product, i) => (
                        <div key={i} className="ob-carrousel-products-overview-item">
                            <ProductOverview key={i}
                                product={product}
                                whiteText={whiteText}
                                index={i}
                                aspectRatio={minimumAspectRatio}
                                isShown={isShown}
                                isAutoHeightMode={false}
                                isAnimated={true}
                                onClickCallback={onClickCallback}
                                isMedium={isMedium}
                                removeLazyLoad={removeLazyLoad}
                                productOverview={productOverview}/>
                        </div>
                    ))}
                </div>
            </Waypoint>
            {products.length > 1 &&
                <div>
                    <DotNav className="ob-product-grid__progress-bar"
                        onClick={handleDotClick}
                        count={products.length}
                        current={currentPage} />
                </div>}
        </>
    );
}

CarrouselProductsOverview.defaultProps = {
    products: [],
}

CarrouselProductsOverview.propTypes = {
    products: PropTypes.array,
    whiteText: PropTypes.bool,
    onClickCallback: PropTypes.func,
    productOverview: PropTypes.any,
    isMedium: PropTypes.bool,
    removeLazyLoad: PropTypes.bool,
}
