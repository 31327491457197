import React, { useEffect } from 'react'
import { CustomSearch } from '../Search/Search'
import { InstantSearch } from 'react-instantsearch-dom'
import { getSearchClient } from '../../adapters/algolia/client'
import Hits from '../ModernWebAlgolia/Hits'
import {algoliaReducerConstants} from '../../adapters/helpers/Constants';

const SearchPage = (props) => {
  const searchClient = getSearchClient();
  const document = props?.document;
  const context = props?.context;
  const state = context && context[0];
  const dispatch = context && context[1];
  useEffect(() => {   
      dispatch({ type: algoliaReducerConstants.searchStaticContent,payload:document,...state });     
  },[])
   
  function renderPageLink(pageLink, key) {
    const title = pageLink.fields.title;
    const link = pageLink.fields.pagePath.fields.slug;
    const linkClassName = pageLink?.fields?.linkClassName;
    return <li><a href={link} key={key} className={linkClassName}>{title}</a></li>;
  }

  return (
    <div className="ob-search-block">
      <InstantSearch searchClient={searchClient} indexName={process.env.ALGOLIA_INDEX}>    
        <CustomSearch defaultRefinement=""  state={state} dispatch={dispatch} />
        { (state.queryParm === '' || state.queryParm === undefined) ?
          (<React.Fragment>
            <><h2 className='ob-search-block__suggested-title' dangerouslySetInnerHTML={{__html:props.document.suggestedTitle}} /></>
            <ul className={'ob-search-block__suggested-categories'}>
              {document.suggestedCategories.map((category, index) => {
                return renderPageLink(
                  category,
                  'suggestedCategoryIndex' + index
                );
              })}
            </ul>
          </React.Fragment>)
          :
          (<Hits state={state} dispatch={dispatch} />)
        }
      </InstantSearch>
    </div>
  )
}


export default React.memo(SearchPage)
