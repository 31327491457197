import PropTypes from 'prop-types';
import React from 'react';

export default function CellHtml({className, children, role}) {
    return (
        <div className={'ob-comparison-chart-cell ' + className} role={role}>{children}</div>
    );
}

CellHtml.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    role: PropTypes.string,
}