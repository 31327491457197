import PropTypes from 'prop-types';

export const IImageRendition = PropTypes.shape({
    fields: PropTypes.exact({
        contentType: PropTypes.oneOf(['imageRendition']),
        name: PropTypes.string,
        aspectRatio: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        transformations: PropTypes.string,
    }),
});

export const ICloudinaryImage = PropTypes.shape({
    fields: PropTypes.exact({
        contentType: PropTypes.oneOf(['cloudinaryImage']),
        name: PropTypes.string,
        assetId: PropTypes.string.isRequired,
        alternateText: PropTypes.string,
        classNames: PropTypes.string,
        forcedFormat: PropTypes.string,
        imageRendition: IImageRendition,
        isPreload: PropTypes.bool,
        isBannerImage : PropTypes.bool
    }),
});

export const IPagePath = PropTypes.shape({
    fields: PropTypes.exact({
        contentType: PropTypes.oneOf(['pagePath']),
        slug: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        searchable: PropTypes.bool,
    }),
});

export const ILabel = PropTypes.shape({
    fields: PropTypes.exact({
        contentType: PropTypes.oneOf(['label']),
        text: PropTypes.string,
    }),
});

export const IProductOverview = PropTypes.shape({
    fields: PropTypes.exact({
        contentType: PropTypes.oneOf(['productOverview']),
        name: PropTypes.string,
        slug: IPagePath,
        pageMapping: PropTypes.string,
        uniqueId: PropTypes.string,
        productVariants: PropTypes.array,
        mainAsset: ICloudinaryImage,
        surtitle: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        shortDescription: PropTypes.string,
        productBenefit: PropTypes.string,
        buyNowLabel: ILabel,
        isFullSizeImage: PropTypes.bool,
    }),
});

export const IArticleOverview = PropTypes.shape({
    fields: PropTypes.exact({
        contentType: PropTypes.oneOf(['articleOverview']),
        name: PropTypes.string,
        slug: IPagePath.isRequired,
        pageMapping: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        readTime: PropTypes.number,
        twitterShareText: PropTypes.string,
        thumbnailImage: ICloudinaryImage,
        mobileThumbnail: ICloudinaryImage,
    }),
});
