let smoothScrollId = null;
let smoothScrollStartY = 0;
let smoothScrollDeltaY = 0;
let smoothScrollStartTime = 0;
let smoothScrollDuration = 400;

export function scrollIntoView(element, options = {}) {
    smoothScrollStartY = window.scrollY || window.pageYOffset || 0;
    smoothScrollDeltaY = element.getBoundingClientRect().top;

    const headerElement = document.querySelector('.zone-header');
    const productSubNavElement = document.querySelector('.ob-product-sub-nav');

    if (headerElement) {
        smoothScrollDeltaY -= headerElement.getBoundingClientRect().bottom;

        if (productSubNavElement) {
            smoothScrollDeltaY -= productSubNavElement.getBoundingClientRect().bottom;
        }
    }

    if (options.behavior === 'smooth' && typeof requestAnimationFrame !== 'undefined') {
        smoothScrollStartTime = Date.now();

        if (smoothScrollId == null) {
            smoothScrollStep();
        }
    } else {
        if (smoothScrollId != null) {
            cancelAnimationFrame(smoothScrollId);
            smoothScrollId = null;
        }

        window.scrollTo(window.scrollX, null);
    }
}

function smoothScrollStep() {
    const elapsed = Date.now() - smoothScrollStartTime;
    const t = elapsed / smoothScrollDuration;

    if (t < 1) {
        smoothScrollId = requestAnimationFrame(smoothScrollStep);
        window.scrollTo(window.scrollX, easeInOutQuad(t) * smoothScrollDeltaY + smoothScrollStartY);
    }
    else {
        smoothScrollId = null;
        window.scrollTo(window.scrollX, smoothScrollDeltaY + smoothScrollStartY);
    }
}

function easeInOutQuad(t) {
    return (t < 0.5) ? (2 * t * t) : ((4 - 2 * t) * t - 1);
}
