
const LOCAL_STORAGE_KEYS = {
    RECENTLY_VIEWED_PRODUCTS: 'RECENTLY_VIEWED_PRODUCTS',
}
export function localStorageRemoveItem(item) {
    return localStorage.removeItem(item)
}

export function localStorageGetItem(item) {
    return localStorage.getItem(item)
}

export function localStorageSetItem(item,itemType) {
    return localStorage.setItem(item, itemType);
}

export { LOCAL_STORAGE_KEYS };