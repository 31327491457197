function standardizePath(path) {
    if (typeof path === 'string') {
        return path.replace(/\/{1,}/g, '/')
            .replace(/\/{1,}$/g, '');
    }
}
const customJsonstringify = str => JSON.stringify(str) && JSON.stringify(str).replace(/([&"'<>])/g, (char) => {
    switch (char) {
        case '&':
            return '\u0026'
        case '<':
            return '\u003c'
        case '>':
            return '\u003e'
        case '"':
            return '\u0022'
        case "'":
            return '\u0027'
        default:
            return char
    }
})

module.exports = {
    customJsonstringify,
    standardizePath,
};