import Backdrop from '../Backdrop/Backdrop';
import BodyText from '../BodyText/BodyText';
import Eyebrow from '../Eyebrow/Eyebrow';
import Heading from '../Heading/Heading';
import ProductsOverview from '../ProductsOverview/ProductsOverview';
import React from 'react';
//import {IProductRecommenderProps}  from './ProductRecommender';
import RichText from '../RichText/RichText';
import PropTypes from 'prop-types';
import {ICloudinaryImage, IProductOverview} from '../../types';
import {MultipleProductRecommenderConstant} from '../../adapters/helpers/Constants'

const IProductRecommenderProps = {
    className: PropTypes.string,
    classNames: PropTypes.string, // contentful misspelling
    contentType: PropTypes.oneOf(['productRecommenderBlock']),
    name: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    bodyCopy: PropTypes.string,
    disclaimer: PropTypes.object,
    anchorId: PropTypes.string,
    switchTextAndProduct: PropTypes.bool,
    hasDivider: PropTypes.bool,
    cta: PropTypes.string,
    ctaLink: PropTypes.string,
    singleColorBackground: PropTypes.string,
    whiteText: PropTypes.bool,
    backgroundAsset: ICloudinaryImage,
    mobileBackgroundAsset: ICloudinaryImage,
    productList: PropTypes.arrayOf(IProductOverview),
    productGroup: PropTypes.object,
    seeMoreLabel: PropTypes.object,
    seeLessLabel: PropTypes.object,
    revision: PropTypes.number,
    onClickCallback: PropTypes.func,
};

export default function MultipleProductRecommender(props) {
    const {classNames, className = classNames} = props;
    const {productList, anchorId, eyebrow, headline, bodyCopy, hasDivider, disclaimer, onClickCallback} = props;
    const {whiteText, backgroundAsset, mobileBackgroundAsset} = props;
    const seeLessLabel = props?.seeLessLabel?.fields?.text;
    const seeMoreLabel = props?.seeMoreLabel?.fields?.text;

    const style = {
        backgroundColor: props.singleColorBackground,
    };

    return (
        <Backdrop className={`ob-product-recommender ${hasDivider ? 'ob-product-recommender--has-divider' : ''} ${className || ''}`} id={`${anchorId}`} style={style}>
            <Backdrop.Image preserveAspectRatio={false} image={backgroundAsset} mobileImage={mobileBackgroundAsset}/>

            <Backdrop.Content className="ob-product-recommender__content">
                <div className="ob-product-recommender__wrapper">
                    {!!eyebrow && (
                        <Eyebrow className="ob-product-recommender__eyebrow" whiteText={whiteText}>
                            {eyebrow}
                        </Eyebrow>
                    )}

                    {!!headline && (
                        <Heading className="ob-product-recommender__heading" whiteText={whiteText}>
                            {headline}
                        </Heading>
                    )}

                    {!!bodyCopy && (
                        <BodyText className="ob-product-recommender__body" whiteText={whiteText}>
                            {bodyCopy}
                        </BodyText>
                    )}

                    {disclaimer && (
                        <div className="ob-product-recommender__side-disclaimer">
                            <RichText whiteText={whiteText} document={disclaimer} />
                        </div>
                    )}

                    {(Array.isArray(productList) && productList.length > 0) && (
                        <div className="ob-product-recommender__products">
                            <ProductsOverview
                                products={productList}
                                seeLessProductsText={seeLessLabel}
                                seeMoreProductsText={seeMoreLabel}
                                itemsPerLine={Math.max(Math.min(productList.length, 3), 3)}
                                whiteText={whiteText}
                                isCarrousel={false}
                                onClickCallback={onClickCallback}
                            />
                        </div>
                    )}
                </div>
            </Backdrop.Content>
        </Backdrop>
    );
}

MultipleProductRecommender.propTypes = IProductRecommenderProps;
