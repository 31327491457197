import {ComparisonChartBlockConstants} from '../../adapters/helpers/Constants';
import PropTypes from 'prop-types';
import React from 'react';

export default function Row({children, role}) {
    return (
        <div role={role} className='ob-comparison-chart-row'>{children}</div>
    );
}

Row.defaultProps = {
    role: ComparisonChartBlockConstants.row,
}

Row.propTypes = {
    children: PropTypes.node,
    role: PropTypes.any,
}