import React, { useEffect,useCallback, useRef,useState } from 'react';
import PropTypes from 'prop-types';
import { getReviewAndRatingId} from '../../adapters/helpers/Utils';
import { handleFindElement } from '../../adapters/helpers/Utils';
import { UserQuoteConstants, PrePurchaseConstants, PostPurchaseConstants} from '../../adapters/helpers/Constants';
import ProductSEO from './ProductSchema';
import { getSearchQueryParameter } from '../Janrain/Janrain/janrainForms/Janrain.helpers';

function CustomerReview(props) {
    let [rating, setRating] = useState();
    let [review, setTotalReview] = useState();
    const ratingRefernce = useRef()   
    const reviewAndRatingId = getReviewAndRatingId(props?.product?.productOverview);
    const customerReviewRef = useRef(null);

    useEffect(() => {
        handleRatingandReviews();
    }, []);

    const handleRatingandReviews=()=>{	
        const requestOptions = {	
        method: 'GET',	
        };	
        	
        let RnRURL = process.env.BAZAAR_VOICE_RNR?.replace('{reviewAndRatingId}', reviewAndRatingId);	
            	
        fetch(RnRURL, requestOptions).then(async (response) => {	
            if(response.ok) {	
                const output= await response.json();	
                return output;	
            }	
        }).then(async (output) => {	
                setRating(output?.reviewSummary?.primaryRating?.average)	
                setTotalReview(output?.reviewSummary?.numReviews)	
        })	
        .catch((error) => {	
        console.error('Bazaar Voice RNR API: ', error);	
        });	
    }

    useEffect(() => {
        document.addEventListener('DOMContentLoaded', handleFindElement());
        return () => document.removeEventListener('DOMContentLoaded', handleFindElement());
        
    },[]); 

    useEffect(() => {
        setTimeout(()=>{
            const rnrButtonId = document.getElementById('rnrButton');
            rnrButtonId && rnrButtonId.addEventListener('click', function(){
                if(customerReviewRef.current){
                    customerReviewRef.current.scrollIntoView('behaviour:smooth')
                }
            })
        },1000)
    },[]);

    useEffect(() => {
        const writeAReviewQueryParamValue = getSearchQueryParameter(PrePurchaseConstants.writeAReviewQueryParam);
        if(writeAReviewQueryParamValue === PrePurchaseConstants.writeAReviewQueryParamValue) {
            setTimeout(() => {
                const warCta = document.getElementsByClassName(PostPurchaseConstants.WAR_POP_ID).length > 0 && document.getElementsByClassName(PostPurchaseConstants.WAR_POP_ID)[0]
                warCta && warCta.click();
            }, PrePurchaseConstants.WAR_CTA_CLICK_TIMEOUT);
        }
    }, []);    

    return (
        <>
            { props.isDisplay &&
                  <div ref={customerReviewRef} className='ob-customer-review' >
                   
                    {reviewAndRatingId &&
                       <div className='ob-customer-review-container' id='BVCustomerReviewContiner'>
                            <h2 className='ob-customer-review-title'>{props.title}</h2>

                            <div ref={ratingRefernce} data-bv-show={UserQuoteConstants.ratingSummary} data-bv-product-id={reviewAndRatingId}></div>

                            <div  data-bv-show={UserQuoteConstants.reviews} data-bv-product-id={reviewAndRatingId}></div>

                            <div data-bv-show={UserQuoteConstants.questions} data-bv-product-id={reviewAndRatingId}></div>
                        </div>
                    }
                </div>
            }
            <ProductSEO
                productDetails={props?.product?.productOverview?.fields}
                rating={rating}
                review={review}              
            /> 
        </>
    )
}

CustomerReview.propTypes = {
    product: PropTypes.object,
    productName: PropTypes.string,
    title: PropTypes.string,
    isDisplay: PropTypes.bool,
}


export default CustomerReview
