import React, { useContext, useMemo } from 'react';
import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import { Waypoint } from 'react-waypoint';
import Eyebrow from '../Eyebrow/Eyebrow'
import Heading from '../Heading/Heading'
import Button from '../Button/Button'
import BodyText from '../BodyText/BodyText'
import Icon from '../Icon/Icon'
import ObLink from '../ObLink/ObLink'
import PropTypes from 'prop-types'
import OnePageScrollContext from '../OnePageScroll/OnePageScrollContext';
import { getConfigurationValue } from '../../adapters/helpers/ContentBlockConfiguration';
import { Transition } from 'react-transition-group';
import {SpotlightContentBlockConstants} from '../../adapters/helpers/Constants';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'

/**
 *
 * Supported Classes:
 *  By default the background is white and the text color is primary-grey.
 *  The CTA is a Button by default
 *  - white-text : change the text color to white
 *  - align-center : force a text-align: center without impacting the text container alignment
 *
 *  - background-primary-grey : change the background to primary grey
 *  - background-secondary-grey : change the background to secondary grey
 *  - background-secondary-off-white : change the background to secondary-off-white
 *  - background-isolate-grey : change the background to isolate grey
 *  - background-primary-blue : change the background to primary-blue
 *  - background-secondary-blue : change the background to secondary-blue
 *
 *  - hasLink : Use ObLink component for the CTA instead ob Button
 *
 */

function SpotlightContentBlock(props) {
    const {isXSmall, isMedium, isXLarge} = props;
    const duration = 600;

    const { currentIndex } = useContext(OnePageScrollContext);
    const isActiveBlock = props.index === currentIndex;

    const block = new Block(props);

    const valueOf = (name) => {
        if (name) {
            return block.getFieldValue(name);
        }
    }

    const generateContentBlockState = () => {
        return {
            availableConfigurations: valueOf(SpotlightContentBlockConstants.devicesConfigurations).map(configuration => configuration.fields.deviceType),
            textAlign: configurationValue(SpotlightContentBlockConstants.textAlign, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            textContainerHorizontalAlignment: configurationValue(SpotlightContentBlockConstants.textAlignment, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            textContainerVerticalAlignment: configurationValue(SpotlightContentBlockConstants.textContainerVerticalAlignment, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            textContainerOffset: configurationValue(SpotlightContentBlockConstants.textContainerOffset, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            textContainerMarginRight: configurationValue(SpotlightContentBlockConstants.textContainerMarginRight, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            textContainerMarginLeft: configurationValue(SpotlightContentBlockConstants.textContainerMarginLeft, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            mainAsset: configurationValue(SpotlightContentBlockConstants.mainAsset, isMedium, isXSmall, isXLarge),
            mainAssetHorizontalAlignment: configurationValue(SpotlightContentBlockConstants.mainAssetHorizontalAlignment, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            mainAssetMaxWidth: configurationValue(SpotlightContentBlockConstants.mainAssetMaxWidth, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            mainAssetOffsetTop: configurationValue(SpotlightContentBlockConstants.mainAssetMarginTop, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            mainAssetOffsetRight: configurationValue(SpotlightContentBlockConstants.mainAssetMarginRight, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            mainAssetOffsetBottom: configurationValue(SpotlightContentBlockConstants.mainAssetOffsetBottom, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            mainAssetOffsetLeft: configurationValue(SpotlightContentBlockConstants.mainAssetOffsetLeft, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            secondaryAsset: configurationValue(SpotlightContentBlockConstants.secondaryAsset, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            additionalAssetList: configurationValue(SpotlightContentBlockConstants.additionalAssetList, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
            backgroundAsset: configurationValue(SpotlightContentBlockConstants.backgroundAsset, isMedium, isXSmall, isXLarge),
            mainAssetScale: configurationValue(SpotlightContentBlockConstants.mainAssetScale, isMedium, isXSmall, isXLarge, SpotlightContentBlockConstants.empty),
        };
    }

    const configurationValue = (fieldName, isMedium, isXSmall, isXLarge, defaultValue = false) => {
        return getConfigurationValue(availableConfigurations,
            valueOf(SpotlightContentBlockConstants.devicesConfigurations),
            fieldName,
            isMedium,
            isXSmall,
            isXLarge,
            defaultValue);
    }

    const imageRender = (resetImage) => {
        return (
            <Image offsetBottom={blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null}
                   offsetTop={blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null}
                   offsetLeft={blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null}
                   offsetRight={blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null}
                   scale={blockState.mainAssetScale ? blockState.mainAssetScale : null}
                   image={blockState.mainAsset}
                   resetImage={resetImage}
                   className={blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`} />
        )
    };

    const secondaryImageRender = (resetImage) => {
        return (
            <Image offsetBottom={blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null}
                   offsetTop={blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null}
                   offsetLeft={blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null}
                   offsetRight={blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null}
                   scale={blockState.mainAssetScale ? blockState.mainAssetScale : null}
                   image={blockState.secondaryAsset}
                   resetImage={resetImage}
                   className={blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`} />
        )
    };

    const additionalAssetRender = (image, key) => {
        return (
            <Image key={key}
                   offsetBottom={blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null}
                   offsetTop={blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null}
                   offsetLeft={blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null}
                   offsetRight={blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null}
                   scale={blockState.mainAssetScale ? blockState.mainAssetScale : null}
                   image={image}
                   className={blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`} />
        )
    };

    const textFadeIn = valueOf(SpotlightContentBlockConstants.textFadeIn);
    const refreshImageOnFrameChange = valueOf(SpotlightContentBlockConstants.refreshImageOnFrameChange) || false;
    const availableConfigurations = valueOf(SpotlightContentBlockConstants.devicesConfigurations).map(configuration => configuration.fields.deviceType);

    let blockState  = useMemo(() => generateContentBlockState(), [isMedium, isXSmall, isXLarge]);

    let className = SpotlightContentBlockConstants.content + block.getFieldValue(SpotlightContentBlockConstants.classNames);
    const isWhiteText = className.includes(SpotlightContentBlockConstants.whiteText);
    const hasLink = className.includes(SpotlightContentBlockConstants.hasLink);

    const title = valueOf(SpotlightContentBlockConstants.title);
    const titleLevel = valueOf(SpotlightContentBlockConstants.titleLevel);
    let textImage = valueOf(SpotlightContentBlockConstants.textImage);
    const textImageMobile = valueOf(SpotlightContentBlockConstants.textImageMobile);
    const textImagePosition = valueOf(SpotlightContentBlockConstants.textImagePosition) || SpotlightContentBlockConstants.top;
    const anchorId = block.getAnchorId();
    const textContainerBackgroundColor = valueOf(SpotlightContentBlockConstants.textContainerBackgroundColor);
    const textContainerHasPadding = valueOf(SpotlightContentBlockConstants.textContainerHasPadding);
    
    if(isMedium && textImageMobile) {
        textImage = textImageMobile;
    }

    return (
        <Transition in={props.index === undefined} timeout={duration}>
            {() => (
                <section className={`ob-spotlightContentBlock ${className}`} id={anchorId} data-isactiveblock={isActiveBlock}>
                    <Image isMedium={isMedium} image={blockState.backgroundAsset !== false ? blockState.backgroundAsset : undefined} className={'ob-spotlightContentBlock-background'} forceBackground/>

                    <div className={`ob-spotlightContentBlock-wrapper ${blockState.textContainerVerticalAlignment && `vertical-${blockState.textContainerVerticalAlignment}`}`}>
                        <div className={`ob-spotlightContentBlock-textContent
                            ${textContainerHasPadding ? ' hasPadding' : ''}
                            ${blockState.textContainerHorizontalAlignment && `horizontal-${blockState.textContainerHorizontalAlignment}`}`}
                            style={{
                                marginTop: blockState.textContainerOffset,
                                marginLeft: blockState.textContainerMarginLeft,
                                backgroundColor: textContainerBackgroundColor,
                                textAlign: blockState.textAlign
                            }}>

                            <Eyebrow whiteText={isWhiteText}>{valueOf(SpotlightContentBlockConstants.surtitle)}</Eyebrow>

                            { (textImage && textImagePosition == SpotlightContentBlockConstants.top) &&
                                <Image noLazyLoad image={textImage} />
                            }

                            <Heading whiteText={isWhiteText} tag={`h${titleLevel}`} className="ob-display-2-xl">{title}</Heading>

                            <BodyText whiteText={isWhiteText}>{valueOf(SpotlightContentBlockConstants.textContent)}</BodyText>

                            { (textImage && textImagePosition == SpotlightContentBlockConstants.bottom) &&
                                <Image noLazyLoad image={textImage} />
                            }

                            { hasLink ? (
                                <ObLink href={`${valueOf(SpotlightContentBlockConstants.callToActionLink)}`}
                                    className={isWhiteText ? SpotlightContentBlockConstants.white : ''}
                                    icon={SpotlightContentBlockConstants.chevronRight}>
                                    <Icon name={SpotlightContentBlockConstants.chevronRight} roundedIcon={SpotlightContentBlockConstants.blue} />
                                    {valueOf(SpotlightContentBlockConstants.callToActionLabel)}
                                </ObLink>
                            ) : (
                                <Button whiteTheme={isWhiteText}
                                    tag="a"
                                    href={valueOf(SpotlightContentBlockConstants.callToActionLink)}>
                                    {valueOf(SpotlightContentBlockConstants.callToActionLabel)}
                                </Button>
                            )}

                            { textFadeIn &&
                                <Waypoint onEnter={this._handleWaypointEnter} />
                            }

                        </div>

                        { blockState.mainAsset &&
                            <div className={'ob-spotlightContentBlock-mainAsset-container'}>
                                {imageRender(isActiveBlock && refreshImageOnFrameChange)}
                            </div>
                        }

                        { blockState.secondaryAsset &&
                            <div className={'ob-spotlightContentBlock-secondaryAsset-container'}>
                                {secondaryImageRender(isActiveBlock && refreshImageOnFrameChange)}
                            </div>
                        }

                        { blockState.additionalAssetList &&
                            <div className={'ob-spotlightContentBlock-additionalAssetList-container'}>
                                { blockState.additionalAssetList.map((image, key) =>
                                    additionalAssetRender(image, key)
                                )}
                            </div>
                        }
                    </div>
                </section>
            )}
        </Transition>
    )
}

export default mediaQueryHOC(SpotlightContentBlock)

SpotlightContentBlock.propTypes = {
    isXSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isXLarge: PropTypes.bool,
    index: PropTypes.any,
}