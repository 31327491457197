import React from 'react';
import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import Heading from '../Heading/Heading'
import StarRating from '../StarRating/StarRating'
import PropTypes from 'prop-types'
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import { UserQuoteConstants } from '../../adapters/helpers/Constants';
import {Label} from '../Label/Label';
import CustomerReview from '../CustomerReviews/CustomerReview';
import Disclaimer from '../Disclaimer/Disclaimer';

function UserQuote(props) {
    const {extraAttributes, isMedium} = props;
    return (extraAttributes && extraAttributes.entity) ?
        renderUserQuoteBlock(extraAttributes.entity, props, isMedium) :
        '';
}

export default mediaQueryHOC(UserQuote);

function renderUserQuoteBlock(entity, props) {

    const product = entity;
    const productOverview = new Block(entity.productOverview);
    const propsBlock = new Block(props);

    const classNames = propsBlock.getFieldValue(UserQuoteConstants.classNames);
    const title = propsBlock.getFieldValue(UserQuoteConstants.title);
    const anchorId = propsBlock.getAnchorId();
    const mainAsset = productOverview.getFieldValue(UserQuoteConstants.mainAsset);
    const productName = productOverview.getFieldValue(UserQuoteConstants.name);
    const disclaimer = productOverview.getFieldValue(UserQuoteConstants.disclaimer);
    const writeAReviewLabel = Label({label: propsBlock.getFieldValue(UserQuoteConstants.writeAReviewLabel)});
    const userQuote = entity.featuredQuote;

    let authorName;
    let quote;
    let starRating;
    let displayUserReviews;

    if(userQuote) {
        authorName = userQuote.fields.authorName;
        quote = userQuote.fields.quote;
        starRating = userQuote.fields.starRating;
        displayUserReviews = userQuote.fields.displayUserReviews;
    }

    //User reviews are displayed by default if there is no featured quote
    if(displayUserReviews == undefined){
        displayUserReviews = true;
    }

    return (

        <div className={`ob-user-quote ${classNames ? classNames : ''}`} id={anchorId} >
            <div className='ob-user-quote_wrapper'>
                {userQuote &&
                    <div className='ob-user-quote_featured-quote-wrapper'>
                        <Heading className='ob-user-quote_heading' tag={UserQuoteConstants.h2}>
                            {title}
                        </Heading>
                        <div className='ob-user-quote_flex-container'>
                            <div className='ob-user-quote_flex-container-col'>
                                <div className='ob-user-quote_star-rating'>
                                    {props.isMedium ? (
                                        <StarRating ratingNumber={starRating}/>
                                    ) : (
                                        <StarRating ratingNumber={starRating} smallSize={5.2} mediumSize={7.2}
                                                    largeSize={9.2}/>
                                    )}
                                </div>

                                <div className='ob-user-quote_quote'>
                                    {quote}
                                </div>

                                <div className='ob-user-quote_author-name'>{authorName}</div>
                            </div>
                            <div className='ob-user-quote_flex-container-col'>
                                <Image image={mainAsset} className='ob-user-quote_image'></Image>
                            </div>
                        </div>
                        {disclaimer &&
                            <Disclaimer document={disclaimer} />
                        }
                    </div>
                }

                <CustomerReview
                    product={product}
                    productName={productName}
                    isDisplay={displayUserReviews}
                    title={writeAReviewLabel}/>

            </div>
        </div>

    )
}

renderUserQuoteBlock.propTypes = {
    isMedium: PropTypes.bool,
}
