
import PropTypes from 'prop-types'
import React from 'react'
import {isString, isObject} from '../../adapters/helpers/Utils';

function Eyebrow({children, tag: Tag, id, className, styles, whiteText, blueEyebrow}) {

    if (!children) {
        return null
    }

    const isStringChildren = isString(children);
    const isObjectChildren = isObject(children);

    className = className ? `ob-eyebrow ${className}` : 'ob-eyebrow';

    const renderChildren = () => {
        try {
            if (typeof children.map == 'undefined') {
                return children
            } else {
                return children.map((child)=> child);
            }
        }
        catch {
            console.log('Eyebrow error', children);
        }
    }

    return (
        <Tag id={id}
            className={className}
            data-white-text={whiteText}
            data-blue-text={blueEyebrow}
            style={styles}>

            { isObjectChildren ? (
                renderChildren()
            ) : (
                isStringChildren ? ( <span dangerouslySetInnerHTML={{__html: children}}></span> ) : ('')
            )}

        </Tag>
    )
}

Eyebrow.propTypes = {
    /**
    * The html to be structured
    */
    children: PropTypes.node,

    /**
    * The tag to be used for the containing element
    */
    tag: PropTypes.string,

    /**
    * The ID attribute be added to the element
    */
    id: PropTypes.string,

    /**
    * The Class attribute be added to the element
    */
    className: PropTypes.string,

    /**
     * Add custom styles to the eyebrow
     */
    styles: PropTypes.object,

    /**
    * Set white text if needed
    */
    whiteText: PropTypes.bool,

    /**
    * Set blue primary color on the eyebrow
    */
    blueEyebrow: PropTypes.bool,
}

Eyebrow.defaultProps = {
    tag: 'p',
    className: '',
    styles: {},
    whiteText: false
  }

export default Eyebrow

