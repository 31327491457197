import React from 'react';
import { ForgotPasswordForm } from '../Janrain/Janrain/janrainForms';
import { objectWithLabel } from '../../adapters/helpers/Utils'
import JanrainForm from '../Janrain/Janrain/HOC/JanrainForm';

export default function ForgotPassword(props) {
    const title = props?.document?.fields?.title;
    const subTitle = props?.document?.fields?.surtitle;
    const successLink = props?.document?.fields?.successLink;
    const subtitleSuccess = props?.document?.fields?.subtitleSuccess;
    const emailLabel = props?.document?.fields?.email?.fields?.text;
    const submitButtonLabel = props?.document?.fields?.submitButtonLabel?.fields?.text;
    const emailValidationRegex = props?.document?.fields?.emailValidationRegex?.fields?.pattern;
    const ariaFormIsSubmitting = props?.document?.fields?.ariaFormIsSubmitting?.fields?.text;

    const loginFormLabels = {
        forgotPasswordEmailFieldLabel: emailLabel,
        forgotPasswordButtonText: submitButtonLabel,
        forgotPasswordSuccessText: subtitleSuccess,
    };

    const janrainErrorList = props?.document?.fields?.janrainErrors?.fields;

    return <JanrainForm>
            <ForgotPasswordForm
                formHeading={title}
                formSubHeading={subTitle}
                janrainLabels={loginFormLabels}
                emailValidationRegex={emailValidationRegex}
                ariaFormIsSubmitting={ariaFormIsSubmitting}
                janrainFormErrors={objectWithLabel(janrainErrorList)}
                formSuccessLink={successLink}
            />
    </JanrainForm>
}
