import React from 'react';
import Image from '../Image/Image';
import PropTypes from 'prop-types';
import {findIndex} from '../../adapters/helpers/functions';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { ResponsiveImageConstants } from '../../adapters/helpers/Constants'

function ResponsiveImage({document, isXSmall, isMedium, isXLarge}) {
    const classNames = document?.fields?.classNames || '';
    const title = document?.fields?.title;
    const destinationUrl =  document?.fields?.destinationUrl;
    const imageTitle = document?.fields?.desktopImage?.fields?.alternateText;

    // We try to tind the smallest possible possible format
    const formats = [
        [ResponsiveImageConstants.tiny, document?.fields?.tinyImage, isXSmall],
        [ResponsiveImageConstants.mobile, document?.fields?.mobileImage, isMedium],
        [ResponsiveImageConstants.tablet, document?.fields?.tabletImage, isXLarge],
        [ResponsiveImageConstants.desktop, document?.fields?.desktopImage, true],
    ]

    let currentFormat = [...formats]
        .reverse()
        .reduce((prev, curr) => curr[2] ? curr[0]: prev, undefined);

    let image = undefined;

    if(currentFormat) {
        /*
            If there is a format we get the index of the format
            and then we find the closest image to the format
        */
        const index = findIndex(formats, format => format[0] === currentFormat);
        for(let i = index; i < formats.length; i++) {
            const format = formats[i];
            if(format[1]) {
                image = format[1];
                break;
            }
        }
    }

    if(!image) {
        /* If there is still no images we get the largest available image */
        image = formats
            .reduce((prev, curr) => curr[1] ? curr[1]: prev, undefined);
    }

    if(!image) {
        /* We return null if the if the formats array contains no images */
        return null;
    }

    return (
        <div className={classNames}>
             {title && <h2 dangerouslySetInnerHTML={{__html: title}}></h2>}
            {destinationUrl ? <a aria-label={'responsiveImageBlock-banner'||`${'responsiveImageBlock'-`${title||imageTitle}`}`} href={destinationUrl} target="_blank"><Image image={image} /></a> : <Image image={image} />}
        </div>
    );
}

export default mediaQueryHOC(ResponsiveImage);

ResponsiveImage.propTypes = {
    document: PropTypes.object,
    extraAttributes: PropTypes.object,
    isXSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isXLarge: PropTypes.bool,
  }
