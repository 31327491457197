import Document from './Document';
import componentFactory from '../../../components/ComponentFactory';
import React from 'react';

class Component extends Document {

    constructor(props) {
        super(props);
        this.props = props;
    }

    renderBlock(moduleClass, attributes, moduleBody) {
        const moduleDefinition = componentFactory.getModule(moduleClass);
        if (moduleDefinition) {
            const module = React.createElement(moduleDefinition, attributes, moduleBody);
            return module;
        } else {
            console.info('Unsupported module type : ' + moduleClass);
        }
    }
}

export default Component