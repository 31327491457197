import React from 'react';
import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import Heading from '../Heading/Heading';
import {ShowcaseBlockConstants} from '../../adapters/helpers/Constants';

export default function ShowcaseBlock(props) {
    const block = new Block(props);

    const className = block.getFieldValue(ShowcaseBlockConstants.classNames);
    const anchorId = block.getAnchorId();

    const title = block.getFieldValue(ShowcaseBlockConstants.title);
    const description = block.getFieldValue(ShowcaseBlockConstants.description);
    const sideImage = block.getFieldValue(ShowcaseBlockConstants.sideImage);

    return (
        <div className={`ob-showcase ${className}`} id={anchorId} >
            <Image image = {sideImage}></Image>
            <div className={'ob-showcase__content'}>
                <Heading tag="h2" className={'ob-showcase__title'}>{title}</Heading>
                <p className={'ob-showcase__description'}>{description}</p>
            </div>
        </div>
    )
}