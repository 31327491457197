import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useJanrainGlobalState from '../useJanrainGlobalState';
import ObLink from '../../../../ObLink/ObLink';
import FormInput from '../../../components/FormInput/FormInput';
import FormStore from '../generic-form.store';
import { observer } from 'mobx-react';
import { forgotPasswordValidations } from '../form.validators';
import { inputs } from './inputs';
import { SubmitButton } from '../../../components';
import { FormErrors } from '../index';
import { focusOnElement } from '../../../../../adapters/helpers/Utils'

const forgotPasswordStore = new FormStore(forgotPasswordValidations, 1);

const ForgotPasswordForm = ({ formHeading, formSubHeading, janrainLabels, ariaFormIsSubmitting, emailValidationRegex, janrainFormErrors, formSuccessLink }) => {
    const { form, errors, formValidated } = forgotPasswordStore;
    const [isFormProcessing, setIsFormProcessing] = useState(false);
    const [isNextStep, setNextStep] = useState(false);
    const [globalState, setGlobalState] = useJanrainGlobalState();

    const handlePasswordSend = (e) => {
        e.preventDefault();
        setIsFormProcessing(true);
    }

    useEffect(() => {
        forgotPasswordStore.clear();
        forgotPasswordStore.errorTexts = janrainFormErrors;
    }, []);

    useEffect(() => {
        if (isFormProcessing) {
            window.janrain.events.onCaptureSaveSuccess.addHandler(handleFormSendSuccess);
            window.janrain.events.onCaptureSaveFailed.addHandler(handleFormSendFailed);
            // Uncomment this when locale is added to flow.
            // form.locale = process.env.JANRAIN_CAPTURE_LOCALE;
            window.janrain.capture.ui.postCaptureForm(inputs.formName, form);
        }
    }, [isFormProcessing]);

    useEffect(() => {
        if (isNextStep) {
            const successFocus = document.querySelector('.ob-form__subtitle');
            focusOnElement(successFocus);
        }
    }, [isNextStep]);

    const handleFormSendFailed = (data) => {
        setIsFormProcessing(false);
        globalState.isInvalidForm = true;
        globalState.formErrorResponse = data;
        setGlobalState(globalState);
    };

    const handleFormSendSuccess = () => {
        setIsFormProcessing(false);
        setNextStep(true);
    };

    const renderFakeInputsToPreventAutofill = () => <>
        <input aria-label={inputs?.field?.signInEmailAddress} type={inputs.type.email} style={{ 'display': 'none' }} name={inputs.field.signInEmailAddress} />
    </>;

    return (
        <div className='ob-forgot-password' >
            <div className='ob-form__layout ob-forgot-password__wrapper'>
                {!isNextStep &&
                    <form
                        className='ob-form__form'
                        name={inputs.formName}
                        onSubmit={handlePasswordSend}>
                        {renderFakeInputsToPreventAutofill()}
                        <h1 className='ob-form__title' dangerouslySetInnerHTML={{ __html: formHeading }}></h1>
                        <h2 className='ob-form__subtitle' dangerouslySetInnerHTML={{ __html: formSubHeading }}></h2>
                        {globalState.isInvalidForm && <FormErrors {...globalState.formErrorResponse} predefinedErrors={janrainFormErrors} />}
                        <FormInput
                            label={janrainLabels.forgotPasswordEmailFieldLabel}
                            inputType={inputs.type.email}
                            name={inputs.field.signInEmailAddress}
                            value={form.signInEmailAddress ? form.signInEmailAddress : ''}
                            onChange={(e) => forgotPasswordStore.fieldChange(inputs.field.signInEmailAddress, e.target.value)}
                            validationErrorText={errors.signInEmailAddress}
                            halfWidth={true}
                        />
                        <SubmitButton
                            buttonText={janrainLabels.forgotPasswordButtonText}
                            disabled={!formValidated}
                            ariaFormIsSubmitting={ariaFormIsSubmitting}
                            isSubmitting={isFormProcessing}
                        />
                    </form>
                }

                {isNextStep &&
                    <form className='ob-form__form'>
                        <h1 className='ob-form__title' dangerouslySetInnerHTML={{ __html: formHeading }}></h1>
                        <h2 className='ob-form__subtitle' dangerouslySetInnerHTML={{ __html: janrainLabels.forgotPasswordSuccessText }}></h2>
                        <ObLink href={formSuccessLink.fields.pagePath.fields.slug} className={'ob-forgot-password__success-link'}>{formSuccessLink.fields.title}</ObLink>
                    </form>
                }
            </div>
        </div>
    )
};

ForgotPasswordForm.propTypes = {
    ariaFormIsSubmitting: PropTypes.string,
    janrainLabels: PropTypes.shape({
        forgotPasswordEmailFieldLabel: PropTypes.string.isRequired,
        forgotPasswordButtonText: PropTypes.string.isRequired,
        forgotPasswordSuccessText: PropTypes.string.isRequired,
    }).isRequired,
    janrainFormErrors: PropTypes.object.isRequired,
};
export default observer(ForgotPasswordForm)