import React from 'react';
import PropTypes from 'prop-types';

import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import BodyText from '../BodyText/BodyText';
import Component from '../../adapters/helpers/entity/Component';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { PromoConstants } from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';

function Promo(props) {
    const {extraAttributes, isMedium} = props;
    const entityPromo = extraAttributes?.entity?.promo || extraAttributes?.promo;

    if (!entityPromo) {
        return null;
    }

    const block = new Block(props);
    const promo = new Component(entityPromo);

    const className = PromoConstants.promo + block.getFieldValue(PromoConstants.classNames);
    const isWhiteText = className.includes(PromoConstants.whiteText);
    const anchorId = block.getAnchorId();

    const subtitle = promo.getFieldValue(PromoConstants.subtitle);
    const desctiption = promo.getFieldValue(PromoConstants.description);
    const retailerText = promo.getFieldValue(PromoConstants.retailerText);
    const backgroundAsset = isMedium ? promo.getFieldValue(PromoConstants.mobileBackgroundAsset) : promo.getFieldValue(PromoConstants.backgroundAsset);
    const titleLevel = promo.getFieldValue(PromoConstants.titleLevel);
    const title = promo.getFieldValue(PromoConstants.title);
    const retailerLogo = promo.getFieldValue(PromoConstants.retailerLogo);
    const callToActionLink = promo.getFieldValue(PromoConstants.callToActionLink);
    const callToActionLabel = promo.getFieldValue(PromoConstants.callToActionLabel);
    const horizontalAlignment = promo.getFieldValue(PromoConstants.horizontalAlignment);

    return (
        <div className={`ob-promo ${className}`} id={anchorId} >
            <Image image={backgroundAsset}>
                <div className="ob-promo__wrapper" data-horizontal-alignment={horizontalAlignment}>
                    <div className="ob-promo__content">
                        <div className={'ob-promo__top'}>
                            <Heading className="ob-promo__title" tag={`h${titleLevel}`} whiteText={isWhiteText}>
                                {title}
                            </Heading>

                            { subtitle &&
                            <p className={'ob-promo__subtitle'}>
                                {subtitle}
                            </p>}

                            <BodyText whiteText={isWhiteText}>
                                {desctiption}
                            </BodyText>
                        </div>

                        <div className="ob-promo__retailer-wrapper">
                            {retailerLogo &&
                            <Image className="ob-promo__retailer-logo" image={retailerLogo} />}

                            <p className="ob-promo__retailer-text" dangerouslySetInnerHTML={{__html: retailerText}}></p>

                            <Button
                                className="ob-promo__retailer-button event_button_click"
                                dataActionDetail={AnalyticsConstants.promoRedeem + retailerText}
                                tag="a"
                                size={PromoConstants.large}
                                href={callToActionLink}
                                whiteTheme
                            >
                                {callToActionLabel}
                            </Button>
                        </div>
                    </div>
                </div>
            </Image>
        </div>
    )
}

export default mediaQueryHOC(Promo);
// This export is for unit testing (do not remove) :
export const PromoTest = Promo;

Promo.propTypes = {
    extraAttributes: PropTypes.any,
    isMedium: PropTypes.any,
};
