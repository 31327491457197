import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ToggleInput = ({
                           activeLabel,
                           inactiveLabel,
                           name,
                           value,
                           checked,
                           onChange,
                           className
                       }) => {
    const [isChecked, setChecked] = useState(checked);

    const onChangeEvent = (e) => {
        setChecked(!isChecked);
        onChange(e);
    };

    return (
        <div className={`ob-toggle__wrapper ${className}`}>
                <input
                    className={'ob-toggle__input'}
                    id={name}
                    name={name}
                    value={value}
                    checked={isChecked}
                    onChange={(e) => onChangeEvent(e)}
                    type='checkbox'
                />
            <label htmlFor={name} className={'ob-toggle__label'}>
                <span className={'ob-toggle__label-text'}>{isChecked ? activeLabel : inactiveLabel}</span>
            </label>
        </div>
    )
};

ToggleInput.defaultProps = {
    className: ''
};

ToggleInput.propTypes = {
    activeLabel: PropTypes.string,
    inactiveLabel: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string
}

export default ToggleInput
