import React, { useState } from 'react';
import Block from '../../adapters/helpers/Block';
import Component from '../../adapters/helpers/entity/Component';
import Image from '../Image/Image';
import Heading from '../Heading/Heading'
import { Markdown } from '../MarkdownText/MarkdownText';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';
import { InTheBoxBlockConstants } from '../../adapters/helpers/Constants';

export default function InTheBox(props) {
    return (props.extraAttributes && props.extraAttributes.entity && props.extraAttributes.entity.inTheBox) ?
        renderInTheBoxBlock(props) : '';
}

function renderInTheBoxBlock(props) {
    const { extraAttributes } = props;

    const block = new Block(props);
    const product = new Component(extraAttributes.entity.inTheBox);

    const value = name => {
        return product.getFieldValue(name);
    };

    let isWhiteText = false;
    const blockClassName = 'in-the-box';
    let className = block.getFieldValue(InTheBoxBlockConstants.classNames) ? block.getFieldValue(InTheBoxBlockConstants.classNames) : '';

    if (className) {
        isWhiteText = className.includes(InTheBoxBlockConstants.whiteText);
    }

    const body = value(InTheBoxBlockConstants.body);
    const items = value(InTheBoxBlockConstants.items);
    const anchorId = block.getFieldValue(InTheBoxBlockConstants.anchorId);
    const image = value(InTheBoxBlockConstants.inTheBoxImage);

    let [show, setShow] = useState('')

    const _handleWaypointEnter = () => {
        // Trigger by waypoint enter
        setShow('show')
    };

    return (
        <div className={`ob-${blockClassName} ${className ? className : ''} ${show}`} id={anchorId} >
            <Waypoint onEnter={_handleWaypointEnter} />
            <div className={`ob-${blockClassName}-wrapper`}>
                <div className={`ob-${blockClassName}-textContent`}>
                    <div className={`ob-${blockClassName}-textContent-inner`}>
                        <Heading whiteText={isWhiteText} tag={`h${value(InTheBoxBlockConstants.titleLevel)}`} >{value(InTheBoxBlockConstants.title)}</Heading>

                        <div className='ob-bodyText'>
                            <div className={`ob-${blockClassName}__content-body`}>
                                <Markdown source={body} />
                            </div>

                            <div className={`ob-${blockClassName}-text-image`}>
                                <Image image={image} />
                            </div>

                            <div className={`ob-${blockClassName}__content-items`}> 
                                <Markdown source={items} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
renderInTheBoxBlock.propTypes = {
    extraAttributes: PropTypes.any,
} 