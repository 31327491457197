
import React from 'react';
import Block from '../../adapters/helpers/Block';
import JanrainForm from '../Janrain/Janrain/HOC/JanrainForm';

import { ResetPasswordForm } from '../Janrain/Janrain/janrainForms';
import { objectWithLabel } from '../../adapters/helpers/Utils'


export default function ResetPassword(props) {
    const propsBlock = new Block(props);
    const title = propsBlock.getFieldValue('title');
    const subTitle = propsBlock.getFieldValue('surtitle');
    const successLink = propsBlock.getFieldValue('successLink');
    const subtitleSuccess = propsBlock.getFieldValue('subtitleSuccess');
    const newPasswordFieldLabel = propsBlock.getFieldValue('newPassword')?.fields?.text;
    const submitButtonLabel = propsBlock.getFieldValue('submitButtonLabel')?.fields?.text;
    const reEnterPasswordFieldLabel = propsBlock.getFieldValue('reEnterPassword')?.fields?.text;
    const passwordRequirements = propsBlock.getFieldValue('passwordRequirements')?.fields?.text;
    const codeFailRedirectPath = propsBlock.getFieldValue('codeFailRedirectPath')?.fields?.slug;
    const ariaFormIsSubmitting = props?.document?.fields?.ariaFormIsSubmitting?.fields?.text;
    const janrainErrorList = props?.document?.fields?.janrainErrors?.fields

    const resetPasswordFormLabels = {
        newPasswordFieldLabel: newPasswordFieldLabel,
        reEnterPasswordFieldLabel: reEnterPasswordFieldLabel,
        passwordRequirements: passwordRequirements,
        submitButtonLabel: submitButtonLabel
    };

    return (
        <JanrainForm>
            <ResetPasswordForm
                formHeading={title}
                formSubHeading={subTitle}
                formSubHeadingSuccess={subtitleSuccess}
                formSuccessLink={successLink}
                ariaFormIsSubmitting={ariaFormIsSubmitting}
                janrainLabels={resetPasswordFormLabels}
                janrainFormErrors={objectWithLabel(janrainErrorList)}
                codeFailRedirectPath={codeFailRedirectPath}
            />
        </JanrainForm>)
}
