import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import Image from '../Image/Image';
import { Constants } from '../../adapters/helpers/Constants';
export default function TitleInfoIcon({ className, titleElement, infoIcon, toolTip, toolTipTitle, toolTipText, arrowIcon, isLessthanThreeProducts }) {

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        trigger: 'click',
        visible: controlledVisible,
        onVisibleChange: setControlledVisible,
    });

    const [controlledVisible, setControlledVisible] = useState(false);
    
    const handleKeyDown = (event) => {
        if (event?.keyCode == Constants.ESCAPE_KEY_CODE) {
            controlledVisible && setControlledVisible(!controlledVisible);
        }
    };

    return (
        <>
            <div className={`info-icon-wrapper ${className}`}>
                {titleElement && <span className='info-title' dangerouslySetInnerHTML={{ __html: titleElement }} />}
                {infoIcon && <button tabIndex={0} onKeyDown={handleKeyDown} onClick={()=> {console.log('inside info icon clic'); setControlledVisible(!controlledVisible);}} className='info-icon' dangerouslySetInnerHTML={{ __html: infoIcon }} ref={setTriggerRef} />}
            </div>
            {visible && (
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({ className: `tooltip-container ${isLessthanThreeProducts ? 'tooltip-container-less-than-three-products' : ''}` })}
                >
                    <div {...getArrowProps({ className: `tooltip-arrow ${isLessthanThreeProducts ? 'tooltip-arrow-lessthan-three-product' : ''}`, border: '1px', boderColor: '#3d3d41', style: { tooltipBorder: '#3d3d41', boderColor: '#3d3d41' } })} >
                        <Image image={arrowIcon} />
                    </div>
                    {toolTip ? <div dangerouslySetInnerHTML={{ __html: toolTip }} /> :
                        <div tabIndex={0} className='tooltip-inner-wrapper'>
                            <p className='tooltip-title'>{toolTipTitle}</p>
                            <hr />
                            <p className='tooltip-content'>{toolTipText}</p>
                        </div>}
                </div>
            )}
        </>
    );
}


TitleInfoIcon.propTypes = {
    titleElement: PropTypes.any,
    infoIcon: PropTypes.any,
    className: PropTypes.string,
    toolTip: PropTypes.any,
    arrowIcon: PropTypes.any,
}