import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {PypestreamChatConstants} from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import Modal from 'react-modal';
import Image from '../Image/Image';

function PypestreamChat(props) {
    /* global Pypestream */

    const modalDelay = props.document?.fields?.modalDelay;
    const popUpDisabled = props.document?.fields?.popUpDisabled || false;
    const moduleId = props.document?.fields?.moduleId;
    const modalTitle = props.document?.fields?.modalTitle;
    const modalContent = props.document?.fields?.modalContent;
    const modalBackgroundImage = props.document?.fields?.modalBackgroundImage;
    const modalAcceptButtonLabel = props.document?.fields?.modalAcceptButtonLabel;
    const modalDeclineButtonLabel = props.document?.fields?.modalDeclineButtonLabel;
    const toggleChatLabel = props.document?.fields?.toggleChatLabel;
    const toggleButtonIcon = props.document?.fields?.toggleButtonIcon;
    const apiScript = props.document?.fields?.apiScriptUrl;
    const configId = props.document?.fields?.configId;
    const configDomain = props.document?.fields?.configDomain;
    const configEnv = props.document?.fields?.configEnv;
    const configBeta = props.document?.fields?.configBeta;
    const localStorageVariable =  PypestreamChatConstants.persistentChatTriggered + moduleId;

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 200
        },
        content : {
            background          : 'none',
            border              : 'none',
            borderRadius        : '0',
            top                 : '50%',
            left                : '50%',
            right               : 'auto',
            bottom              : 'auto',
            fontSize            : '1.4rem',
            lineHeight          : '2.1rem',
            marginRight         : '-50%',
            padding             : '0',
            maxWidth            : '530px',
            transform           : 'translate(-50%, -50%)',
            width               : '90%'
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalWasVisited, setModalWasVisited] = useState(popUpDisabled);
    const [chatWasVisited, setChatWasVisited] = useState(false);

    const launchChat = () => {
        let chatContainer = document.getElementById(moduleId +'-module-container');

        closeModal();

        setChatWasVisited(true);

        Pypestream('config', {
            domain: configDomain,
            env: configEnv,
            beta: configBeta
        });

        Pypestream('boot', {
            APP_ID: configId
        }, chatContainer);
    };

    const toggleChat = () => {
        if(chatWasVisited) {
            Pypestream('toggle');
        } else {
            launchChat();
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalWasVisited(true);
        localStorage.setItem(localStorageVariable, true);
    };

    const appendScript = () => {
        const script = document.createElement('script');
        script.src = apiScript;
        script.async = true;
        document.head.appendChild(script);
    };

    useEffect(() => {
        appendScript();
        const persistentChatTriggered = localStorage.getItem(localStorageVariable);

        if(!popUpDisabled) {
            setModalWasVisited(persistentChatTriggered ? true : false);
        }

        if (!persistentChatTriggered && !popUpDisabled) {
            setTimeout(() => {
                setModalIsOpen(true);
            }, (modalDelay*1000));
        }
    }, []);

    return (
        <div>
            {modalWasVisited &&
                <button tabIndex={0} className='ob-pypestream-toggle-button event_button_click'
                        onClick={() => toggleChat()}
                        data-action-detail={AnalyticsConstants.openLiveChat}
                        aria-label={toggleChatLabel.fields.text}>
                    <Image image={toggleButtonIcon} />
                </button>
            }

            <div className={'ob-pypestream-chat-container'}
                 id={moduleId + '-module-container'}>
            </div>

            {!modalWasVisited &&
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    closeTimeoutMS={250}
                    style={customModalStyles}>
                    <div className='ob-pypestream-modal'>
                        <Image image={modalBackgroundImage}>
                            <div className='ob-pypestream-modal-content'>
                                <div className='ob-pypestream-modal-copy'>
                                    {modalTitle &&
                                        <p>{modalTitle}</p>
                                    }
                                    {modalContent &&
                                        <p>{modalContent}</p>
                                    }
                                </div>

                                <div className='ob-pypestream-modal-button-container'>
                                    <button aria-label={modalAcceptButtonLabel} className='ob-pypestream-modal-button launch'
                                            onClick={launchChat}>
                                        {modalAcceptButtonLabel}
                                    </button>

                                    <button aria-label={modalDeclineButtonLabel} className='ob-pypestream-modal-button close'
                                            onClick={closeModal}>
                                        {modalDeclineButtonLabel}
                                    </button>
                                </div>
                            </div>
                        </Image>
                    </div>
                </Modal>
            }
        </div>
    );
}

export default PypestreamChat;

PypestreamChat.propTypes = {
    document: PropTypes.object
};