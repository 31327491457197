import React from 'react';
import Image from '../Image/Image';
import PropTypes from 'prop-types';

export default function CloudinaryImage({document}) {
    const classNames = document?.fields?.className;

    return (<div className={classNames}>
        <Image image={document}/>
    </div>);
}

CloudinaryImage.propTypes = {
    document: PropTypes.object,
};