export const inputs = {
    formName: 'registerProductForm',
    editFormName: 'editProfileForm',
    type: {
        text: 'text',
        date: 'date'
    },
    field: {
        selectProductType: 'selectProductType',
        reminderToReplace: 'reminderToReplace',
        dateOfPurchase: 'dateOfPurchase',
        traitProductType: {
            traitValue: 'traitProductType_traitValue',
            imageUrl: 'traitProductType_imageUrl',
            registrationDate: 'traitProductType_registrationDate',
            purchaseDate: 'traitProductType_purchaseDate'
        },
        registerProductsID: 'RegisterProductsID',
        primaryProduct: {
            traitValue: 'primaryProduct'
        }
    }
}
