import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const FormInput = ({
    inputType,
    name,
    label,
    value,
    toolTipText,
    checked,
    validationErrorText,
    placeholder,
    halfWidth,
    quarterWidth,
    required,
    ariaDescribedBy,
    onChange,
    onBlur,
    disabled=false
}) => {
    const [dynamicInputType, setDynamicInputType] = useState(inputType);
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const togglePasswordShowHide = () => {
        dynamicInputType === 'password' ? setDynamicInputType('text') : setDynamicInputType('password');
        setIsPasswordShown(!isPasswordShown);
    };

    const isDateInputSupported = () => {
        const input = document.createElement('input');
        const notADateValue = 'not-a-date';

        if(input) {
            input.setAttribute('type', 'date');
            input.setAttribute('value', notADateValue);
        }

        return ((input) && (input.value !== notADateValue));
    };

    useEffect(() => {
        if (inputType === 'date' && !isDateInputSupported()) {
            setDynamicInputType('text');
        }
    }, []);

    return (
        <div className="ob-input__wrapper" data-half-width={halfWidth} data-quarter-width={quarterWidth}>
            <label htmlFor={name} className="ob-input__label">{label}</label>
            <div className="ob-input__password-wrapper">
                {inputType === 'date' && isDateInputSupported()  ? (

                    <input aria-label={name}
                        className='ob-input__input'
                        type={dynamicInputType}
                        value={value}
                        name={name}
                        aria-describedby={`ob-input__error-${name} ${ariaDescribedBy}`}
                        id={name}
                        onChange={onChange}
                        data-isvalid={value ? (validationErrorText ? false : true) : (validationErrorText ? false : true)}
                        aria-invalid={value ? (validationErrorText ? true : false) : (validationErrorText ? true : false)}
                        required={required}
                    />
                ) : (
                    <input aria-label={placeholder}
                        className='ob-input__input'
                        disabled={disabled}
                        placeholder={placeholder}
                        type={dynamicInputType}
                        checked={checked}
                        value={value}
                        name={name}
                        aria-describedby={`${validationErrorText ? `ob-input__error-${name}` : '' } ${ariaDescribedBy}`}
                        id={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        data-isvalid={value ? (validationErrorText ? false : true) : (validationErrorText ? false : true)}
                        aria-invalid={value ? (validationErrorText ? true : false) : (validationErrorText ? true : false)}
                        autoComplete={name}
                        required={required}
                    />
                )}
                { inputType === 'password' &&
                    <button className={`ob-input__show-hide ${isPasswordShown ? '--show' : '--hide'}`} type="button" onClick={togglePasswordShowHide}
                            aria-label={!isPasswordShown ? 'Show Password' : 'Hide Password'}>
                        <svg viewBox='0 0 37 19' className={'ob-icon ob-icon--showHide'} dangerouslySetInnerHTML={{__html: `
                            <path d="M2.318 9.46c5.59 5.468 11.383 8.089 17.226 7.79 2.12-.109 4.258-.607 6.352-1.48a24.212 24.212 0 004.947-2.82 26.969 26.969 0 003.838-3.41c-5.596-5.472-11.393-8.092-17.242-7.787-2.128.11-4.273.613-6.373 1.494A24.26 24.26 0 006.11 6.085 26.862 26.862 0 002.318 9.46M18.64 19C12.439 19 6.356 16.014.543 10.111L0 9.561l.485-.605c.069-.087 1.727-2.139 4.628-4.27 1.705-1.253 3.489-2.274 5.303-3.035C12.696.694 15.03.148 17.352.027c6.55-.34 12.977 2.642 19.104 8.863l.543.551-.485.605c-.069.086-1.728 2.138-4.628 4.27-1.705 1.252-3.49 2.273-5.303 3.034-2.28.957-4.614 1.503-6.936 1.624-.336.017-.672.026-1.006.026"/><path d="M18.5 2.739A6.769 6.769 0 0011.739 9.5a6.769 6.769 0 006.761 6.761A6.769 6.769 0 0025.261 9.5 6.769 6.769 0 0018.5 2.739M18.5 18c-4.687 0-8.5-3.813-8.5-8.5C10 4.813 13.813 1 18.5 1 23.187 1 27 4.813 27 9.5c0 4.687-3.813 8.5-8.5 8.5"/><path d="M23 9.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0"/>`}}>
                        </svg>
                    </button>
                }
            </div>
            {toolTipText &&
                <div>
                    <p>{toolTipText}</p>
                </div>
            }
            {validationErrorText &&
                <p id={`ob-input__error-${name}`} className="ob-input__error">
                    {validationErrorText}
                </p>
            }
        </div>
        )
};

FormInput.propTypes = {
    value:  PropTypes.string,
    name:  PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    inputType: PropTypes.oneOf(['text', 'email', 'password', 'tel', 'hidden', 'date']).isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    validationErrorText: PropTypes.string,
    toolTipText: PropTypes.string,
    halfWidth: PropTypes.bool,
    quarterWidth: PropTypes.bool,
    onBlur: PropTypes.func,
    disabled:PropTypes.bool
};

FormInput.defaultProps = {
    required: true,
    ariaDescribedBy: ''
};

export default FormInput
